import styled from '@emotion/styled';
import appConfig from '../../config/config';

const Container = styled.div(
	{
		background: '#ffffff',
		boxShadow: '3px 3px 16px 0px #ABAFC661',
		padding: '2rem 1.2rem',
		paddingBottom: 0,
		borderRadius: 16,
		width: '100%',
	},
	() => ({})
);
const Row = styled.div(
	{
		display: 'flex',
		margin: '0 1.4rem',
		gap: '2rem',
	},
	({
		isHeader,
		isChild,
		isParent,
		verticalPadding = '1.94rem',
	}: {
		isHeader?: boolean;
		isChild?: boolean;
		isParent?: boolean;
		verticalPadding?: string;
	}) => ({
		fontWeight: isHeader ? 500 : 400,
		padding: isHeader ? '1.6rem 0' : `${verticalPadding} 0`,
		...(isChild
			? {
					background: appConfig.style.colors.lightBg,
					'> div:first-of-type': {
						color: appConfig.style.colors.text4,
						paddingLeft: '1.94rem',
					},
			  }
			: {}),
		...(isParent ? {} : {}),
		...(!isChild && !isParent
			? {
					'&:not(:last-child)': {
						borderBottom: `${isHeader ? 3 : 1}px solid ${appConfig.style.colors.table}`,
					},
			  }
			: {}),
	})
);
const Cell = styled.div(
	{
		display: 'flex',
		alignItems: 'center',
		flex: 1,
		minWidth: '10rem',
		whiteSpace: 'nowrap',
		'&[data-col-type="status"]': {
			minWidth: '15rem',
		},
		'&[data-col-type="drafts"]': {
			minWidth: '15rem',
			position: 'relative',
			'.img__new': {
				cursor: 'pointer',
				position: 'relative',
				span: {
					position: 'absolute',
					top: 'calc(100% - 1rem)',
					fontSize: '0.8rem',
					background: '#E98B8B',
					fontWeight: 500,
					borderRadius: 10,
					left: '50%',
					color: '#ffffff',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '0.2rem 0.6rem',
				},
				// '&::before': {
				// 	content: '""', // Ensures the pseudo-element exists
				// 	position: 'absolute', // Allows positioning
				// 	height: 'calc(100% + 1rem)', // Extends beyond the parent
				// 	width: 'calc(100% + 1rem)', // Extends beyond the parent
				// 	top: '-0.5rem', // Centers the extended background
				// 	left: '-0.5rem', // Centers the extended background
				// 	background: 'red', // Makes it visible
				// 	display: 'block', // Ensures the pseudo-element is visible
				// 	zIndex: 0, // Places it below the parent content
				// },
				// img: {
				// 	zIndex: 1,
				// },
			},
		},
		'&[data-col-type="model"]': {
			maxWidth: '6rem',
		},
		'&[data-col-type="final"], &[data-col-type="drafts"]': {
			img: {
				cursor: 'pointer',
			},
		},
		'&[data-col-type="valuator-name"]': {
			position: 'relative',
			userSelect: 'none',
		},
	},
	({ wide }: { wide?: boolean }) => ({
		...(wide ? { flex: '1 0 12rem' } : {}),
	})
);

const Scroll = styled.div(
	{
		overflowY: 'auto',
	},
	({ maxHeight = '29.7rem' }: { maxHeight?: string }) => ({
		maxHeight,
	})
);

const ScrollableList = {
	Container,
	Scroll,
	Row,
	Cell,
};

export default ScrollableList;
