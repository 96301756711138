import { observer } from "mobx-react-lite";
import { IGrantCancelation } from "../../../../../Models/App/EquityPlans/GrantCancelations";
import { GrantTable } from "../../../../../Models/App/EquityPlans/Grants";
import { IOrder, OrderType } from "../../../../../Models/App/EquityPlans/Order";
import Spinner from "../../../../../Shared/Components/Spinner/Spinner";
import { TableColumn } from "../../../../../Shared/Components/Table/Table";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { cancelationReason, cancelationType, grantTypes, orderTypes } from "../../../../../Shared/StaticData/equityPlans";
import { formatDate, formatNumber, getCurrency, isNumber } from "../../../../../Shared/Utilities";
import TableDrawer from "../../../General/TableDrawer/TableDrawer";
import BeneficiaryDetails from "./../BeneficiaryDetails/BeneficiaryDetails";
import BeneficiaryCardStyles from "./BeneficiaryCard.style";
import Image from "../../../../../Shared/Components/Image";
import { ElectronicSignatureStatus } from "../../../../../Models/API/Document/eletronic-signature-status-enum";
import { IC_LETTER_CANCELLED, IC_LETTER_READY, IC_LETTER_SENT, IC_LETTER_SIGNED } from "../../../../../Assets";
import DocumentPreviewInfo from "../../../../Documents/Components/Preview/DocumentPreviewInfo";
import useDocument from "../../../../Documents/helpers/hooks/useDocument";
import { useState } from "react";
import { ElectronicSignature } from "../../../../../Models/API/Document/eletronics-signature";
import { ModalBodyProps } from "../../../../../Shared/Components/Modal/types";

interface OrdersTable {
    orderId: number;
    orderType: string;
    orderDate: Date;
    amount: number;
}

interface CancellationsTable {
    cancelId: number;
    date: Date;
    reason: string;
    cancelType: string;
    grace: string;
}

interface BenTable {
    fullName: { firstName: string; lastName: string };
    outstanding: number;
    grantDate: Date;
    grantType: number;
    grantNumber: string;
    granted: number;
    planName: string;
    vested: number;
    unvested: number;
    grantId: number;

    // empNumber: string;
}

interface BenCardProps {
    btnFunc: () => void;
    openGrantModal: (grantId: number) => void;
    openOrderModal: () => void;
    openCancelationModal: () => void;
    isEdit: boolean;
    modalProps: ModalBodyProps;
}

const BeneficiaryCard = (props: BenCardProps) => {
    const { equityPlansStore, documentsStore } = useRootStore();
    // const { openGrntCard, onEditOrderHandler } = useEsopModals();
    const { getPreviewInfo, previewDocument } = useDocument();
    const [tooltips, setTooltips] = useState<Record<string, ElectronicSignature>>({});

    const grantsTableColumns: TableColumn<GrantTable>[] = [
        {
            label: "Grant number",
            name: "grantNumber",
            sortable: true,
            style: { flex: '1 0 12rem' },
        },
        {
            label: 'Grant letter',
            name: 'isSigned',
            align: 'center',
            justify: 'center',
            // style: { flex: "0 0 11.5rem" },
            style: { flex: '1 0 12rem', display: 'flex' },
            render(obj, value) {
                if (!obj.documentDetailsDto || !obj.documentDetailsDto.length) return;
                const latestDocument = obj.documentDetailsDto?.find(doc => !doc.isCancelled) || obj.documentDetailsDto[obj.documentDetailsDto.length - 1];
                const tooltipData = tooltips[latestDocument.documentId];

                return (
                    <Image
                        className="clickable"
                        onClick={(e) => {
                            e.stopPropagation();
                            previewDocument(latestDocument.documentId, latestDocument.templateId, latestDocument.documentName);
                        }}
                        tooltipPlacement="right"
                        key={latestDocument.documentId}
                        tooltip={
                            latestDocument.isCancelled ? (
                                'Canceled'
                            ) : latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated ? (
                                'Not sent yet'
                            ) : (
                                <DocumentPreviewInfo {...getPreviewInfo(tooltipData, latestDocument.templateId, true)} />
                            )
                        }
                        width="4.5rem"
                        src={
                            latestDocument.isCancelled || latestDocument.isDeleted
                                ? IC_LETTER_CANCELLED
                                : latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated
                                    ? IC_LETTER_READY
                                    : latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.ElectronicSignatureCreated
                                        ? IC_LETTER_SENT
                                        : latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.ElectronicSignatureSigned
                                            ? IC_LETTER_SIGNED
                                            : undefined
                        }
                    />
                );
            },
        },
        {
            label: "Award type",
            name: "grantType",
            format: (val) => grantTypes[val]?.label,
            sortable: false,
            style: { flex: '1 0 12rem' },
        },
        {
            label: "Grant date",
            name: "grantDate",
            format: (val: any) => formatDate(val),
            sortable: true,
            style: { flex: '1 0 12rem' },
        },
        {
            label: 'Exercise price',
            name: 'exercisePrice',
            sortType: 'number',
            style: { flex: '1 0 12rem' },
            sortable: true,
            render: (val) => val.beneficiaryId === -1 ? undefined
                : <span>{val.grantType === 1 ? '-------' : `${getCurrency(val.exercisePriceCurrency).symbol} ${formatNumber(val.exercisePrice)}`}</span>
        },
        {
            label: 'Vesting start date',
            name: 'vestStartDate',
            format: (value, row) => row?.beneficiaryId === -1 ? undefined : formatDate(value),
            sortType: 'date',
            style: { flex: '1 0 12rem' },
            sortable: true,
        },
        {
            label: 'Equity plan',
            name: 'planName',
            sortable: true,
            style: { flex: '1 0 12rem' },
        },
        {
            label: "Granted",
            name: "granted",
            sortable: true,
            style: { flex: '1 0 12rem' },
            format: (val) => formatNumber(val),
        },
        {
            label: "Unvested",
            name: "unvested",
            sortable: true,
            format: (val) => formatNumber(val),
            style: { flex: '1 0 12rem' },
        },
        {
            label: "Exercisable",
            name: "exercisable",
            sortable: true,
            format: (val) => formatNumber(val),
            style: { flex: '1 0 12rem' },
        },
        {
            label: 'Saleable',
            name: 'sellable',
            sortable: true,
            sortType: 'number',
            format: (val) => formatNumber(val),
            style: { flex: '1 0 12rem' },
        },
        {
            label: 'Sold',
            name: 'sold',
            sortable: true,
            sortType: 'number',
            format: (val) => formatNumber(val),
            style: { flex: '1 0 12rem' },
        },
        {
            label: 'Canceled',
            name: 'canceled',
            sortable: true,
            sortType: 'number',
            format: (val) => formatNumber(val),
            style: { flex: '1 0 12rem' },
        },
        {
            label: "Outstanding",
            name: "outstanding",
            sortable: true,
            format: (val) => formatNumber(val),
            style: { flex: '1 0 12rem' },
        },
    ];

    const ordersTableColumns: TableColumn<IOrder>[] = [
        {
            label: "Order number",
            name: "orderNumber",
        },
        {
            label: "Grant number",
            name: "grantNumber",
        },
        {
            label: "Order type",
            name: "orderType",
            format(val) {
                return orderTypes[val]?.label;
            },
        },
        {
            label: "Order date",
            name: "date",
            format(val) {
                return formatDate(val);
            },
        },
        {
            label: "Amount",
            name: "amount",
            format: (val) => formatNumber(val),
        },
        {
            label: "Exercise price",
            name: "exercisePrice",
        },
        {
            label: "Currency",
            name: "currency",
        },
        {
            label: "Sale price",
            name: "sellPrice",
            format(val, obj) {
                return obj?.orderType === OrderType.Exercise ? "" : val;
            },
        },
    ];

    const cancelationsTableColumns: TableColumn<IGrantCancelation>[] = [
        {
            label: "Cancelation number",
            name: "grantCancelationId",
        },
        {
            label: "Cancel type",
            name: "cancelType",
            format(val, obj) {
                if (!obj) return;
                return cancelationType.filter((ctyp) => obj.cancelType === ctyp.value)[0].label;
            },
        },
        {
            label: "Cancel date",
            name: "cancelationDate",
            format(val) {
                return formatDate(val);
            },
        },
        {
            label: "Cancel in",
            name: "gracePeriodNum",
        },
        {
            label: "Cancel reason",
            name: "cancelationReason",
            format(val, obj) {
                if (!obj) return;
                return cancelationReason.filter((ctyp) => +obj.cancelationReason === ctyp.value)[0].label;
            },
        },
        // {
        // 	name: "menu",
        // 	label: "",
        // 	menuItems: [
        // 		{
        // 			onClick: (obj) => { },
        // 			element: <img src={IC_EDIT_GRAY} alt="edit" />,
        // 			qaid: "Beneficiary.Button.Edit",
        // 		},
        // 		{
        // 			onClick: (obj) => { },
        // 			element: <img src={IC_TRASH2} alt="delete" />,
        // 			qaid: "Beneficiary.Button.Delete",
        // 		},
        // 	],
        // },
    ];

    // const handleModal = (row?: GrantTable | any) => {
    // 	showModal({
    // 		title: `Grant # ${row?.grantNumber}`,
    // 		body: <GrantCard />,
    // 		height: "100%",
    // 		width: "70%",
    // 		isMust: true,
    // 		showBackBtn: true,
    // 	});
    // };

    // const openGrntCard = (row: GrantTable) => {
    // 	equityPlansStore.GetSingleGrant(row.grantId);
    // 	equityPlansStore.GetOrdersForSingleGrant(row.beneficiaryId, row.grantId);
    // 	equityPlansStore.GetCancelationOrdersForSingleGrant(row.beneficiaryId, row.grantId);
    // 	handleModal(row);
    // };

    const getDrawerHeader = (title: string, postfix: string, total: number = 0) => {
        return (
            <div className={`${BeneficiaryCardStyles}__drawer-header`}>
                <span className="bold">{title}</span>
                <span className="header-comment">
                    ({total} {postfix})
                </span>
            </div>
        );
    };

    const onTooltipIntersection = async (grant: GrantTable) => {
        if (!grant.documentDetailsDto) return;

        const latestDocument = grant.documentDetailsDto?.find(doc => !doc.isCancelled) || grant.documentDetailsDto[grant.documentDetailsDto.length - 1];

        if (latestDocument.electronicSignatureStatus === ElectronicSignatureStatus.DocumentCreated) return;

        const res = await documentsStore.getDocumentSignatures(latestDocument.documentId);
        if (res.data) {
            setTooltips((prev) => ({ ...prev, [latestDocument.documentId]: res.data.data }));
        }
    }

    return (
        <div className={BeneficiaryCardStyles}>
            {equityPlansStore.beneficiaryData.firstName ? (
                <>
                    <BeneficiaryDetails
                        type="detailed"
                        isEdit={props.isEdit}
                        modalProps={props.modalProps}
                    />
                    <div>
                        {/* Grants */}
                        <TableDrawer
                            tableColumns={grantsTableColumns}
                            tableData={equityPlansStore.beneficiaryGrantsWithTotalRow}
                            rowClicked={(obj) => props.openGrantModal(obj.grantId)}
                            actionBtn={{ func: props.btnFunc, desc: "Add new grant" }}
                            header={getDrawerHeader(
                                "Beneficiary grants",
                                (equityPlansStore.beneficiaryGrants?.length ?? 0) > 1 ? "grants" : "grant",
                                isNumber(equityPlansStore.beneficiaryGrants?.length) ? (equityPlansStore.beneficiaryGrants.length - 1) : 0
                            )}
                            showTotal
                            onRowIntersection={onTooltipIntersection}
                        />

                        {/* Orders */}
                        <TableDrawer
                            tableColumns={ordersTableColumns}
                            tableData={equityPlansStore.beneficiaryOrders}
                            actionBtn={{ func: props.openOrderModal, desc: "Add new grant order" }}
                            header={getDrawerHeader(
                                `Beneficiary orders`,
                                (equityPlansStore.beneficiaryOrders?.length ?? 0) > 1 ? "orders" : "order",
                                equityPlansStore.beneficiaryOrders?.length
                            )}
                        />

                        {/* Cancelations */}
                        <TableDrawer
                            tableColumns={cancelationsTableColumns}
                            tableData={equityPlansStore.benficiaryGrantCancelations}
                            actionBtn={{ func: props.openCancelationModal, desc: "Add new grant cancelation" }}
                            header={getDrawerHeader(
                                `Beneficiary grant cancelations`,
                                (equityPlansStore.benficiaryGrantCancelations?.length ?? 0) > 1 ? "cancelations" : "canceletion",
                                equityPlansStore.benficiaryGrantCancelations?.length
                            )}
                        />
                    </div>
                </>
            ) : (
                <Spinner
                    attachParent
                    incorporated
                    center
                />
            )}
        </div>
    );
};

export default observer(BeneficiaryCard);