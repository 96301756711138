import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { IC_DOWNLOAD_FILE, IC_MESSAGE_UNREAD, IC_SUCCESS_MODAL, IC_VALUATION_MINIFIED_LOGO } from '../../../../Assets';
import appConfig from '../../../../config/config';
import { DataCollectionProjectPreview, DataCollectionStatusEnum, ReportType } from '../../../../Models/API/DataCollection/project-preview';
import Button from '../../../../Shared/Components/Button/Button';
import Image from '../../../../Shared/Components/Image';
import Title from '../../../../Shared/Components/Layout/Title';
import { ModalBodyProps } from '../../../../Shared/Components/Modal/types';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';
import useModal from '../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { formatDecimal, isNumber, onOpenURL } from '../../../../Shared/Utilities';
import AddonCheckout from '../../../Payment/Checkout/AddonCheckout';
import { useDataCollectionModal } from '../../helpers/useDataCollectionModal';
import { DataCollectionPermissionsEnum, getDataCollectionStatus, hasProjectPermissions } from '../../helpers/utils';

type Props = {
	project: DataCollectionProjectPreview;
	reportId?: string;
} & ModalBodyProps;

const Style = css({
	label: 'ProjectFilePreviewContainer',
	padding: '5.5rem 3.5rem',
	display: 'flex',
	gap: '1rem',
});

const Card = styled.div({
	label: 'ProjectFilePreviewCard',
	border: `1px solid ${appConfig.style.colors.table}`,
	boxShadow: '3px 3px 3px rgba(201, 202, 208, 0.4)',
	borderRadius: 16,
});

const FilePreviewStyle = css({
	label: 'ProjectFilePreview',
	display: 'flex',
	flexDirection: 'column',
	padding: '4.5rem',
	flex: 1,
	'&__fileDetails': {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		gap: '1rem',
		'.projectData': {
			display: 'flex',
			alignItems: 'center',
			height: '4rem',
			gap: '2rem',
			flex: 1,
			justifyContent: 'space-between',
			'.projectName': {
				fontWeight: 500,
			},
		},
		'.download__button': {
			borderRadius: 8,
			border: `1px solid ${appConfig.style.colors.text2}`,
			display: 'flex',
			alignItems: 'center',
			gap: '1.5rem',
			textDecoration: 'underline',
			paddingInline: '1.5rem',
			cursor: 'pointer',
			height: '100%',
		},
	},
	'&__summaryDetails': {
		marginTop: '3.5rem',
		display: 'flex',
		flexDirection: 'column',
		'.subtitle': {
			fontWeight: 400,
		},
		'.conclusionDetails': {
			fontWeight: 500,
			fontSize: '1.8rem',
			display: 'flex',
			justifyContent: 'space-between',
			padding: '1.5rem 0',
		},
		'.divider': {
			width: '100%',
			height: 1,
			background: appConfig.style.colors.border1,
		},
		'.paymentBtn': {
			marginTop: '4.5rem',
			alignSelf: 'flex-end',
		},
	},
});

const SupportStyle = css({
	padding: '4.5rem 3.5rem',
	flex: '0 1 33rem',
	display: 'flex',
	flexDirection: 'column',
	ul: {
		paddingLeft: '2rem',
	},
	'ul li': {
		listStyle: 'outside',
		color: appConfig.style.colors.text2,
		'&:not(:last-child)': {
			marginBottom: '1rem',
		},
	},
	'&__contactTeam': {
		display: 'flex',
		gap: '1rem',
		textDecoration: 'underline',
		color: appConfig.style.colors.color1,
		cursor: 'pointer',
		justifyContent: 'center',
		marginTop: 'auto',
	},
});

const ProjectFilePreview = ({ project, reportId, removeModal }: Props) => {
	const { paymentStore, dataCollectionStore } = useRootStore();
	const { showModal } = useModal();
	const { showErrorModal } = useGeneralModal();
	const { currentStatus } = getDataCollectionStatus(project.status);
	const isPendingPayment = currentStatus === DataCollectionStatusEnum.PaymentApproval;
	const { onOpenGeneralChat } = useDataCollectionModal();

	const onProceed = async () => {
		if (!hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.SEND_PAYMENT) && isPendingPayment) {
			removeModal?.();
			return showModal({
				body: ({ removeModal }) => (
					<div className="flex flex-column justify-center align-center gap-1">
						<Image src={IC_SUCCESS_MODAL} />
						<Title type="secondary">Draft review</Title>
						<div className="text-center">
							Your 409A draft report <span className="bold">({project.projectName})</span> is ready for your approval. In order to review it,
							please contact your company's admin (Adam Smith) to arrange the payment.
						</div>
						<Button qaid="ProjectPreview.Button.Close" onClick={removeModal} label="Close" className="mt-4" />
					</div>
				),
				width: '73rem',
			});
		}

		const res = await paymentStore.getValuationAddOn(project.valuationProjectId);
		if (res.data === undefined || !isNumber(res.data?.data.addonId)) return;

		showModal({
			isFrameless: true,
			body: (props) => (
				<AddonCheckout
					addons={[
						{
							name: '409A Valuation',
							price: res.data.data.price,
							id: res.data.data.addonId,
						},
					]}
					onPurchaseHandler={async (token) => {
						if (!isNumber(res.data?.data.addonId)) return;

						const purchaseRes = await paymentStore.purchaseValuationAddon(res.data.data.addonId, project.valuationProjectId, token);
						if (!purchaseRes.isSuccess) return showErrorModal(purchaseRes.errorMessage);

						const projectsRes = await dataCollectionStore.getProjects();
						const updatedProject = projectsRes.data?.data.find((p) => p.valuationProjectId === project.valuationProjectId);

						// const latestDoc = updatedProject?.reports[updatedProject?.reports.length - 1];
						// props.removeModal?.();

						// if (!latestDoc) return;

						showModal({
							type: 'success',
							body: 'Your 409A draft is now available',
							qaid: '409A.Modal.Success',
							width: '60.5rem',
							showProgressBar: false,
							timeout: 3000,
							onModalClose: () => {
								// onOpenURL(latestDoc.filePath);
								removeModal?.();
								props.removeModal?.();
							},
						});
					}}
				/>
			),
			width: '100%',
			height: '100%',
		});
	};

	const reportType = useMemo(() => {
		return project.reports.find((report) => report.id === reportId)?.reportType ?? ReportType.Draft;
	}, [project, reportId]);

	const onOpenReport = () => {
		if (isPendingPayment) return onProceed();
		const report = project.reports.find((report) => report.id === reportId);
		if (report === undefined) return;
		onOpenURL(report.filePath);
	};

	return (
		<div className={Style}>
			<Card className={FilePreviewStyle}>
				<div className={`${FilePreviewStyle}__fileDetails`}>
					<Image width="4.4rem" src={IC_VALUATION_MINIFIED_LOGO} />
					<div className="projectData">
						<span className="projectName">{project.projectName}</span>
						<div className="download__button" onClick={onOpenReport}>
							<span>Review 409A {reportType === ReportType.Final ? 'final' : 'draft'} Report</span>
							<Image src={IC_DOWNLOAD_FILE} width="2rem" height="2rem" />
						</div>
					</div>
				</div>
				<div className={`${FilePreviewStyle}__summaryDetails`}>
					<span className="subtitle">Valuation summary</span>
					<div className="conclusionDetails">
						<span>Metric</span>
						<span>Conclusion</span>
					</div>
					<div className="divider"></div>
					<div className="conclusionDetails">
						<span>Fair market value (common/ ordinary share)</span>
						<span>${formatDecimal(project.fairMarketValue)}</span>
					</div>
					{isPendingPayment && <Button className="paymentBtn" qaid="" label="Proceed to payment" onClick={onProceed} width="22rem" />}
				</div>
			</Card>
			<Card className={SupportStyle}>
				<ul>
					<li>Valuation is based on the Option Pricing Method</li>
					<li>Detailed report with all parameters taken (volatility, risk-free rate, etc)</li>
					<li>Contact us directly via the 409A project ledger for any outstanding questions/ remarks</li>
				</ul>
				<div className={`${SupportStyle}__contactTeam`} onClick={() => onOpenGeneralChat(project.valuationProjectId)}>
					Contact our valuation team
					<Image src={IC_MESSAGE_UNREAD} width="3rem" />
				</div>
			</Card>
		</div>
	);
};

export default ProjectFilePreview;
