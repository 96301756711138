import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AltProps } from "../../dependencies/types/types";
import { Wrapper, defaultColors } from "./DoughnutChart.style";
import { formatNumber } from "../../../../../Utilities";
import useRootStore from "../../../../../Hooks/useRootStore";

interface Props {
    colors?: string[];
    labels: string[];
    figures: number[] | string[];
    tooltipFigures?: number[];
    className?: string;
    esopTooltip?: boolean;
    centerTotal?: number;
}

export interface DoughnutChartProps extends AltProps<Props> { }

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ ap }: DoughnutChartProps) => {
    const { colors, labels, figures, tooltipFigures, className, esopTooltip, centerTotal } = ap;
    const { appState } = useRootStore();

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Shareholders",
                data: figures,
                backgroundColor: colors || defaultColors,
            },
        ],
    };

    const options: any = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },

            tooltip: {
                mode: "index",
                intersect: false,
                backgroundColor: "#4D4D4D",
                padding: 18,
                titleSpacing: 3,
                titleMarginBottom: 3,
                footerSpacing: 3,
                footerMarginTop: 17,
                titleFont: {
                    size: appState.fontSize * 1.2,
                    weight: "normal",
                },

                bodyFont: {
                    size: appState.fontSize * 1.2,
                },
                footerFont: {
                    size: appState.fontSize * 1.2,
                    weight: "normal",
                },
                ...(!tooltipFigures ? null : tooltipFigures &&
                    tooltipFigures?.length > 0 && !esopTooltip ? {
                    callbacks: {
                        beforeTitle: function (context: any) {
                            return `${context[0].label}`;
                        },
                        title: function () {
                            return `------------------------`;
                        },
                        afterTitle: function () {
                            return "Shares (fully diluted):";
                        },

                            label: function (context: any) {
                                const formattedNumber = formatNumber(tooltipFigures[context.dataIndex]);
                                const percentage = context.dataset.data[context.dataIndex];

                            return ` ${formattedNumber}    ${percentage}%`;
                        },
                    },
                } :
                    {
                        callbacks: {
                            beforeTitle: function (context: any) {
                                return `${context[0].label}`;
                            },
                            title: function () {
                                return `- - - - - - - - - - - - - - - - -`;
                            },
                            label: function (context: any) {
                                const formattedNumber = formatNumber(tooltipFigures[context.dataIndex]);
                                const percentage = context.dataset.data[context.dataIndex];
                                return ` ${formattedNumber}    ${percentage}%`;
                            },
                        },
                    }),
            },
        },
    };

    const centerdText = {
        id: 'centerdText',
        afterDatasetsDraw(chart: any, args: any, plugins: any) {
            if (!centerTotal) return '';
            const { ctx, data } = chart;

            const xCenter = chart.getDatasetMeta(0).data[0]?.x;
            const yCenter = chart.getDatasetMeta(0).data[0]?.y;
            ctx.save();

            ctx.font = '14px sans-serif';
            ctx.fillStyle = '#979DB5';
            ctx.textAlign = 'center';
            ctx.fillText('Total', xCenter, yCenter - 10);

            ctx.font = 'bold 14px sans-serif';
            ctx.fillStyle = '#4d4d4d';
            ctx.textAlign = 'center';

            ctx.fillText(formatNumber(centerTotal), xCenter, yCenter + 10);
            ctx.restore();
        }
    }

    return (
        <Wrapper className={className}>
            <Doughnut options={options} data={data} plugins={[centerdText]} />
        </Wrapper>
    );
};

export default DoughnutChart;
