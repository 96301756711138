import { AddOnPlan, AddOnPlanReponse, AddOnsEnum, IBillingInfo, ICheckout, IPaymenPlan, IPaymentPlans, PreviewInvoice } from './../Models/App/PaymentModels';
import { ICommonApiRequestValues } from '../Models/App/DataRoom/ICommonApiRequestValue';
import { ICustomerDetails, ICustomerSubscribe, IDashboardData, IPlanUsage, PaymentIntentModel } from '../Models/App/PaymentModels';
import { BaseService } from './BaseService';
import { HigherLevelResponse, HighLevelErrorResponse, paymentApi } from './Axios';
import { ServerResponseApi } from '../Models/API/All/server-response';
import { isNumber } from '../Shared/Utilities';
import { PaymentPlanEnum } from '../Models/API/enums';

export default class PaymentService extends BaseService {
	endPoints = {
		// CreatePaymentIntent: "Billing/subscription",
		Plans: 'plan/plans',
		Invoices: (companyId: number) => `Customer/invoices/${companyId}`,
		CustomerSubscribe: (companyId: number) => `Customer/subscribe/${companyId}`,
		CustomerCard: (companyId: number) => `Customer/card/${companyId}`,
		CustomerDetails: (companyId: number) => `Customer/${companyId}`,
		CustomerUpdate: (companyId: number) => `Customer/${companyId}`,
		PlanUsage: (companyId: number) => `Plan/usage/${companyId}`,
		PlanDashboardData: (companyId: number) => `Plan/DashboardData/${companyId}`,
	};

	commonValues: ICommonApiRequestValues;

	constructor(companyID: number) {
		super();
		this.commonValues = {
			company_Id: companyID,
		};
	}

	async CreatePaymentIntent(companyId: number, planId: number, reCaptchaToken: string) {
		return await this.safeExecuteAsync(async () => {
			const response = await this.httpPayment.post<PaymentIntentModel>(this.endPoints.CustomerSubscribe(companyId), {
				planId,
				reCaptchaToken,
			});
			const data = response.data;

			return data;
		});
	}

	async getPlans(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpPayment.get<IPaymentPlans>(this.endPoints.Plans)).data;
			return data;
		});
	}

	async getInvoices(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpPayment.get<any>(this.endPoints.Invoices(companyId))).data;
			return data;
		});
	}

	async getCustomerDetails(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpPayment.get<ICustomerDetails>(this.endPoints.CustomerDetails(companyId))).data;

			return data;
		});
	}

	async updateBillingContact(companyId: number, billingContact: IBillingInfo, reCaptchaToken: string) {
		return await this.safeExecuteAsync(async () => {
			const response = await this.httpPayment.put<ICustomerDetails>(this.endPoints.CustomerUpdate(companyId), {
				...billingContact,
				reCaptchaToken,
				companyId,
			});
			const data = response.data;

			return data;
		});
	}

	async getPlanUsage(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpPayment.get<IPlanUsage>(this.endPoints.PlanUsage(companyId))).data;

			return data;
		});
	}

	async getCurrentPlan(companyId: number) {
		return paymentApi.get<IPaymenPlan>(`Plan/plan/${companyId}`);
		// return await this.safeExecuteAsync(async () => {
		// 	const data = (await this.httpPayment.get<IPaymenPlan>(this.endPoints.plan(companyId))).data;

		// 	return data;
		// });
	}

	async newPlanCustomerSubscribe(companyId: number, planId: number, reCaptchaToken: string, checkoutInfo: ICheckout) {
		return paymentApi.post<ServerResponseApi<ICustomerSubscribe>>(`Customer/subscribe/${companyId}`, {
			...checkoutInfo,
			planId,
			reCaptchaToken,
		});
	}

	async updatePlanCustomerSubscribe(companyId: number, planId: number, reCaptchaToken: string) {
		return paymentApi.put<ServerResponseApi<ICustomerSubscribe>>(`Customer/subscribe/${companyId}`, {
			newPlanId: planId,
			reCaptchaToken,
		});
	}

	async updatCustomerCard(companyId: number, planId: number, reCaptchaToken: string) {
		return await this.safeExecuteAsync(async () => {
			const response = await this.httpPayment.put<ICustomerSubscribe>(this.endPoints.CustomerCard(companyId), {
				newPlanId: planId,
				reCaptchaToken,
			});

			const data = response.data;
			return data;
		});
	}

	async getDashboardData(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const response = await this.httpPayment.get<IDashboardData>(this.endPoints.PlanDashboardData(companyId));

			return response.data;
		});
	}

	async updateBillingInfo(info: IBillingInfo, companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const response = await this.httpPayment.put<ICustomerDetails>(this.endPoints.CustomerUpdate(companyId), info);
			return response.data;
		});
	}

	async updateCurstomerCard(reCaptchaToken: string, companyId: number) {
		return await this.safeExecuteAsync(async () => {
			return (await this.httpPayment.put(this.endPoints.CustomerCard(companyId), { reCaptchaToken })).data;
		});
	}

	getAddOnsPlans(addonCode?: AddOnsEnum) {
		return paymentApi.get<ServerResponseApi<AddOnPlanReponse>>('/AddOn/addons' + (isNumber(addonCode) ? `/${addonCode}` : ''));
	}

	purchaseAddon(addOnId: number, quantity: number, recaptchaToken: string) {
		return paymentApi.post<ServerResponseApi<AddOnPlan[]>>('/Customer/add-on', { addOnId, quantity, recaptchaToken });
	}

	getValuationAddOn(valuationProjectId: string) {
		return paymentApi.get<ServerResponseApi<AddOnPlanReponse>>(`/AddOn/addon-external/${valuationProjectId}`);
	}

	purchaseValuationAddon(addOnId: number, valuationProjectId: string, recaptchaToken: string) {
		return paymentApi.post<ServerResponseApi<AddOnPlan[]>>('/Customer/addon-payment', { addOnId, externalId: valuationProjectId, recaptchaToken });
	}

	getPreviewInvoice(planId: PaymentPlanEnum) {
		return paymentApi.get<ServerResponseApi<PreviewInvoice>>('/Customer/invoice/preview/' + planId);
	}
}
