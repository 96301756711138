import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import classNames from 'classnames';
import { CSSProperties, forwardRef, isValidElement, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IC_CHECK_PURPLE } from '../../../Assets';
import { NumberOption } from '../../../Models/API/All/NumberOption';
import { KeyDownEnum } from '../../Enums';
import useEffectOnce from '../../Hooks/useEffectOnce';
import { checkQaidConvention, isNullOrUndefined, isObject, isString } from '../../Utilities';
import Flex from '../Layout/Flex';
import Tooltip from '../Tooltip';
import StyledSelect from './Select.Style';
import { InputRef } from '../../../Models/App/Inputs/types';
import { Comment } from '../Input/Input.Style';
import Image from '../Image';
import OverflowText from '../OverflowText';

// interface ResizeObserverOptions {
//   /**
//    * Sets which box model the observer will observe changes to. Possible values
//    * are `content-box` (the default), and `border-box`.
//    *
//    * @default 'content-box'
//    */
//   box?: "content-box" | "border-box" | "device-pixel-content-box" | undefined;
// }

interface ResizeObserverSize {
	readonly inlineSize: number;
	readonly blockSize: number;
}

// interface ResizeObserver {
//   disconnect(): void;
//   observe(target: Element, options?: ResizeObserverOptions): void;
//   unobserve(target: Element): void;
// }

// interface ResizeObserverCallback {
//   (entries: ResizeObserverEntry[], observer: ResizeObserver): void;
// }

interface ResizeObserverEntry {
	readonly target: HTMLDivElement;
	readonly contentRect: DOMRectReadOnly;
	readonly borderBoxSize: ReadonlyArray<ResizeObserverSize>;
	readonly contentBoxSize: ReadonlyArray<ResizeObserverSize>;
	// readonly devicePixelContentBoxSize: ReadonlyArray<ResizeObserverSize>;
}

export interface ISelectProps {
	options: NumberOption[] | undefined;
	onChange: (value: number | string, name?: string) => void;
	onBlur?: () => void;
	autoTranslate?: boolean;
	label?: string;
	info?: string;
	value?: any;
	required?: boolean;
	defaultValue?: string;
	disabled?: boolean;
	width?: number | string;
	height?: number;
	name?: string;
	qaid: string;
	className?: string;
	style?: CSSProperties;
	isLoading?: boolean;
	isStandalone?: boolean;
	flat?: boolean;
	error?: string;
	children?: React.ReactChild | React.ReactChild[] | false | Element;
	isViewMode?: boolean;
	scrollAfter?: number;
	endIcon?: string | { src: string; onClick: () => void; width?: string | number };
}

export type Positioning = {
	bottom?: boolean;
	top?: boolean;
	left?: boolean;
	right?: boolean;
};

const Select = forwardRef<InputRef, ISelectProps>(
	(
		{
			options = [],
			onChange,
			autoTranslate = true,
			label,
			value,
			info,
			required,
			defaultValue = 'Select...',
			disabled,
			name,
			qaid,
			className,
			isLoading,
			children,
			isStandalone,
			error,
			flat,
			isViewMode = false,
			style,
			scrollAfter,
			endIcon,
			...rst
		},
		ref
	) => {
		checkQaidConvention(qaid);
		const [isOpen, setIsOpen] = useState<boolean>();
		const { t } = useTranslation();
		const [positioning, setPositioning] = useState<Positioning>({} as Positioning);
		const selectRef = useRef<HTMLDivElement>(null);
		const optionsRef = useRef<HTMLDivElement>(null);
		const [optionsStyle, setOptionsStyle] = useState<CSSProperties>();
		const [isOpenOnce, setIsOpenOnce] = useState<boolean>(false);
		const [err, setErr] = useState<string>();
		const optionsRefs = useRef<HTMLDivElement[]>([]);
		const [optionFocusId, setOptionFocusId] = useState<number>(-1);

		const selectedOption = useMemo(() => {
			return options.find((option: NumberOption) => option.value === value);
		}, [value, options]);

		useImperativeHandle(ref, () => ({
			resetError() {
				setIsOpenOnce(false);
			},
			showError() {
				setIsOpenOnce(true);
			},
			focus() {
				selectRef?.current?.focus();
				selectRef?.current?.scrollIntoView({ behavior: 'smooth' });
				// dateRef?.current?.setFocus();
			},
			clear() {
				// setInternalValue("");
			},
			getName() {
				return name;
			},
			value: selectedOption,
		}));

		useEffect(() => {
			optionsRefs.current[optionFocusId]?.focus();
			// Daniel: start scrolling after third option is selected for smoothbess, -2 is to center the focused element in the list
			optionFocusId > 1 && optionsRefs.current[optionFocusId - 2]?.scrollIntoView({ behavior: 'smooth' });
		}, [optionFocusId]);

		useEffect(() => {
			if (!isOpen || positioning.bottom || positioning.top) return;

			(async () => {
				await new Promise((res) => setTimeout(res, 10));
				if (!optionsRef.current) return setPositioning({ bottom: true, top: false });

				const rect = optionsRef.current.getBoundingClientRect();
				const isTop = rect.top + rect.height > window.innerHeight;
				setPositioning({ top: rect.top + rect.height > window.innerHeight, bottom: !isTop });
			})();
		}, [isOpen, positioning, optionsRef.current]);

		useEffect(() => {
			setErr(undefined);
		}, [value]);

		const hideOptions = () => {
			isOpen && setIsOpenOnce(true);
			setIsOpen(false);
			setOptionFocusId(-1);
		};

		const onKeyDownHandler = (e: any) => {
			e.preventDefault();

			if ((e.keyCode === KeyDownEnum.Enter || e.keyCode === KeyDownEnum.Space) && optionFocusId === -1) {
				!disabled && setIsOpen((state) => !state);
			}

			if (isNullOrUndefined(optionsRef.current)) return;

			if (e.keyCode === KeyDownEnum.ArrowDown) {
				setOptionFocusId((prev) => (prev + 1 < options.length ? prev + 1 : prev)); // Not going above idx options.length - 1
			}
			if (e.keyCode === KeyDownEnum.ArrowUp) {
				setOptionFocusId((prev) => (prev - 1 < 0 ? prev : prev - 1)); // Not going below idx 0
			}
			if (e.keyCode === KeyDownEnum.Enter && optionFocusId > -1) {
				onChange(options[optionFocusId].value, name);
				hideOptions();
			}
		};

		const errorToDisplay = err || error;

		const SelectComponent = isViewMode ? (
			<Flex height="3.6rem" align="end" justify="start">
				<OverflowText>
					{isValidElement(selectedOption?.label) ? selectedOption?.label : isString(selectedOption?.label) ? t(selectedOption?.label) : '---------'}
				</OverflowText>
			</Flex>
		) : (
			<>
				<ClickAwayListener onClickAway={hideOptions} mouseEvent="onMouseDown">
					<Flex direction="column">
						<Flex flex={1} style={{ position: isStandalone ? 'unset' : 'relative' }}>
							<StyledSelect.Select
								ref={selectRef}
								className={classNames('select w-100', { flat })}
								onClick={(e: any) => {
									// e.stopPropagation();
									!disabled && !isLoading && setIsOpen((state) => !state);
								}}
								onKeyDown={onKeyDownHandler}
								isOpen={isOpen}
								disabled={disabled || isLoading}
								tabIndex={0}
								data-qaid={qaid}
								isSelected={!isNullOrUndefined(value) && options.some((opt) => opt.value === value)}
								style={!!children ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}}
								isStandalone={isStandalone || !!options.length}
								{...rst}
							>
								<>
									<div className="wrapper" style={{ marginTop: 2 }}>
										<div className="value text-ellipsis">
											{autoTranslate
												? isValidElement(selectedOption?.label)
													? selectedOption?.label
													: isString(selectedOption?.label)
													? t(selectedOption?.label)
													: `${t(defaultValue)}`
												: selectedOption?.label || `${t(defaultValue)}`}
										</div>
										{isLoading ? (
											<CircularProgress className="loading-spinner" size={14} />
										) : (
											<div className={endIcon ? 'flex gap-1 align-center' : undefined}>
												{endIcon && (
													<Image
														src={isString(endIcon) ? endIcon : endIcon.src}
														onClick={
															isObject(endIcon)
																? (e) => {
																		e.stopPropagation();
																		endIcon.onClick();
																  }
																: undefined
														}
														alt="lookup"
														width={isString(endIcon) ? '2rem' : endIcon.width}
														className={classNames(`__end-icon`, {
															pointer: isObject(endIcon) ? !!endIcon.onClick : false,
														})}
													/>
												)}
												<div className={`arrow ${isOpen ? 'up' : 'down'}`}></div>
											</div>
										)}
									</div>
									<Fade unmountOnExit in={isOpen}>
										<StyledSelect.OptionsContainer scrollAfter={scrollAfter} ref={optionsRef} positioning={positioning}>
											{!options.length && <StyledSelect.Option className="disabled">{t('general.none')}</StyledSelect.Option>}
											{options.map((option: NumberOption, idx: number) => (
												<StyledSelect.Option
													ref={(el: HTMLDivElement) => {
														if (optionsRefs.current) optionsRefs.current[idx] = el;
													}}
													onClick={() => option.value !== value && onChange(option.value, name)}
													className={classNames({ selected: value === option.value })}
													key={option.value}
													tabIndex={idx}
													data-qaid={`Select.Option.${option.value}`}
												>
													<span className="text-ellipsis">
														{autoTranslate
															? isValidElement(option?.label)
																? option?.label
																: isString(option?.label)
																? t(option?.label)
																: '---------'
															: option.label}
													</span>
													<Image
														width="1.9rem"
														src={IC_CHECK_PURPLE}
														alt="checkmark"
														className={classNames('option-checkmark', {
															show: value === option.value,
														})}
													/>
												</StyledSelect.Option>
											))}
										</StyledSelect.OptionsContainer>
									</Fade>
								</>
							</StyledSelect.Select>
							{children}
						</Flex>
					</Flex>
				</ClickAwayListener>
				<Fade in={isOpenOnce && !!errorToDisplay} unmountOnExit>
					<Comment error>{errorToDisplay}</Comment>
				</Fade>
			</>
		);

		if (isStandalone) return SelectComponent;

		return (
			<StyledSelect.Wrapper className={classNames(className, { flat })} style={style}>
				<label className="label" htmlFor="">
					{(label || info) && (
						<div className="text">
							{!!label && (
								<span className="text-ellipsis">
									{t(label)} {required && !isViewMode ? '*' : ''}
								</span>
							)}
							{!!info && <Tooltip style={{ marginLeft: 'auto' }} title={autoTranslate && info ? t(info) : info} />}
						</div>
					)}
					{SelectComponent}
				</label>
			</StyledSelect.Wrapper>
		);
	}
);

export default Select;
