import { JsxElement } from "typescript";
import useModal from "../../Shared/Hooks/useModal";
import useRootStore from "../../Shared/Hooks/useRootStore";
import TrusteeBeneficiaryCard from "./Dashboard/TrusteeBeneficiaries/BeneficiaryCard/TrusteeBeneficiaryCard";
import TrusteeGrantCard from "./Dashboard/TrusteeGrants/GrantCard/TrusteeGrantCard";
import { toJS } from "mobx";
import TerminationRequest from "./Dashboard/TrusteeBeneficiaries/TerminationRequest/TerminationRequest";
import { OpenTerminationRequest, TrusteeBeneficiaries } from "./Types/trusteeTypes";
import Button from "../../Shared/Components/Button/Button";
import TerminationRequestTermination from "./Dashboard/TrusteeBeneficiaries/TerminationRequest/CancelRequest/TerminationRequestTermination";
import { IPivotalCompany } from "../../Models/App";
import { ReqTypePivotal } from "../../Services/TrusteeService";
import Login from "../CapTable/Components/General/OptionsSync/Pivotal/Login";
import { useHistory } from "react-router-dom";
import Table, { TableColumn } from "../../Shared/Components/Table/Table";
import { IC_EXCEL_GREEN } from "../../Assets";


const useTrusteeModals = () => {
    const { trusteeStore, companyStore, auth } = useRootStore();
    const { showModal, clearModals, removeCurrentModal } = useModal();
    const history = useHistory()

    const showSuccessModal = (body?: JsxElement | string) => {
        showModal({
            body: <>{body}</>,
            type: "success",
            timeout: 1400,
            width: "44.5rem",
            showProgressBar: false,
        });
    };

    const showGrantCard = (grantId: string) => {
        trusteeStore.grantId = grantId

        trusteeStore.GetTrusteeGrantAndVests(grantId)
        trusteeStore.GetTrusteeGrantOrders(grantId)
        trusteeStore.GetTrusteeGrantCancelations(grantId)

        showModal({
            body: <TrusteeGrantCard />,
            height: "100%",
            width: "70%",
            isFrameless: true,
        });
    };

    const showBeneficiaryCard = (beneficiaryId: string) => {
        trusteeStore.beneficiaryId = beneficiaryId

        trusteeStore.GetTrusteeBeneficiaryGrants(beneficiaryId)
        trusteeStore.GetTrusteeBeneficiaryOrders(beneficiaryId)
        trusteeStore.GetTrusteeBeneficiaryCancelations(beneficiaryId)

        const bnficri = trusteeStore.trusteeBeneficiariesTable.beneficiarys.filter((ben) => ben.beneficiaryId === beneficiaryId)[0];

        showModal({
            body: <TrusteeBeneficiaryCard beneficiary={bnficri} />,
            height: "100%",
            width: "70%",
            isFrameless: true,
        });
    };

    const showTerminationModal = (beneficiaryRow: TrusteeBeneficiaries) => {
        showModal({
            body: <TerminationRequest benData={beneficiaryRow} />,
            width: '58rem',
            height: '64rem'
        })
    }

    const showTerminationCancelationModal = (data: OpenTerminationRequest) => {
        showModal({
            body: <TerminationRequestTermination data={data} />
        })
    }

    const showPivotalRegistration = (is420?: boolean, initialRegistrartion?: boolean) => {
        // console.log('PIV reg opening 420: ', is420)

        const registerUserToPivotal = async (rcpToken: string, cmp: IPivotalCompany): Promise<boolean> => {

            if (!auth.pivotalToken) {
                return false
            }

            const obj: ReqTypePivotal = {
                companyId: trusteeStore.companyId,
                productsSchema: 1,
                reCaptchaToken: rcpToken,
                token: auth.pivotalToken,
                trusteeCompanyId: cmp.companyId,
            };

            const res = await trusteeStore.SetUserCompanyToken(obj)

            if (res.data?.companyId) {
                const res = await companyStore.SetCompanyEquitySource(true)
                trusteeStore.setRegistration = false

                await trusteeStore.GetPoolSummary()
                return true
            }
            return false
        }

        console.log('PIV reg OPEN')
        showModal({
			body: <Login onCompanySelect={registerUserToPivotal} initialRegistrartion={initialRegistrartion} />,
			isMust: true,
			qaid: 'Trustee.Modal.Login',
			onCancel: () => {
				// console.log('closing 420', is420)
				trusteeStore.setRegistration = false;

				if (!is420) return;

				// console.log('Modal cancelling')
				history.push('/ESOP/settings');
			},
		});
    }

    const showReportPreviewModal = (tableData: any, reportName: string) => {
        const keys: string[] = Object.keys(tableData[0]);
        const columnsTable: TableColumn<any>[] = keys.map(key => { return { name: key, label: key } });

        showModal({
            type: "dialog",
            width: '170rem',
            body: <>
                <p style={{ display: 'flex', alignContent: 'center', alignItems: 'center', marginBottom: '2rem' }}>
                    <img
                        src={IC_EXCEL_GREEN}
                        width={20}
                        style={{ marginRight: '1rem' }}
                    />
                    <h4>{reportName}</h4>
                </p>

                <Table
                    columns={columnsTable}
                    rows={tableData}
                />
            </>,
        });
    };

    return {
        showGrantCard,
        showBeneficiaryCard,
        showSuccessModal,
        showTerminationModal,
        showTerminationCancelationModal,
        showPivotalRegistration,
        showReportPreviewModal
    }
}

export default useTrusteeModals;
