import { observer } from 'mobx-react-lite';
import { forwardRef, ReactElement, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DataCollectionFieldTypeEnum } from '../../../../../../Models/API/DataCollection/comment-response';
import { ValuationDocumentType } from '../../../../../../Models/API/DataCollection/document-type';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import UploadFile from '../../../../../../Shared/Components/UploadFile';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { base64ToFile, fileToBase64 } from '../../../../../../Shared/Utilities';
import { WaterfallStep } from '../../../../../Waterfall/Components/AddEditWaterfall/index.style';
import { DataCollectionPermissionsEnum, hasProjectPermissions } from '../../../../helpers/utils';
import { RefsType } from '../../../../types';
import Question from '../../../Question';

type LoadingState = Record<DataCollectionFieldTypeEnum, boolean>;
type FileState = Record<DataCollectionFieldTypeEnum, File>;

const AddDocuments = forwardRef<ForwardedRef, unknown>((_, forwardedRef) => {
	const { dataCollectionStore } = useRootStore();
	const hasUpdatePermissions = hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.UPDATE_PROJECT);
	const [isLoading, setIsLoading] = useState<LoadingState>({} as LoadingState);
	const [files, setFiles] = useState<FileState>({} as FileState);
	const questionsRef = useRef<RefsType>({} as RefsType);
	const isDisabled = !hasUpdatePermissions || dataCollectionStore.isProjectDisabled;

	useImperativeHandle(forwardedRef, () => ({
		onValidate: async () => {
			// Assuming DataCollectionFieldTypeEnum is an enum and `RefsType` uses it as keys
			const promises = Object.keys(questionsRef.current || []).map((key) => {
				const typedKey = key as unknown as keyof RefsType;

				const comment = questionsRef.current?.[typedKey].getData();
				if (!comment || !dataCollectionStore.valuationProjectId) return;

				return dataCollectionStore.addComment({
					fieldType: +typedKey,
					commentDescription: comment,
					valuationProjectId: dataCollectionStore.valuationProjectId,
				});
			});

			await Promise.all([...promises, hasUpdatePermissions ? dataCollectionStore.updateDataCollection() : undefined]);

			return true;
		},
	}));

	useEffect(() => {
		if (!dataCollectionStore.documents?.length || Object.keys(files).length) return;
		const updatedFiles = dataCollectionStore.documents.reduce((acc, doc) => {
			if (doc.fileName) {
				acc[doc.type] = base64ToFile('', doc.fileName, 'pdf'); // Daniel: to add filebase 64 after getting aws link
			}
			return acc;
		}, files);
		setFiles(updatedFiles);
	}, [dataCollectionStore.documents, files]);

	if (!dataCollectionStore.documents?.length) {
		return (
			<WaterfallStep>
				<Spinner center incorporated />
			</WaterfallStep>
		);
	}

	const getTitle = (type: ValuationDocumentType): string | ReactElement => {
		switch (type) {
			case DataCollectionFieldTypeEnum.IssuanceDocument:
				return 'Please attach the latest investment agreement';
			case DataCollectionFieldTypeEnum.ArticleOfAssociation:
				return 'Please attach the comapny’s Articles of Association agreement (AOA)';
			case DataCollectionFieldTypeEnum.FinancialStatement:
				return 'Please attach the latest financial statements of the company';
			case DataCollectionFieldTypeEnum.DiscountCashFlow:
				return 'Discount';
			case DataCollectionFieldTypeEnum.Dcf:
				return (
					<div>
						Please download and fill out the&nbsp;
						<span className="link" onClick={() => window.open(process.env.REACT_APP_DCF_URL, '_blank')}>
							DCF Excel model
						</span>
						&nbsp;and upload it back
					</div>
				);
			case DataCollectionFieldTypeEnum.SafeOrConvertible:
				return 'Please attach the SAFE/ Convertible loan agreement';
			case DataCollectionFieldTypeEnum.TimeToLiquidity:
				return 'Please attach the latest financial statements of the company (optional)';

			case DataCollectionFieldTypeEnum.CompanyProfitableInFuture:
				return 'CompanyProfitableInFuture';
			case DataCollectionFieldTypeEnum.GrantStatus:
				return 'Please upload the options ledger (grant status) as of the valuation date (optional).';
			default:
				return '';
		}
	};

	const onUploadHandler = async (file: File, documentType: ValuationDocumentType, documentId: string) => {
		const fileBase64 = await fileToBase64(file);
		if (!fileBase64 || !dataCollectionStore.dataCollectionProjectId) return;
		handleLoading(documentType, true);
		const res = await dataCollectionStore.uploadDocument(
			{ dataCollectionId: dataCollectionStore.dataCollectionProjectId, documentType, fileBase64, fileName: file.name },
			documentId
		);
		handleLoading(documentType, false);
		if (!res.data) return;

		setFiles((prevFiles) => ({
			...prevFiles,
			[documentType]: base64ToFile(fileBase64, res.data?.data.fileName, 'pdf'),
		}));
	};

	const handleLoading = (documentType: ValuationDocumentType, isLoading: boolean) =>
		setIsLoading((prevState) => ({ ...prevState, [documentType]: isLoading }));

	return (
		<WaterfallStep small>
			{dataCollectionStore.documents.map((document) => (
				<Question
					key={document.type}
					title={getTitle(document.type)}
					fieldType={document.type}
					headeredComment
					renderActions={
						<UploadFile
							accept={['pdf', 'excel']}
							onFileUpload={(file) => !isDisabled && onUploadHandler(file, document.type, document.id)}
							isLoading={isLoading[document.type]}
							extensionError="Only supported files can be used for the import cap table process"
							file={files[document.type]}
							onPreview={() => {
								document.filePath && window.open(document.filePath, '_blank');
							}}
							disabled={isDisabled}
						/>
					}
					ref={(el) => {
						if (questionsRef.current && el) {
							questionsRef.current[document.type] = el;
						}
					}}
				/>
			))}
		</WaterfallStep>
	);
});

export default observer(AddDocuments);
