import { Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useRef } from 'react';
import { Scatter } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { colors } from '../../../../../../../config/style/lib/colors';
import { isNumber } from '../../../../../../../Shared/Utilities';
import { ScatterChartPoint, ScatterChartProps } from '../../../../../../Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types';

export function removeLineOnSlopeZero(points: ScatterChartPoint[]) {
	const filteredArray = points.filter((point, index, array) => {
		if (index === 0 || index === array.length - 1) {
			return true;
		}

		const previousPoint = array[index - 1];
		const nextPoint = array[index + 1];

		return point.y !== previousPoint.y || point.y !== nextPoint.y;
	});

	return filteredArray.reduce((acc: ScatterChartPoint[][], point) => {
		if (acc.length === 0 || acc[acc.length - 1].some((p) => p.y === point.y)) {
			acc.push([point]);
		} else {
			acc[acc.length - 1].push(point);
		}

		return acc;
	}, []);
}

const ValuationCardScatterChart = (props: ScatterChartProps) => {
	const scatterRef = useRef<ChartJSOrUndefined<'scatter', ScatterChartPoint[], unknown> | null>();
	ChartJS.register(...registerables, zoomPlugin);
	// ChartJS.defaults.font = {
	//     size: appState.fontSize
	// }

	const dataVisible = props.data.filter((bp) => !bp.hide);
	const datasets = removeLineOnSlopeZero(props.data);
	const MAX_POINT_X = Math.max(...dataVisible.map((d) => d.x), props.exitValuePoint?.x ?? 0);
	const MIN_POINT_X = Math.min(...dataVisible.map((d) => d.x), props.exitValuePoint?.x ?? MAX_POINT_X);
	const MAX_POINT_Y = Math.max(...dataVisible.map((d) => d.y), props.exitValuePoint?.y ?? 0);
	const MIN_POINT_Y = Math.min(...dataVisible.map((d) => d.y), props.exitValuePoint?.y ?? MAX_POINT_Y);

	const GRAPH_X_TRESHHOLD = (MAX_POINT_X / 100) * ((isNumber(props.graphTreshhold) ? props.graphTreshhold : props.graphTreshhold?.x) ?? 9);
	const GRAPH_Y_TRESHHOLD = (MIN_POINT_Y / 100) * ((isNumber(props.graphTreshhold) ? props.graphTreshhold : props.graphTreshhold?.y) ?? 9);

	const options: ChartOptions<'scatter'> = {
		scales: {
			x: {
				min: MIN_POINT_X - GRAPH_X_TRESHHOLD,
				max: MAX_POINT_X + GRAPH_X_TRESHHOLD,
				beginAtZero: false,
				type: 'linear',
				title: {
					display: true,
					text: props.xAxisLabel,
					font: {
						size: 14,
					},
				},
			},
			y: {
				min: MIN_POINT_Y - GRAPH_Y_TRESHHOLD,
				max: MAX_POINT_Y + GRAPH_Y_TRESHHOLD,
				beginAtZero: false,
				title: {
					display: true,
					text: props.yAxisLabel,
					font: {
						size: 14,
					},
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					beforeTitle: (tooltipItems) => {
						// Filter to keep only the first tooltip item
						tooltipItems.splice(1); // Keep only the first item
						return ''; // No need for a title
					},
				},
			},
			// zoom: {
			// 	zoom: {
			// 		wheel: {
			// 			enabled: true,
			// 			speed: 0.1,
			// 		},
			// 	},
			// 	limits: {
			// 		y: {
			// 			min: MIN_POINT_Y - GRAPH_Y_TRESHHOLD,
			// 			max: MAX_POINT_Y + GRAPH_Y_TRESHHOLD,
			// 		},
			// 		x: {
			// 			min: MIN_POINT_X - GRAPH_X_TRESHHOLD,
			// 			max: MAX_POINT_X + GRAPH_X_TRESHHOLD,
			// 		},
			// 	},
			// 	pan: {
			// 		enabled: true,
			// 		mode: 'xy',
			// 		scaleMode: 'x',
			// 	},
			// },
		},
	};

	const datasetsConfig = datasets.map((dataset, index) => ({
		label: `dataset-${index + 1}`,
		data: dataset,
		borderColor: colors.primary,
		pointBackgroundColor: colors.action,
		pointBorderColor: colors.action,
		pointRadius: 3,
		showLine: true,
		showPoint: true,
	}));

	const data = {
		datasets: !props.exitValuePoint
			? datasetsConfig
			: [
					{
						label: 'exitValue',
						data: [props.exitValuePoint],
						borderColor: colors.primary,
						pointBackgroundColor: colors.success,
						pointBorderColor: colors.success,
						pointRadius: 3,
						showLine: true,
						showPoint: true,
					},
					...datasetsConfig,
					// ...datasetsConfig.map((dataset) => ({
					// 	...dataset,
					// 	data: dataset.data.filter((point) => point.x !== props.exitValuePoint?.x && point.y !== props.exitValuePoint?.y),
					// })),
			  ],
	};

	return (
		<div style={{ position: 'relative' }}>
			<Scatter ref={scatterRef} data={data} options={options} />
		</div>
	);
};

export default ValuationCardScatterChart;
