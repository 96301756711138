
export enum reportTabsEnum {
    General,
    BusinessPartners
}

export interface ReportType {
    name: string;
    key: string;
    filters: (FilterFieldEnum)[];
}

export enum AndOrConnectorEnum {
    And = 0,
    Or = 1
}

export enum SqlOperatorEnum {
    Equal = 1,
    NotEqual,
    GreaterThan,
    LessThan,
    GreaterThanOrEqual,
    LessThanOrEqual,
    Contains,
    Between
}

export enum FilterFieldEnum {
    ForDate = 1,
    AwardType,
    ExerciseDate,
    OrderType,
    TaxTrack,
    CancelationDate,
    GrantDate,
    ContactId,
    EmployeeStatus,
    SiteId
}

export interface FilterESOPReportsType {
    field: FilterFieldEnum;
    connector: AndOrConnectorEnum;
    operator: SqlOperatorEnum;
    value: string | Date | undefined | number;
    value2?: string | Date | undefined | number;
}
