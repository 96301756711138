import { HigherLevelResponse, equityPlansApi } from './Axios';
import { GetGrantsFilterBy } from '../Models/API/enums';
import { ICommonApiRequestValues } from '../Models/App/DataRoom/ICommonApiRequestValue';
import { BenTable, IBeneficiary, BeneficiaryResponse } from '../Models/App/EquityPlans/Beneficiaries';
import { BaseService } from './BaseService';
import { BenGrant, GrantTable, IGrant } from '../Models/App/EquityPlans/Grants';
import { IGrantCancelation } from '../Models/App/EquityPlans/GrantCancelations';
import { IModifiedGrant, IBaseGrant } from '../Models/App/EquityPlans/GrantEvents';
import { CompanyCustomData, ICompanyDetails, IEquityPlan, IEquityPlanTemp, ILog } from '../Models/App/EquityPlans/Company';
import { CustomDataTypes } from '../Models/App/EquityPlans/equityEnums';
import { IOrder, OrderType, OrderTypeAndAmount } from '../Models/App/EquityPlans/Order';
import { EntityTrackHistoryType, EntityType, IDashboardData, MultiType } from '../Models/App/EquityPlans/General';
import { EntityHistoryUpdate } from '../Models/App/EquityPlans/reqModel/general';
import { EntitiyHistoryRes } from '../Models/App/EquityPlans/resModel/general';
import { PlanReq } from '../Models/App/EquityPlans/reqModel/company';
import { IUpdate } from '../Screens/Dashboard/components/Updates/Updates';
import { nulldefined } from '../Shared/Interfaces/global';
import { SaaSMainDashboardData } from '../Models/App/ESOP/SaaSMainDashboardData';
import { ConvertPromisedRequestType, PromisedType } from '../Models/App/EquityPlans/Promised';
import { DateFormatsEnum, formatDate } from '../Shared/Utilities';
import { UpcomingVestings } from '../Screens/TrusteeEquity/Types/trusteeTypes';
import { ConvertToGrantResponseType } from '../Models/API/EquityPlans/Promised';

export interface BeneficiariesResponse {
	beneficiaries: IBeneficiary[];
}

export interface CompanyBenResponse {
	data: BenTable[];
}

export default class EquityPlansService extends BaseService {
	endPoints = {
		Company: (companyId: number) => `Company/${companyId}`,
		CreateOrUpdateOptionsCompany: 'Company/CreateOrUpdateOptionsCompany',
		// LegalEntity: "LegalEntity",
		Site: 'Site',
		DeleteSite: (siteId: number, companyId: number) => `Site/${siteId}/${companyId}`,
		Department: 'Department',
		DepartmentById: (departmentId: number, companyId: number) => `Department/${departmentId}/${companyId}`,
		SubDepartment: 'SubDepartment',
		UpdateSubDepartment: (subDepartmentId: number, companyId: number) => `SubDepartment/${subDepartmentId}/${companyId}`,
		Log: 'OptionsLog',
		Plan: 'Plan',
		GetSinglePlan: (planId: number, companyId: number) => `Plan/${planId}/${companyId}`,
		DeletePlan: (planId: number, companyId: number) => `Plan/DeletePlan/${planId}/${companyId}`,
		UpdatePlan: (planId: number) => `Plan/${planId}`,
		PoolStep: 'PoolStep',
		GetPoolStepsForPlan: (companyId: number, poolId: number) => `PoolStep/poolSteps/${poolId}/${companyId}`,
		Vesting: 'Vesting',
		VestingCompany: 'VestingCompany',
		ConnectVestToCompany: 'VestingCompany/addVestingForCompany',
		DisConnectVestFromCompany: (vestingId: number, companyId: number) => `VestingCompany/removeVestingCompany/${vestingId}/${companyId}`,
		TaxTrack: 'TaxTrack',
		DeleteTaxTrack: (taxTrackId: number, companyId: number) => `TaxTrack/${taxTrackId}/${companyId}`,

		Beneficiaries: 'Beneficiary',
		GetBeneficiaries: (companyId: number, endDate: string) => `Beneficiaries?CompanyId=${companyId}${endDate ? '&EndDate=' + endDate : ''}`,
		GetBeneficiary: (companyId: number, benficiaryId: number) => `Beneficiary/${benficiaryId}/${companyId}`,
		SetBeneficiaryCCD: (beneficiaryId: number, companyId: number, ccd: CustomDataTypes) =>
			`Beneficiary/UpdateBeneficiaryCCD/${beneficiaryId}/${companyId}?ccd=${ccd}`,
		RemoveBeneficiary: (beneficiaryId: number, companyId: number) => `Beneficiary/deleteBeneficiary/${beneficiaryId}/${companyId}`,
		UpdateMultipleBeneficiaries: 'Beneficiary/updateMultiBeneficiaries',
		beneficiaryTable: 'Beneficiary/beneficiaryTable',
		benHistory: 'Beneficiary/updateBeneficiaryEntityTrackHistory',
		SetBeneficiaryEntityHistory: (companyId: number, beneficiaryId: number) =>
			`Beneficiary/updateBeneficiaryEntityTrackHistory/${beneficiaryId}/${companyId}`,

		Grant: 'Grant',
		CreateSingleGrant: 'Grant/createGrant',
		UpdateBenGrant: (companyId: number) => `Grant/${companyId}`,
		DeleteGrant: (grantId: number, companyId: number) => `Grant/deleteGrant/${grantId}/${companyId}`,
		GetGrant: (grantId: number, companyId: number) => `Grant/${grantId}/${companyId}`,
		GrantOrders: 'Transaction/Transactions',
		GrantCancelation: 'GrantCancelation',
		GetGrantCancelations: (filterBy: GetGrantsFilterBy, companyId: number, date?: Date, planId?: number) =>
			`GrantCancelation/grantCancelations?Filterby=${filterBy}&companyId=${companyId}&date=${date?.toISOString()}&planId=${planId}`,
		GetGrantCancelationsByContact: (filterBy: GetGrantsFilterBy, companyId: number, beneficiaryId: number) =>
			`GrantCancelation/grantCancelations?Filterby=${filterBy}&companyId=${companyId}&ContactId=${beneficiaryId}`,
		GetGrantCancelationsByGrant: (filterBy: GetGrantsFilterBy, companyId: number, grantId: number) =>
			`GrantCancelation/grantCancelations?Filterby=${filterBy}&companyId=${companyId}&grantId=${grantId}`,
		GetSingleGrantCancelation: (companyId: number, cancelId: number) => `GrantCancelation}/${cancelId}/${companyId}`,
		Grants: 'Grant/Grants',
		CreateGrant: 'Grant/createGrant',
		CheckIso: 'Grant/CheckIso',
		Order: 'Order',
		GetOrders: 'Order/orders',
		GetOrderById: (orderId: number, companyId: number) => `Order/${orderId}/${companyId}`,
		GetAmountForOrderType: 'Order/getAvailable',

		// GrantEvent: "GrantEvent",
		// OriginalGrantEvent: "getVestingHistory",
		// ModifiedGrantEvent: "getModifiedVests",
		GetUnModifiedGrantEvents: 'GrantEvent/getVestingHistory',
		GetModifiedGrantEvents: 'GrantEvent/getModifiedVests',
		GetLastVestDate: 'GrantEvent/SimulateLastVesting',

		// EntityTrackHistory: "EntityTrackHistory",
		GetTrackHistory: (hstryType: EntityTrackHistoryType, enttType: EntityType, entId: number, companyId: number) =>
			`EntityTrackHistory/${hstryType}/${enttType}/${entId}/${companyId}/`,

		CheckIfUserExists: (companyId: number, employeeNumber: string) =>
			`Validation/Beneficiary/CheckExistEmployeeNumber?companyId=${companyId}&employeeNumber=${employeeNumber}`,
		CheckIfGrantNumberExists: (companyId: number, grantNumber: string) =>
			`Validation/Grant/CheckExistGrantNumber?companyId=${companyId}&grantNumber=${grantNumber}`,
		ValidateGrant: 'Validation/Grant/ValidateGrant',
		ValidateTransaction: 'ValidationTransaction',
		DashboardData: 'Company/GetEquityDashboardData',
		GetCfoLogEventsForCompany: (page: number, size: number) => `OptionsLog/GetCFOLogEvents/${page}/${size}`,
		GetGrantsForBeneficiary: (beneficiaryId: number, companyId: number) => `Grant/GetGrantsForBeneficiary/${beneficiaryId}/${companyId}`,
		InviteUser: 'user/invite',

		getBeneficiaryByContactId: (companyId: number, contactId: number) => `Beneficiary/contact/${contactId}/${companyId}`,
	};

	commonValues: ICommonApiRequestValues;

	// TODO: change all compantId's

	companyId: number;

	constructor(companyID: number) {
		super();
		this.commonValues = {
			company_Id: companyID,
		};
		this.companyId = companyID;
	}

	// ******  COMPANY  *******

	GetEquitySettings(companyId: number) {
		return equityPlansApi.get<ICompanyDetails>(this.endPoints.Company(companyId));
	}

	CreateNewCompany(companyId: number, isAutoGenerated: boolean = false) {
		const data = { IsActive: true, DownloadReports: true, companyId, AutoGenerateGrantNumber: isAutoGenerated };
		return equityPlansApi.post<any>(this.endPoints.CreateOrUpdateOptionsCompany, data);
	}

	GetEquityDashboardBeneficiaries(companyId: number, EndDate: Date, planId?: number) {
		return equityPlansApi.get<BenTable[]>(this.endPoints.beneficiaryTable, { companyId, EndDate, planId });
	}

	GetDashboardData(companyId: number, date: Date, planId?: number) {
		return equityPlansApi.get<IDashboardData>(this.endPoints.DashboardData, { companyId, date, planId });
	}

	// GetCompanyBeneficiaries(companyId: number, EndDate: string = new Date().toISOString()) {
	// 	const endDateParam = EndDate ? `&EndDate=${EndDate}` : "";

	// 	const params = { params: { CompanyID: companyId, EndDate } };
	// 	return equityPlansApi.get(`${this.endPoints.Beneficiaries}/beneficiaryTable?CompanyId=${companyId}&EndDate=${EndDate}`);
	// }

	async GetBeneficiaries(companyId: number, EndDate: string = new Date().toISOString()) {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpOptions.get<BeneficiariesResponse>(this.endPoints.GetBeneficiaries(companyId, EndDate))).data.beneficiaries;
			console.log('benficiaries DATA - ESOP service', data);
			return data;
		});
	}

	// async GetGrants(companyId: number, filterBy?: GetGrantsFilterBy, employeeId?: number, EndDate: string = new Date().toISOString()) {
	// 	const contactParam = employeeId ? `&EmployeeId=${employeeId}` : "";
	// 	const endDateParam = EndDate ? `&EndDate=${EndDate}` : "";

	// 	console.log('stupid shit running')
	// 	return await this.safeExecuteAsync(async () => {
	// 		const data = (
	// 			await this.httpOptions.get<GrantTable[]>(this.`endPoints.Grant`s, {
	// 				params: { companyId, filterBy, employeeId, EndDate },
	// 			})
	// 		).data;

	// 		return data;
	// 	});
	// }

	async GetGrants(companyId: number, filterBy?: GetGrantsFilterBy, employeeId?: number, EndDate?: Date, planId?: number) {
		return await equityPlansApi.get<GrantTable[]>(this.endPoints.Grants, { companyId, EndDate, planId });
	}

	async GetBeneficiaryGrants(beneficiaryId: number, companyId: number) {
		return await equityPlansApi.get<any>(this.endPoints.GetGrantsForBeneficiary(beneficiaryId, companyId));
	}

	async CheckIfGrantNumberExists(companyId: number, grantNumber: string) {
		return await equityPlansApi.post<any>(this.endPoints.CheckIfGrantNumberExists(companyId, grantNumber));
	}

	// CreateLegalEntity(companyId: number, type: number, legalEntityName: string) {
	// 	return equityPlansApi.post(this.endPoints.LegalEntity, {
	// 		legalEntityType: type,
	// 		value: legalEntityName,
	// 		companyId,
	// 	});
	// }

	// UpdateLegalEntity(companyId: number, legalEntityId: number, newValue: string) {
	// 	return equityPlansApi.put(`${this.endPoints.LegalEntity}/${legalEntityId}/${companyId}`, {
	// 		legalEntityType: 0,
	// 		legalEntityName: newValue,
	// 	});
	// }

	// 	CCD

	async CreateDepartment(companyId: number, type: number, depName: string) {
		return await equityPlansApi.post<any>(this.endPoints.Department, {
			departmentType: type,
			value: depName,
			companyId,
		});
	}

	async UpdateDepartment(companyId: number, departmentId: number, newValue: string) {
		return await equityPlansApi.put<any>(this.endPoints.DepartmentById(departmentId, companyId), {
			departmentType: 0,
			departmentName: newValue,
		});
	}

	async DeleteDepartment(departmentId: number, companyId: number, isSubDepartment: boolean) {
		return await equityPlansApi.delete(this.endPoints.DepartmentById(departmentId, companyId), null, { isSubDepartment });
	}

	// CreateSubDepartment(companyId: number, type: number, subDepName: string) {
	// 	return equityPlansApi.post(this.endPoints.SubDepartment, {
	// 		subDepartmentType: type,
	// 		value: subDepName,
	// 		companyId,
	// 	});
	// }

	// Tax Track

	async CreateTaxTrack(companyId: number, taxName: string) {
		return await equityPlansApi.post<any>(this.endPoints.TaxTrack, {
			value: taxName,
			companyId,
		});
	}

	async DeleteTaxTrack(taxTrackId: number, companyId: number) {
		return await equityPlansApi.delete(this.endPoints.DeleteTaxTrack(taxTrackId, companyId));
	}

	// UpdateSubDepartment(companyId: number, subDepartmentId: number, newValue: string) {
	// 	return equityPlansApi.put(`${this.endPoints.SubDepartment}/${subDepartmentId}/${companyId}`, {
	// 		subDepartmentType: 0,
	// 		subDepartmentName: newValue,
	// 	});
	// }

	// SITE

	async CreateSite(companyId: number, value: string) {
		return await equityPlansApi.post<any>(this.endPoints.Site, { value, companyId });
	}

	async DeleteSite(companyId: number, siteId: number) {
		return await equityPlansApi.delete(this.endPoints.DeleteSite(siteId, companyId));
	}

	async ConnectVestToCompany(obj: any) {
		return await equityPlansApi.post<any>(this.endPoints.ConnectVestToCompany, obj);
	}

	async DisConnectVestFromCompany(vestingId: number, companyId: number) {
		return await equityPlansApi.put<any>(this.endPoints.DisConnectVestFromCompany(vestingId, companyId));
	}

	//   ******  Beneficiary  *******

	async GetBeneficiary(companyId: number, benficiaryId: number) {
		if (!companyId || !benficiaryId) {
			console.log(`GetBeneficiary No Id`);
		}
		return await equityPlansApi.get<any>(this.endPoints.GetBeneficiary(companyId, benficiaryId));
	}

	async UpdateBeneficiary(companyId: number, benficiaryId: number, data: IBeneficiary) {
		// return await equityPlansApi.put(`${this.endPoints.Beneficiaries}/${companyId}/${benficiaryId}`, data);
		return await equityPlansApi.put<BeneficiaryResponse>(this.endPoints.Beneficiaries, {
			...data,
			businessEmail: data.businessEmail?.replace(/\s/g, ''),
		});

		//  TODO: refresh Beneficiary Data
	}

	async UpdateMultipleBeneficiaries(multiType: MultiType, ids: number[], companyId: number) {
		const data = { companyId, multiType, ids };
		return await equityPlansApi.put<any>(this.endPoints.UpdateMultipleBeneficiaries, data);
	}

	async SetBeneficiaryCCD(
		companyId: number,
		beneficiaryId: number,
		data: CompanyCustomData,
		dataType: CustomDataTypes,
		startDate: string | Date,
		endDate?: string | Date
	) {
		return await equityPlansApi.put(this.endPoints.SetBeneficiaryCCD(beneficiaryId, companyId, dataType), data);
	}

	async SetBeneficiaryEntityHistory(companyId: number, beneficiaryId: number, data: EntityHistoryUpdate) {
		return await equityPlansApi.put<any>(this.endPoints.SetBeneficiaryEntityHistory(companyId, beneficiaryId), data);
	}

	async CheckIfUserExists(companyId: number, employeeNumber: string) {
		return await equityPlansApi.post<any>(this.endPoints.CheckIfUserExists(companyId, employeeNumber));
	}

	async CreateBeneficiary(beneficiary: any) {
		return await equityPlansApi.post<IBeneficiary>(this.endPoints.Beneficiaries, {
			...beneficiary,
			businessEmail: beneficiary.businessEmail?.replace(/\s/g, ''),
		});
	}

	async RemoveBeneficiary(beneficiaryId: number, companyId: number) {
		return await equityPlansApi.put<any>(this.endPoints.RemoveBeneficiary(beneficiaryId, companyId));
	}

	async InviteToEmployeeManagment(contactId: number, companyId: number) {
		const data = { contactId, companyId, permission: { employeeManagementPermission: 5 } };
		return await this.httpUserManagement.post<any>(this.endPoints.InviteUser, data);
	}

	//   ******  GRANT  *******

	async ValidateGrant(grant: any) {
		// return new Promise((res) => {
		// 	const data = {
		// 		isValid: false,
		// 		errors: [
		// 			{
		// 				editGrantField: 0,
		// 				errorMessage: "Incorrect grantId",
		// 			},
		// 			{
		// 				editGrantField: 1,
		// 				errorMessage: "Incorrect grantId",
		// 			},
		// 			{
		// 				editGrantField: 2,
		// 				errorMessage: "Incorrect dsaadsdasdas",
		// 			},
		// 			{
		// 				editGrantField: 3,
		// 				errorMessage: "Incorrect adsasasttastatastas",
		// 			},
		// 			{
		// 				editGrantField: 4,
		// 				errorMessage: "Incorrect adsasasttastatastas",
		// 			},
		// 			{
		// 				editGrantField: 5,
		// 				errorMessage: "Incorrect adsasasttastatastas",
		// 			},
		// 			{
		// 				editGrantField: 6,
		// 				errorMessage: "Incorrect adsasasttastatastas",
		// 			},
		// 			{
		// 				editGrantField: 7,
		// 				errorMessage: "Incorrect adsasasttastatastas",
		// 			},
		// 		],
		// 	};
		// 	setTimeout(() => res({ status: 400, data }), 500);
		// });
		return await equityPlansApi.post<{ status: number; data: any }>(this.endPoints.ValidateGrant, grant);
	}

	async CreateGrant(grant: any) {
		return await equityPlansApi.post<any>(this.endPoints.CreateGrant, grant);
	}

	async CheckIsoReq(grant: any) {
		return await equityPlansApi.post<any>(this.endPoints.CheckIso, grant);
	}

	async CreateSingleGrant(newGrant: any) {
		return await equityPlansApi.post<any>(this.endPoints.CreateSingleGrant, newGrant);
	}

	async UpdateBenGrant(grant: any, companyId: number) {
		console.log('SERVICE GRANT ', grant, companyId, grant.grantId);
		return await equityPlansApi.put<any>(this.endPoints.UpdateBenGrant(companyId), grant);
	}

	async DeleteGrant(grantId: number, companyId: number) {
		return await equityPlansApi.put<any>(this.endPoints.DeleteGrant(grantId, companyId));
	}

	// grantId companyId
	async GetGrant(grantId: number, companyId: number) {
		// return await this.safeExecuteAsync(async () => {
		//     const data = (
		//         await this.httpOptions.get<any>(
		//             `${this.endPoints.Grant}/${grantId}/${companyId}`
		//         )
		//     ).data;
		//     console.log('GetGrant Data: ', data)
		//     return data;
		// });

		return await equityPlansApi.get<IGrant>(this.endPoints.GetGrant(grantId, companyId));
	}

	// CompanyId GrantNumber

	// async GetGrantOrders(grantId: number, contactId: number, companyId: number) {
	//     return await this.safeExecuteAsync(async () => {
	//         const data = (
	//             await this.httpOptions.get<any>(
	//                 `${this.endPoints.Order}/Orders`, { params: { companyId, contactId, grantId, FilterBy: GetGrantsFilterBy.Grant } }
	//             )
	//         ).data;
	//         console.log('GetGrantOrders Data: ', data)
	//         return data;
	//     });
	// }

	async GetGrantOrders(grantId: number, contactId: number, companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const data = (
				await this.httpOptions.get<any>(this.endPoints.GetOrders, {
					params: { companyId, contactId, grantId, FilterBy: GetGrantsFilterBy.Grant },
				})
			).data;
			console.log('GetGrantOrders Data: ', data);
			return data;
		});
	}

	async ValidateTransaction(data: Partial<IOrder>) {
		return await equityPlansApi.post(this.endPoints.ValidateTransaction, { data });
	}

	// CompanyId GrantNumber
	async GetGrantCancelations(companyId: number, filterBy: GetGrantsFilterBy, beneficiaryId?: number, grantId?: number, date?: Date, planId?: number) {
		let params = {};

		switch (filterBy) {
			case GetGrantsFilterBy.Company:
				params = { filterBy, companyId };
				return await equityPlansApi.get<IGrantCancelation[]>(this.endPoints.GetGrantCancelations(filterBy, companyId, date, planId || 0));
			case GetGrantsFilterBy.Contact:
				params = { filterBy: filterBy, companyId, beneficiaryId };
				return await equityPlansApi.get<IGrantCancelation[]>(this.endPoints.GetGrantCancelationsByContact(filterBy, companyId, beneficiaryId || 0));
			case GetGrantsFilterBy.Grant:
				params = { filterBy, companyId, beneficiaryId, grantId };
				return await equityPlansApi.get<IGrantCancelation[]>(this.endPoints.GetGrantCancelationsByGrant(filterBy, companyId, grantId || 0));
			// default:
			// 	return;
		}

		// return equityPlansApi.get(`${this.endPoints.GrantCancelation}/grantCancelations`, {params});
		// return equityPlansApi.get(`${this.endPoints.GrantCancelation}/grantCancelations?Filterby=${filterBy}&companyId=${companyId}&grantId=${grantId}`);
	}

	async GetSingleGrantCancelation(companyId: number, cancelId: number) {
		return await equityPlansApi.get<any>(this.endPoints.GetSingleGrantCancelation(companyId, cancelId));
	}

	//   ******  Order  *******

	async GetOrders(filterBy: GetGrantsFilterBy, companyId: number, contactId: number, grantId?: number) {
		let params = {};

		switch (filterBy) {
			case GetGrantsFilterBy.Company:
				params = { filterBy, companyId };
				break;
			case GetGrantsFilterBy.Contact:
				params = { filterBy, companyId, contactId };
				break;
			case GetGrantsFilterBy.Grant:
				params = { filterBy, companyId, contactId, grantId };
				break;
		}

		// const data = (
		//     await equityPlansApi.get(
		//         `${this.endPoints.GrantCancelation}/grantCancelations`,
		//         params
		//     )
		// );

		return await equityPlansApi.get<any>(this.endPoints.GetOrders, params);
	}

	async CreateNewGrantOrder(order: any) {
		return await equityPlansApi.post<any>(this.endPoints.Order, order);
	}

	async GetOrderById(orderId: number, companyId: number) {
		return await equityPlansApi.get<any>(this.endPoints.GetOrderById(orderId, companyId));
	}

	async GetAmountForOrderType(companyId: number, grantId: number, asOfDate: Date, orderType: OrderType) {
		return await equityPlansApi.get<any>(this.endPoints.GetAmountForOrderType, { companyId, grantId, asOfDate, orderType });
	}

	async GetAmountsAndOrderTypes(companyId: number, grantId: number, asOfDate: Date) {
		return await equityPlansApi.get<OrderTypeAndAmount[]>(`${this.endPoints.Order}/getAvailableAllOrderTYpes`, {
			companyId,
			grantId,
			asOfDate,
		});
	}

	//	 validateAmountForOrder()

	//    *****  Grant Cancelation  ******

	async CreateGrantCancelation(cancelationData: any) {
		return await equityPlansApi.post<IGrantCancelation[]>(this.endPoints.GrantCancelation, cancelationData);
	}

	//    *****  Grant Events  ******

	async GetUnModifiedGrantEvents(companyId: number, grantId: number) {
		const params = { CompanyId: companyId, GrantId: grantId };
		return await equityPlansApi.get<IBaseGrant[]>(this.endPoints.GetUnModifiedGrantEvents, params);
	}

	async GetModifiedGrantEvents(companyId: number, grantId: number) {
		const params = { CompanyId: companyId, GrantId: grantId };
		return await equityPlansApi.get<IModifiedGrant>(this.endPoints.GetModifiedGrantEvents, params);
	}

	async GetLastVestDate(granted: number, grantDate: Date, vestingStartDate: Date, vestingId: number, companyId: number) {
		return await equityPlansApi.get<any>(this.endPoints.GetLastVestDate, {
			granted,
			grantDate,
			vestingStartDate,
			companyId,
			vestingId,
		});
	}

	//      ****    General      ****

	async GetTrackHistory(hstryType: EntityTrackHistoryType, enttType: EntityType, entId: number, companyId: number) {
		return await equityPlansApi.get<EntitiyHistoryRes>(this.endPoints.GetTrackHistory(hstryType, enttType, entId, companyId));
	}

	async GetCompanyLog(companyId: number, fromDate: Date | string) {
		return await equityPlansApi.get<nulldefined | ILog[]>(this.endPoints.Log, { CompanyId: companyId, Date: fromDate });
	}

	async CreateNewPlan(plan: PlanReq) {
		return await equityPlansApi.post<any>(this.endPoints.Plan, plan);
	}

	async GetSinglePlan(planId: number, companyId: number) {
		return await equityPlansApi.get<IEquityPlanTemp>(this.endPoints.GetSinglePlan(planId, companyId));
	}

	async UpdatePlan(planId: number, plan: any) {
		return await equityPlansApi.put<any>(this.endPoints.UpdatePlan(planId), plan);
	}

	async DeletePlan(planId: number, companyId: number) {
		return await equityPlansApi.delete<any>(this.endPoints.DeletePlan(planId, companyId));
	}

	async AddVestingSchedule(vestSched: any) {
		return await equityPlansApi.post<any>(this.endPoints.Vesting, vestSched);
	}

	async GetPoolStepsForPlan(companyId: number, poolId: number) {
		return await equityPlansApi.get<any>(this.endPoints.GetPoolStepsForPlan(companyId, poolId));
	}

	async getBeneficiaryByContactId(companyId: number, contactId: number) {
		return equityPlansApi.get<BeneficiaryResponse>(this.endPoints.getBeneficiaryByContactId(companyId, contactId));
	}

	//     DASHBOARD

	async GetCompanyOrders(companyId: number, date?: Date, planId?: number) {
		return await equityPlansApi.get<any>(this.endPoints.GetOrders, {
			companyId,
			FilterBy: GetGrantsFilterBy.Company,
			date,
			planId,
		});
	}

	async GetSaaSMainDashboardData(companyId: number) {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpOptions.get<SaaSMainDashboardData>(`CapTable/GetSaaSMainDashboardData?companyId=${companyId}`)).data;

			return data;
		});
	}

	async GetCfoLogEvents(page: number, size: number = 10) {
		return await this.safeExecuteAsync(async () => {
			const data = (await this.httpOptions.get<IUpdate>(this.endPoints.GetCfoLogEventsForCompany(page, size))).data;
			return data;
		});
	}

	createGrantLetter(grantId: number, templateId: string, variablesCollection: string[]) {
		return equityPlansApi.post<any>('/Grant/CreateGrantLetter', {
			grantId,
			templateId,
			companyId: this.companyId,
			variablesCollection,
		});
	}

	getPromisedsList(companyId: number, planId: number) {
		return equityPlansApi.get<PromisedType[]>(`/Promised/Promiseds/${companyId}?planId=${planId}`);
	}

	createPromised(body: PromisedType) {
		return equityPlansApi.post<unknown>('Promised/createPromised', {
			...body,
		});
	}

	editPromised(body: PromisedType) {
		return equityPlansApi.put<unknown>(`Promised/${body.promisedId}/${body.companyId}`, {
			...body,
		});
	}

	deletePromised(body: PromisedType) {
		return equityPlansApi.put<unknown>(`Promised/deletePromised/${body.promisedId}/${body.companyId}`);
	}

	convertPromisedToGrant(body: ConvertPromisedRequestType) {
		return equityPlansApi.post<ConvertToGrantResponseType>(`Promised/convertPromisedToGrant`, {
			...body,
		});
	}

	getUpcomingVestingEvents(companyId: number, planId: number, date: Date) {
		return equityPlansApi.get<UpcomingVestings[]>(
			`Company/UpComingVestingsDashboard/${companyId}/${planId}?date=${formatDate(date, DateFormatsEnum.DASHED)}`
		);
	}

	getGrantStatusReport(companyId: number, filter: string) {
		return equityPlansApi.get<unknown>(`Report/grantStatusReport/${companyId}`, { filterReport: filter });
	}

	getExerciseOrdersReport(companyId: number, filter: string) {
		return equityPlansApi.get<unknown>(`Report/exerciseOrdersReport/${companyId}`, { filterReport: filter });
	}

	getCancellationsReport(companyId: number, filter: string) {
		return equityPlansApi.get<unknown>(`Report/CancellationsReport/${companyId}`, { filterReport: filter });
	}

	getPoolStatusReport(companyId: number, filter: string) {
		return equityPlansApi.get<unknown>(`Report/poolStatusReport/${companyId}`, { filterReport: filter });
	}

	getGrantsAndVestingsReport(companyId: number, filter: string) {
		return equityPlansApi.get<unknown>(`Report/grantsAndVestingsReport/${companyId}`, { filterReport: filter });
	}
}
