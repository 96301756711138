import React, { useRef, useState } from 'react'
import useRootStore from '../../../../Shared/Hooks/useRootStore'
import { observer } from 'mobx-react-lite'
import { TrusteeFileReq } from '../../Types/ReqRes'
import DropFiles from '../../../../Shared/Components/DropFiles/DropFiles'
import Button from '../../../../Shared/Components/Button/Button'
import { useHistory } from 'react-router-dom'
import { IC_DOC_PDF, IC_DOC_WORD, IC_EXCEL_GREEN, IC_SUBMIT_FILE } from '../../../../Assets'
import Select from '../../../../Shared/Components/Select/Select'
import TextInput from '../../../../Shared/Components/Input/TextInput'
import { trusteeFileTypes } from '../../StaticData/dropDownValues'
import { Loader } from '../../../../Shared/Components'
import useTrusteeModals from '../../useTrusteeModals'
import PageStyles from '../../Dashboard/AdditionalViews/AdditionalViews.module.css'
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table'
import Image from '../../../../Shared/Components/Image'
import { formatDate } from '../../../../Shared/Utilities'
import saveAs from 'file-saver';
import { SubmitFileType } from '../../../../Models/API/Trustee/submit-file'

const SubmitFiles = () => {
    const history = useHistory()
    const { showSuccessModal } = useTrusteeModals()
    const { trusteeStore } = useRootStore()
    const clearRef = useRef<{ clearAll: () => void }>()
    const [fileName, setFileName] = useState<string>()
    const [fileComments, setFileComments] = useState<string>()
    const [fileType, setFileType] = useState<number>()
    const [droppedFile, setDroppedFile] = useState<string>()
    const [fileExtension, setFileExtension] = useState<string>()
    const [loader, setLoader] = useState(false)
    const [typeError, setTypeError] = useState(false)
    const [tableData, setTableData] = useState<SubmitFileType[]>([]);

    const convertFileToBase64 = async (file: File) => {
        let binary = ''
        const abFile = await file.arrayBuffer()
        const bytes = new Uint8Array(abFile)
        const length = bytes.byteLength

        for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i])
        }

        const base64string = window.btoa(binary)
        return base64string
    }

    const fileDroppedHandler = async (file: File) => {
        const base64File = await convertFileToBase64(file)
        setDroppedFile(base64File)
        const filePath = file.name.split('.')
        setFileName(filePath[0])
        setFileExtension(filePath[1])
    }

    const onFileTypeSelect = (type: number) => {
        setFileType(type)
        setTypeError(false)
    }

    const submitFile = async () => {
        if (!fileType) {
            setTypeError(true)
            return
        } else if (!droppedFile || !fileExtension || !fileName) return

        setLoader(true)

        const fileReq: TrusteeFileReq = {
            submitFilesToTrusteeType: fileType,
            comments: fileComments,
            file: droppedFile,
            fileName: fileName,
            fileType: fileExtension
        }

        const res = await trusteeStore.TrusteeFileRequest(fileReq)

        if (res.data) {
            clearRef.current?.clearAll()
            showSuccessModal('The file has been submitted to your trustee')
            getHistoryFiels();
        }

        setLoader(false);
    }

    React.useEffect(() => {
        getHistoryFiels();
    }, []);

    const getHistoryFiels = async () => {
        const res = await trusteeStore.GetHistorySubmitFiles();
        if (res?.data?.submitFiles) {
            setTableData(res.data.submitFiles);
        }

    }

    const formsTable: TableColumn<SubmitFileType>[] = [
        {
            name: 'fileName',
            label: 'File name',
            sortable: true,
            style: { flex: 3.14 },
        },
        {
            name: 'createDate',
            label: 'Submitted on',
            sortable: true,
            format: (value) => formatDate(value)
        },
        {
            name: 'submitFilesToTrusteeType',
            label: 'Download',
            sortable: true,
            style: { flex: 0.5 },
            render(obj, value) {
                return (
                    <>
                        {obj.fileType === 'pdf' ? <Image
                            width="2.7rem"
                            src={IC_DOC_PDF}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onDownload(obj)}
                        /> : obj.fileType === 'docx' ? <Image
                            width="2.7rem"
                            src={IC_DOC_WORD}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onDownload(obj)}
                        /> : <Image
                            width="2.7rem"
                            src={IC_EXCEL_GREEN}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onDownload(obj)}
                        />}
                    </>
                );
            },
        },
    ];

    const onDownload = async (selectedFile: SubmitFileType) => {
        const res = await trusteeStore.GetSubmitFile(selectedFile.keyContactRequestId, selectedFile.submitFilesToTrusteeType);
        if (!res.data?.fileString) return;

        const ggg = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
        const base = ggg + res.data.fileString;
        saveAs(base, (res.data.fileName + '.' + res.data.fileExt));
    }

    return (
        <>
            <div className={PageStyles.mainContainer}>
                <div className={PageStyles.btnHeader}>
                    <Button
                        qaid='trustee.orders.back'
                        label='Back'
                        inverse
                        cancel
                        onClick={() => history.push('/trustee')}
                    />
                </div>
                <div className={PageStyles.pageBody}>
                    <div className={PageStyles.pageHeader}>

                        <div className={PageStyles.headerMain}>
                            <img src={IC_SUBMIT_FILE} />
                            <h3>Submit files to trustee</h3>
                        </div>
                    </div>
                    <div className={PageStyles.importFiles}>
                        <div className={PageStyles.importInput}>
                            <Select
                                label='Select the file type from the following closed list'
                                onChange={onFileTypeSelect}
                                value={fileType}
                                options={trusteeFileTypes}
                                qaid=''
                                defaultValue='Please select file type...'
                            // style={typeError ? { borderBottom: '1px solid red', borderRadius: '10px' } : {}}
                            />
                        </div>
                        <div style={{ width: '100%', marginLeft: '4rem' }}>
                            <TextInput
                                label='Comments (optional)'
                                onChange={val => setFileComments(val)}
                                value={fileComments}
                                qaid=''
                            />
                        </div>
                    </div>
                    <div className={PageStyles.importContainer}>
                        <DropFiles onFileDropped={fileDroppedHandler} ref={clearRef} />
                        {loader && <Loader />}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        {typeError && <p style={{ color: 'red' }}>Please select file type</p>}
                        <div className={PageStyles.button}>
                            <Button
                                qaid='trustee.orders.back'
                                label='Submit'
                                onClick={submitFile}
                                disabled={loader || !!!droppedFile}
                            />
                        </div>
                    </div><br /><br />
                    <div>
                        <h5>Upload History</h5><br />
                        <Table
                            columns={formsTable}
                            rows={tableData}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default observer(SubmitFiles);