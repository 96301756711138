import styled from "@emotion/styled";
import appConfig from "../../../config/config";

type MainTitle = "primary" | "secondary";

interface Props {
    type?: MainTitle;
    danger?: boolean;
    warning?: boolean;
    size?: string;
}

const Title = styled.div(
	{
		label: 'Title',
	},
	({ type = 'primary', danger = false, warning = false, size }: Props) => ({
		fontWeight: 500,
		fontSize: size || (type === 'primary' || type === 'secondary' ? '2.5rem' : '1.6rem'),
		color: danger
			? appConfig.style.colors.danger
			: warning
			? appConfig.style.colors.color4
			: type === 'primary'
			? appConfig.style.colors.text1
			: type === 'secondary'
			? appConfig.style.colors.color1
			: 'inherit',
	})
);

export default Title;
