import { ReactElement, useEffect, useState } from "react";
import { IC_CHEVRON_DOWN, IC_CHEVRON_UP } from "../../../../Assets";
import DrawerStyles from "./TableDrawer.style";
import AddButton from "../../../../Shared/Components/Button/AddButton";
import Table, { TableColumn } from "../../../../Shared/Components/Table/Table";
import Flex from "../../../../Shared/Components/Layout/Flex";
import Image from "../../../../Shared/Components/Image";
import { toJS } from "mobx";
import { Object } from "lodash";

interface TableDrawerProps {
    actionBtn?: { func: () => void; desc: string };
    tableColumns: TableColumn<any>[];
    tableData: any;
    isOpen?: boolean;
    header?: string | ReactElement;
    rowClicked?: (row?: any) => any;
    showTotal?: boolean;
    onRowIntersection?: any;
}

const TableDrawer = (props: TableDrawerProps) => {
    const [drawerOpen, setDrawerOpen] = useState(props.isOpen);

    const drawerHandler = () => {
        setDrawerOpen((prevValue) => !prevValue);
    };

    const rowClickHandler = (id?: any) => {
        if (!props.rowClicked) return
        console.log('row', toJS(id))

        props.rowClicked(id)
    }

    return (
        <div className={DrawerStyles}>
            <div className={"drawer-header"}>
                <div onClick={drawerHandler} className={"header-details"}>
                    {props.header && props.header}

                    <Image src={drawerOpen ? IC_CHEVRON_UP : IC_CHEVRON_DOWN} className="ml-4" alt="" />
                </div>

                {props.actionBtn && !drawerOpen && <AddButton qaid="" label={""} tooltip={props.actionBtn?.desc} onClick={props.actionBtn?.func} />}
            </div>

            {drawerOpen && (
                <div className={"drawer-table"}>
                    <Table
                        columns={props.tableColumns}
                        rows={props.tableData}
                        onRowClick={(row) => rowClickHandler(row)}
                        scrollAfterRows={8}
                        renderBottom={
                            props.actionBtn && (
                                <Flex align="center" justify="start">
                                    <AddButton onClick={props.actionBtn.func} qaid="" label={props.actionBtn.desc} />
                                </Flex>)
                        }
                        showTotal={props.showTotal}
                        onRowIntersection={props.onRowIntersection}
                    />
                </div>
            )}
        </div>
    );
};

export default TableDrawer;
