import { nulldefined } from "../../../Shared/Interfaces/global";
import { CompanyCustomData } from "./Company";

export interface IBeneficiary {
	companyId: number;
	employeeNumber: string;
	firstName: string;
	lastName: string;
	businessEmail: string;
	privateEmail: string;
	mobilePhone: string | undefined;
	mobilePrefix: string | undefined;
	site: number;
	employementStatus: number;
	notes: string;
	employmentStartDate: Date | string;
	employmentEndDate: Date | string;
	beneficiaryId: number;
	departmentEmp: number;
	subDepartmentEmp: number;
	level: number | string;
	role: number | string;
	id: string | string;
	sourceContactId: number;
	gender: GenderTypes;
	address: string;
}

export type BeneficiaryResponse = {
	companyId: number;
	employeeNumber: string;
	firstName: string;
	lastName: string;
	businessEmail: string | null;
	privateEmail: string | null;
	mobilePhone: string | null;
	mobilePrefix: string | null;
	site: string | null;
	employementStatus: number;
	notes: string | null;
	employmentStartDate: Date | null;
	employmentEndDate: Date | null;
	beneficiaryId: number;
	departmentEmp: number | null;
	subDepartmentEmp: number | null;
	level: number | string | null;
	role: number | string | null;
	id: number | string | null;
	gender: GenderTypes | null;
	sourceContactId: number;
}

export interface INewBeneficiary {
	companyId: number;
	employeeNumber: string;
	firstName: string;
	lastName: string;
	businessEmail: string | undefined;
	privateEmail: string | undefined;
	mobilePhone: string | undefined;
	site: number | undefined;
	employementStatus: number | undefined;
	notes: string | undefined;
	employmentStartDate: Date | undefined;
	employmentEndDate: Date | undefined;
	beneficiaryId: number | undefined;
	departmentEmp: number | undefined;
	subDepartmentEmp: number | undefined;
	level: string | undefined;
	role: string | undefined;
	id: string | undefined;
	gender: GenderTypes | undefined;
	sourceContactId: number;
	address?: string;
}

export interface BenTable {
	beneficiaryId: number;
	shares: number;
	outstanding: number;
	unvested: number;
	granted: number;
	exercisable: number;
	sold: number;
	canceled: number;
	employeeNumber: string;
	site: CompanyCustomData | nulldefined;
	department: CompanyCustomData | nulldefined;
	employementStatus: number;
	webAccess: string | nulldefined;
	email: string;
	fullName: {
		firstName: string;
		lastName: string;
	};
	sourceContactId: number;
}

export enum GenderTypes {
	Male,
	Female,
	Other,
}

export enum EmploymentStatus {
	Employee,
	ExEmployee,
	Other
}