import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const ReportsStyles = css({
    label: "Reports",
    display: "flex",
    padding: "7rem 8rem 4rem",
    gap: '5rem',

    "&__mainContainer": {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr 1fr',
    },

    "&__btnHeader": {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '3rem 8rem 0 0',
    },

    "&__pageBody": {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    "&__pageHeader": {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0 0 3rem 0',
    },

    '&__headerMain': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        width: '100%',
        paddingBottom: '1rem',
    },

    '&__headerMainInner': {
        display: 'flex',

        '> h3': {
            alignSelf: 'self-end',
            margin: '0px',
            padding: '0 0 0 3rem',
        },

        '> img': {
            height: '4rem',
        },
    },

    "&__tabs": {
        borderBottom: `1px solid ${appConfig.style.colors.border1}`,
        display: 'flex',
        width: '70%'
    },

    "&__wrapTable": {
        width: '50%',
        margin: '0 1rem',
        // border: `1px solid ${appConfig.style.colors.table}`,
        // borderRadius: '16px'
    },

    "&__card": {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1rem',
        borderRadius: '16px',
        boxShadow: `0px 4px 8px ${appConfig.style.colors.boxShadowColor1}`,
        border: `1px solid ${appConfig.style.colors.table}`,
        height: '98%',
    },

    "&__headerCard": {
        background: appConfig.style.colors.purple,
        borderTopRightRadius: '16px',
        borderTopLeftRadius: '16px',
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        padding: '0 1.6rem',
        height: '7rem',
        display: 'flex',

        "> span": {
            fontWeight: 500,
            margin: 'auto 0',
        }
    },

    "&__bodyCard": {
        height: '100%',
        borderTop: `1px solid ${appConfig.style.colors.table}`,
    },

    "&__actionsCard": {
        height: '45px',
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        display: 'flex',
        flexDirection: 'row-reverse',
        marginBottom: '20px',

        "> img": {
            margin: '0 5px',
            cursor: 'pointer'
        }
    },

    '&__selected': {
        color: appConfig.style.colors.color1,
        boxShadow: `4px 0px 15px #4D4D4D40`,
        transition: "box-shadow .3s",
    },

    "&__filterCard": {
        borderBottom: `1px solid ${appConfig.style.colors.table}`,
        padding: '1rem 2rem',
        display: 'flex',
        flexDirection: 'column',

        "> span": {
            color: '#979DB5',
            marginBottom: '5px'
        }
    },

});

export default ReportsStyles;
