import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_PAYMENT_CHECKOUT_ALTSHARE_LOGO } from '../../../../Assets';
import { ICheckout, PaymentMethod, PreviewInvoice } from '../../../../Models/App/PaymentModels';
import { Routes } from '../../../../Routes';
import Clickable from '../../../../Shared/Components/Clickable/Clickable';
import Image from '../../../../Shared/Components/Image';
import Flex from '../../../../Shared/Components/Layout/Flex';
import Spinner from '../../../../Shared/Components/Spinner/Spinner';
import { StripeElements } from '../../../../Shared/Components/StripeElements';
import { useAppendState } from '../../../../Shared/Hooks/useAppendState';
import { InputRefs, useFormValidation } from '../../../../Shared/Hooks/useFormValidation';
import useRecaptcha from '../../../../Shared/Hooks/useRecaptcha';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import useScreenSize from '../../../../Shared/Hooks/useScreenSize';
import { commonValidators } from '../../../../Shared/ObjectValidator';
import BillingInfo from '../BillingInfo';
import CardInformation from '../CardInformation';
import { CheckoutStyle } from '../Checkout.Style';
import { formatDecimal } from '../../../../Shared/Utilities';
import SelectedAddon from './SelectedAddon';

type Props = {
	addons: Addon[];
	onPurchaseHandler: (recaptchaToken: string) => Promise<void>;
};

type Addon = {
	name: string;
	price: number;
	id: number;
};

const AddonCheckout = ({ addons, onPurchaseHandler }: Props) => {
	const { paymentStore, auth, companyStore } = useRootStore();
	const { isMobile } = useScreenSize();
	const history = useHistory();
	const { execute } = useRecaptcha();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
	const [invoice, setInvoice] = useState<PreviewInvoice>();

	const [isBillingOnEdit, setIsBillingOnEdit] = useState<boolean>(true);

	const INITIAL_CHECKOUT_INFO: ICheckout = {
		email: auth.userEmail,
		companyName: companyStore.company.companyLegalName,
		isPaymentPrivacyPolicy: false,
	};

	const [checkoutInfo, setCheckoutInfo, onInputHandler] = useAppendState<ICheckout>(INITIAL_CHECKOUT_INFO);
	const inputRefs = useRef<InputRefs<ICheckout>>({} as InputRefs<ICheckout>);

	const { validateForm, formValidationState } = useFormValidation({
		form: checkoutInfo,
		schema: {
			email: [commonValidators.required(), commonValidators.emailValidator, commonValidators.minLength(2)],
			companyName: [commonValidators.required(), commonValidators.minLength(2)],
			isPaymentPrivacyPolicy: [commonValidators.required()],
		},
		refs: inputRefs.current,
	});

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const res = await paymentStore.getCustomerDetails();
			const invoiceRes = await paymentStore.getPreviewInvoice(paymentStore.selectPlanDetails.planId);
			setInvoice(invoiceRes.data?.data);
			setPaymentMethod(res?.paymentMhetod);
			setIsLoading(false);
		})();
	}, [paymentStore.selectPlanDetails.planId]);

	const onBillingEditHandler = (isOnEdit: boolean) => setIsBillingOnEdit(isOnEdit);

	const onPayHandler = async () => {
		const isValid = validateForm();
		if (!isValid) {
			return {
				isFormValid: false,
			};
		}

		return execute(async (recaptchaKey) => {
			return onPurchaseHandler(recaptchaKey);
		});
	};

	if (isLoading) {
		return <Spinner attachParent incorporated center />;
	}

	return (
		<section className={classNames(CheckoutStyle, { noOverflow: !isBillingOnEdit && isMobile })}>
			<span className="mobileTitle">Payment checkout</span>
			{isMobile && (
				<Clickable qaid="Checkout.Button.Back" flex={0} className="goBackButton" onClick={() => history.push(Routes.payment.pricing)}>
					Back
				</Clickable>
			)}
			<Flex className="selectedPlanHeader">
				<Image className="selectedPlanHeader__logo" src={IMG_PAYMENT_CHECKOUT_ALTSHARE_LOGO} alt="altshare logo" />
				<Flex className="rightSide">
					<span className="rightSide__title">{paymentStore.selectedPlan.name} Plan</span>
					<Flex className="priceContainer">
						<span className="priceContainer__price">${formatDecimal(paymentStore.selectedPlan.price)}</span>
					</Flex>
					<span className="rightSide__subTitle">(Up to {paymentStore.selectedPlan.maxNumOfStakeholders} stakeholders)</span>
					<span className="rightSide__subTitle">Excluding sales taxes</span>
				</Flex>
			</Flex>
			<Flex height="100%" flex={3} direction="column" gap="2.4rem">
				<span className="webTitle">Payment checkout</span>
				<BillingInfo
					flex={isMobile ? 0 : 3}
					inputRefs={inputRefs.current}
					checkoutInfo={checkoutInfo}
					formValidationState={formValidationState}
					setCheckoutInfo={setCheckoutInfo}
					onInputHandler={onInputHandler}
					onBillingEditHandler={onBillingEditHandler}
				/>
				<StripeElements>
					<CardInformation
						flex={isMobile ? 1 : 5}
						inputRefs={inputRefs.current}
						checkoutInfo={checkoutInfo}
						formValidationState={formValidationState}
						onInputHandler={onInputHandler}
						onPayHandler={onPayHandler}
						paymentMethod={paymentMethod}
					/>
				</StripeElements>
			</Flex>
			<Flex height="100%">
				<SelectedAddon addons={addons} />
			</Flex>
		</section>
	);
};

export default observer(AddonCheckout);
