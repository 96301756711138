import { css } from "@emotion/css";
import appConfig from "../../../../../config/config";

const BeneficiarySummaryStyles = css({
    label: "BeneficiarySummary",
    padding: '4.5rem',

    "&__pieWrap": {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4rem'
    },
});

export default BeneficiarySummaryStyles;
