import RadioButton from '../../../../../Shared/Components/RadioButton/RadioButton';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import useEsopModals from '../../../useEsopModals';
import Title from '../../../../../Shared/Components/Layout/Title';
import { css } from '@emotion/css';
import appConfig from '../../../../../config/config';
import Clickable from '../../../../../Shared/Components/Clickable/Clickable';
import useCapTableModal from '../../../../CapTable/Hooks/useCapTableModal';
import { IPivotalCompany } from '../../../../../Models/App';
import { OptionUpdateModeTypeEnum } from '../../../../../Models/API/enums';
import { observer } from 'mobx-react-lite';

const Style = css({
	label: 'SelectDataSource',
	'&__header': {
		paddingBottom: '1.6rem',
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
		marginBottom: '2.5rem',
	},
	'&__selection-container': {
		display: 'flex',
		flexDirection: 'column',
		gap: '4rem',
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
		paddingBottom: '2.5rem',
		marginBottom: '4rem',

		"> span": {
			color: appConfig.style.colors.text2,
		}
	},
	'&__radios': {
		display: 'flex',
		gap: '6rem',

		'> span': {
			fontWeight: 'bold'
		},
	},

	"&__changeCompany": {
		color: appConfig.style.colors.color1,
		textDecoration: 'underline'
	}
});

const SelectDataSource = () => {
	const { companyStore, trusteeStore, capTableStore, equityPlansStore, } = useRootStore();
	const { showChangeDataSource } = useEsopModals();
	const { onPivotalLoginHandler } = useCapTableModal();

	const equityTxt = 'Create & manage your data actively using Equity Plans product';
	const trusteeTxt = 'All the equity plans data is synchronized from altshare trustee service';

	const poolSummaryAndAutorization = async () => {
		trusteeStore.GetPoolSummary();
	};

	const equitySourceSelectorHandler = (fromPivotal: boolean) => {
		if (fromPivotal === companyStore.company.pivotalEquityPlans) return;

		showChangeDataSource(fromPivotal);

		if (fromPivotal) {
			poolSummaryAndAutorization();
		}
	};

	const onCompanySelect = async (reCaptchaToken: string, company: IPivotalCompany) => {
		sessionStorage.setItem('TrusteeCompanyName', company.companyName);
		trusteeStore.companyLegalName = company.companyName;
		const res = await capTableStore.setPivotalcompany(reCaptchaToken, company);

		if (res) {
			await companyStore.SetCapTableEquitySource(true);
			await capTableStore.updateProjectOptionMode(OptionUpdateModeTypeEnum.CompanyTrusteeData);
			// setCompany(company);
		}
		return res;
	};

	const changeCompany = () => {
		// showChangeDataSource(true);
		// trusteeStore.GetPoolSummary();

		//Login
		onPivotalLoginHandler(onCompanySelect);

	}

	return (
		<div className={Style}>
			<div className={`${Style}__header`}>
				<Title>Equity plans data source</Title>
			</div>

			<div className={`${Style}__selection-container`}>
				<div className={`${Style}__radios`}>
					<span>Choose import from:</span>
					<RadioButton
						name="dataFrom"
						qaid=""
						value={!companyStore.company.pivotalEquityPlans}
						checked={!companyStore.company.pivotalEquityPlans}
						label="Equity plans product"
						onChange={() => equitySourceSelectorHandler(false)}
					/>
					<RadioButton
						name="dataFrom"
						qaid=""
						value={companyStore.company.pivotalEquityPlans}
						checked={companyStore.company.pivotalEquityPlans}
						label="altshare trustee data"
						onChange={() => equitySourceSelectorHandler(true)}
					/>
					{companyStore.company.pivotalEquityPlans &&
						<Clickable
							className={`${Style}__changeCompany`}
							qaid='changeCompany'
							onClick={() => changeCompany()}>Change company</Clickable>
					}

				</div>
				<span>{companyStore.company.pivotalEquityPlans ? trusteeTxt : equityTxt}</span>
			</div>
		</div>
	);
};

export default observer(SelectDataSource);
