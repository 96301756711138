import { DataCollectionFieldTypeEnum } from '../../../Models/API/DataCollection/comment-response';
import { DataCollectionProjectPreview, DataCollectionStatusEnum } from '../../../Models/API/DataCollection/project-preview';
import useModal from '../../../Shared/Hooks/useModal';
import useRootStore from '../../../Shared/Hooks/useRootStore';
import ProjectFilePreview from '../Components/ProjectFilePreview';
import QuestionHistory from '../Components/QuestionHistory';

export const useDataCollectionModal = () => {
	const { showModal } = useModal();
	const { dataCollectionStore } = useRootStore();

	const onOpenGeneralChat = (valuationProjectId: string) => {
		dataCollectionStore.comments = undefined;
		dataCollectionStore.documents = undefined;
		dataCollectionStore.dataCollectionProjectId = null;
		dataCollectionStore.currentStatus = DataCollectionStatusEnum.DataCollection;
		dataCollectionStore.getDataCollection(valuationProjectId);
		showModal({
			body: <QuestionHistory fieldType={DataCollectionFieldTypeEnum.General} />,
			isFrameless: true,
			width: '70rem',
			height: '70dvh',
			allowScrolling: false,
		});
	};

	const onFilePreviewHandler = (project: DataCollectionProjectPreview, reportId?: string, onClose?: () => void) => {
		showModal({
			body: <ProjectFilePreview project={project} reportId={reportId} />,
			isFrameless: true,
			width: '100%',
			maxWidth: '120rem',
			qaid: 'DataCollection.Modal.ReportPreview',
			onModalClose: onClose,
		});
	};

	return {
		onFilePreviewHandler,
		onOpenGeneralChat,
	};
};
