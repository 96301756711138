import { css } from '@emotion/css';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import {
	IC_DATA_COLLECTION_GREY,
	IC_DATA_COLLECTION_PURPLE,
	IC_DOC_PDF,
	IC_DOC_PDF_GREY,
	IC_DOC_PDF_PURPLE,
	IC_INFO_PRIMARY,
	IC_INFO_PRIMARY_LIGHT,
} from '../../../../Assets';
import appConfig from '../../../../config/config';
import { DataCollectionFieldTypeEnum } from '../../../../Models/API/DataCollection/comment-response';
import { DataCollectionProjectPreview, DataCollectionStatusEnum, ReportType } from '../../../../Models/API/DataCollection/project-preview';
import { Routes } from '../../../../Routes';
import Image from '../../../../Shared/Components/Image';
import Flex from '../../../../Shared/Components/Layout/Flex';
import UnreadMessages from '../../../../Shared/Components/UnreadMessagesIcon';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';
import useModal from '../../../../Shared/Hooks/useModal';
import useRecaptcha from '../../../../Shared/Hooks/useRecaptcha';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import ScrollableList from '../../../../Shared/ScrollableList/index.style';
import { getDataCollectionReports, getDataCollectionStatus, getStatusTitle } from '../../helpers/utils';
import ProjectFilePreview from '../ProjectFilePreview';
import ProjectStatus from '../ProjectStatus';
import QuestionHistory from '../QuestionHistory';
import { onOpenURL } from '../../../../Shared/Utilities';
import { useDataCollectionModal } from '../../helpers/useDataCollectionModal';

const Style = css({
	label: 'ProjectsList',

	'&__project-name': {
		'&:hover': {
			transition: 'all .2s',
			cursor: 'pointer',
			color: appConfig.style.colors.color1,
			textDecoration: 'underline',
		},
	},
});

const ProjectsList = () => {
	const { dataCollectionStore, paymentStore } = useRootStore();
	const { showModal } = useModal();
	const history = useHistory();
	const { execute } = useRecaptcha();
	const { showErrorModal } = useGeneralModal();
	const { onFilePreviewHandler, onOpenGeneralChat } = useDataCollectionModal();

	const onNavToProject = (project: DataCollectionProjectPreview) => {
		dataCollectionStore.valuationProjectId = project.valuationProjectId;
		// dataCollectionStore.currentStatus = getDataCollectionStatus(project.status).currentStatus;
		history.push(`${Routes.dataCollection.form}/${project.valuationProjectId}/0`);
	};

	const areProjectsExists = !!dataCollectionStore.projects?.length;

	return (
		<ScrollableList.Container className={Style}>
			<ScrollableList.Row isHeader>
				<ScrollableList.Cell wide>Project Name</ScrollableList.Cell>
				<ScrollableList.Cell>Data collection</ScrollableList.Cell>
				<ScrollableList.Cell data-col-type="status">Project status</ScrollableList.Cell>
				<ScrollableList.Cell data-col-type="drafts">Drafts</ScrollableList.Cell>
				<ScrollableList.Cell data-col-type="final">Final Report</ScrollableList.Cell>
				<ScrollableList.Cell>Chat with analyst</ScrollableList.Cell>
			</ScrollableList.Row>
			<ScrollableList.Scroll>
				{areProjectsExists ? (
					dataCollectionStore.projects?.map((project) => {
						const reports = getDataCollectionReports(project.reports);
						const { currentStatus, statusList } = getDataCollectionStatus(project.status);
						const isPendingPayment = currentStatus === DataCollectionStatusEnum.PaymentApproval;
						return (
							<ScrollableList.Row key={project.valuationProjectId}>
								<ScrollableList.Cell wide onClick={() => onNavToProject(project)} className={classNames('pointer', `${Style}__project-name`)}>
									{project.projectName}
								</ScrollableList.Cell>
								<ScrollableList.Cell>
									<UnreadMessages
										count={project.unreadMessages}
										onClick={() => onNavToProject(project)}
										width="2.2rem"
										icons={[IC_DATA_COLLECTION_GREY, IC_DATA_COLLECTION_PURPLE]}
										badgePosition={{ x: -10, y: -5 }}
									/>
								</ScrollableList.Cell>
								<ScrollableList.Cell data-col-type="status">
									<span style={{ marginRight: '1rem' }}>{getStatusTitle(currentStatus)}</span>
									<Image
										pointer
										src={IC_INFO_PRIMARY_LIGHT}
										srcHover={IC_INFO_PRIMARY}
										tooltip={<ProjectStatus statusList={statusList} currentStatus={currentStatus} />}
										tooltipPlacement="top"
									/>
								</ScrollableList.Cell>
								<ScrollableList.Cell data-col-type="drafts">
									<Flex justify="start" gap="1.2rem">
										{isPendingPayment ? (
											<div className="img__new" onClick={() => onFilePreviewHandler(project)}>
												<Image src={IC_DOC_PDF_PURPLE} width="2.8rem" />
												<span>New</span>
											</div>
										) : (
											reports.drafts?.map((draft, idx) =>
												idx === reports.drafts!.length - 1 && !reports.final ? (
													<div className="img__new" key={draft.id}>
														<Image
															src={IC_DOC_PDF_GREY}
															width="2.8rem"
															tooltip="Preview"
															onClick={() => onOpenURL(draft.filePath)}
														/>
														<span>New</span>
													</div>
												) : (
													<Image
														src={IC_DOC_PDF_GREY}
														key={draft.id}
														width="2.8rem"
														tooltip="Preview"
														onClick={() => onOpenURL(draft.filePath)}
													/>
												)
											)
										)}
									</Flex>
								</ScrollableList.Cell>
								<ScrollableList.Cell data-col-type="final">
									{!!reports.final && (
										<Image
											src={IC_DOC_PDF}
											width="2.8rem"
											tooltip="Preview"
											onClick={() => reports.final && onOpenURL(reports.final.filePath)}
										/>
									)}
								</ScrollableList.Cell>
								<ScrollableList.Cell>
									<UnreadMessages
										count={project.unreadMessagesGeneral}
										onClick={() => onOpenGeneralChat(project.valuationProjectId)}
										width="3rem"
									/>
								</ScrollableList.Cell>
							</ScrollableList.Row>
						);
					})
				) : (
					<ScrollableList.Row>No data to display</ScrollableList.Row>
				)}
			</ScrollableList.Scroll>
		</ScrollableList.Container>
	);
};

export default observer(ProjectsList);
