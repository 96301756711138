import { IC_MESSAGE, IC_MESSAGE_UNREAD } from '../../../Assets';
import Image from '../Image';

type Props = {
	count: number;
	onClick?: () => void;
	width?: number | string;
	icons?: [string, string];
	badgePosition?: { x: number; y: number };
};

const UnreadMessages = ({ count, onClick, width, icons, badgePosition }: Props) => {
	return (
		<div style={{ position: 'relative' }} className="pointer" onClick={onClick}>
			<Image
				src={count === 0 ? icons?.[0] || IC_MESSAGE : icons?.[1] || IC_MESSAGE_UNREAD}
				width={width ?? '4rem'}
				tooltip={count ? `${count} unread message${count > 1 ? 's' : ''}` : 'No unread messages'}
			/>
			{!!count && (
				<div
					style={{
						background: '#F6818F',
						borderRadius: '50%',
						color: '#ffffff',
						fontSize: '1.1rem',
						fontWeight: 700,
						width: 17,
						height: 17,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						bottom: badgePosition?.y || -3,
						right: badgePosition?.x || -9,
					}}
				>
					{count}
				</div>
			)}
		</div>
	);
};

export default UnreadMessages;
