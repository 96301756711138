import { ServerResponseApi } from '../Models/API/All/server-response';
import { DataCollectionCommentPayload } from '../Models/API/DataCollection/comment-payload';
import { DataCollectionComment } from '../Models/API/DataCollection/comment-response';
import { DataCollectionPayload } from '../Models/API/DataCollection/data-collection-payload';
import { DataCollectionProjectResponse } from '../Models/API/DataCollection/data-collection-project-response';
import { DataCollectionProjectPreview } from '../Models/API/DataCollection/project-preview';
import { UploadDocumentPayload } from '../Models/API/DataCollection/upload-document-payload';
import { DataCollectionDocumentResponse } from '../Models/API/DataCollection/upload-document-response';
import { dataCollectionApi } from './Axios';

export class DataCollectionService {
	// Valuation Project

	createProject() {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionProjectPreview>>('/valuationProject', { projectName: `Test_${+new Date()}` });
	}

	getProjects() {
		return dataCollectionApi.get<ServerResponseApi<DataCollectionProjectPreview[]>>('/valuationProject/company');
	}

	takeProjectOwnership(valuationProjectId: string) {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionProjectPreview>>(`/valuationProject/owner/${valuationProjectId}`);
	}

	// Data Collection

	getDataCollection(valuationProjectId: string) {
		return dataCollectionApi.get<ServerResponseApi<DataCollectionProjectResponse>>(`/dataCollection/valuationProject/${valuationProjectId}`);
	}

	createDataCollection(data: Partial<DataCollectionPayload>) {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionProjectResponse>>('/dataCollection', data);
	}

	updateDataCollection(data: DataCollectionPayload) {
		return dataCollectionApi.put<ServerResponseApi<DataCollectionProjectResponse>>('/dataCollection', data);
	}

	submitDataCollection(dataCollectionId: string) {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionProjectResponse>>(`/dataCollection/submit?dataCollectionId=${dataCollectionId}`);
	}

	markAsRead(dataCollectionId: string, ids: string[]) {
		return dataCollectionApi.patch<ServerResponseApi<DataCollectionComment[]>>(`/comment/markAsRead/${dataCollectionId}`, { ids });
	}

	sendNotification(valuationProjectId: string) {
		return dataCollectionApi.post<boolean>('/comment/Notification', { valuationProjectId });
	}

	setIsraeliCustomer(valuationProjectId: string) {
		return dataCollectionApi.put<ServerResponseApi<DataCollectionProjectPreview>>(`/valuationProject/customr/${valuationProjectId}`);
	}

	approveProject(valuationProjectId: string) {
		return dataCollectionApi.put<ServerResponseApi<DataCollectionProjectPreview>>(`/valuationProject/approve/${valuationProjectId}`);
	}

	// Comment

	addComment(data: DataCollectionCommentPayload) {
		return dataCollectionApi.post<ServerResponseApi<DataCollectionComment>>('/comment', data);
	}

	// Document

	uploadDocument(data: UploadDocumentPayload, documentId: string) {
		return dataCollectionApi.put<ServerResponseApi<DataCollectionDocumentResponse>>(`/document/${documentId}`, data);
	}

	getDocument(dataCollectionId: string, documentId: string) {
		return dataCollectionApi.get<ServerResponseApi<DataCollectionDocumentResponse>>(`/document/${dataCollectionId}/${documentId}`);
	}
}
