import { BaseService } from './BaseService';

import { CompanyAdmin, IContact, IContactCreate } from '../Models/API/Contact/contact';
import { rootStore } from '..';
import { UserEditorPermissions, UsersPermissionsByCompany } from '../Models/API/UsersAndPermissions/users-permissions-by-company';
import { ShareInvitation, UserPermissions } from '../Models/API/UsersAndPermissions/user-permissions-info';
import { HigherLevelResponse, userApi } from './Axios';
import { contacts } from '../Shared/DummyData/contacts';

export default class ContactService extends BaseService {
	endPoints = {
		CreateContact: '/contact',
		UpdateContact: `/contact`,
		GetContacts: (params: string) => `/contact?${params}`,
		getUserPermissions: (companyId: number) => `/userPermission/listView`,
		InviteUser: 'user/invite',
		InviteUsers: 'user/invite-users',
		UserPermission: (userToUpdate: number) => `/UserPermission/${userToUpdate}`,
	};

	companyID: number;

	constructor(companyID: number) {
		super();
		this.companyID = companyID;
	}

	async createContact(data: IContactCreate) {
		return await this.safeExecuteAsync(async () => {
			const { contactDetails, ...rst } = data;
			return (
				await this.httpUserManagement.post<IContact>(this.endPoints.CreateContact, {
					...rst,
					email: data.email?.replace(/\s/g, ''),
					contactDetailsDto: contactDetails,
				})
			).data;
		});
	}
	async UpdateContact(contact: Partial<IContactCreate>, companyId: number) {
		const { contactDetails, ...rst } = contact;

		const payload = {
			...Object.fromEntries(Object.entries(rst).filter(([key, value]) => key !== 'contactId' && value !== null && value !== undefined)),
			email: contact.email?.replace(/\s/g, ''),
			companyId,
			contactDetailsDto: Object.fromEntries(
				Object.entries(contact.contactDetails || {}).filter(([key, value]) => key !== 'contactDetailsId' && value !== null && value !== undefined)
			),
		};

		return await this.safeExecuteAsync(async () => {
			return (await this.httpUserManagement.patch<IContact>(`${this.endPoints.UpdateContact}/${contact.contactId}`, payload)).data;
		});
	}

	async updateContacts(contacts: Partial<IContactCreate>[]) {
		const payload = contacts.map((contact) => {
			const { contactDetails, ...rst } = contact;
			return {
				...Object.fromEntries(Object.entries(rst).filter(([key, value]) => value !== null && value !== undefined)),
				// contactDetailsDto: Object.fromEntries(
				//     Object.entries(contact.contactDetails || {}).filter(
				//         ([key, value]) => key !== "contactDetailsId" && key !== "contactId" && value !== null && value !== undefined
				//     )
				// ),
			};
		});

		return await this.safeExecuteAsync(async () => {
			return (
				await this.httpUserManagement.patch<Pick<IContact, 'email' | 'contactId' | 'firstName' | 'lastName' | 'isLegalEntity'>[]>(
					this.endPoints.UpdateContact,
					payload
				)
			).data;
		});
	}

	async getCompanyContacts() {
		const params = new URLSearchParams();
		params.append('companyId', rootStore.companyStore.companyId.toString());
		params.append('isContactDetails', 'true');

		return await this.safeExecuteAsync(async () => {
			return (await this.httpUserManagement.get<IContact[]>(this.endPoints.GetContacts(params.toString()))).data;
		});
	}

	getContacts(data: Partial<IContact>, isLegalEntity: boolean) {
		const handleQueryString = (string?: string) => {
			return btoa(string ?? '');
		};
		return this.safeExecuteAsync(async () => {
			let params: string = 'encoded=true';

			if (isLegalEntity) {
				params += `&isLegalEntity=true&companyName=${handleQueryString(data.companyName)}`;
			} else if (data.email) {
				params += `&email=${handleQueryString(data.email)}`;
			} else {
				params += `&firstName=${handleQueryString(data.firstName)}&lastName=${handleQueryString(data.lastName)}&isContactDetails=true`;
			}

			return (await this.httpUserManagement.get<IContact[]>(this.endPoints.GetContacts(params))).data;
		});
	}

	async getContact(contactId: number) {
		return await this.safeExecuteAsync(async () => {
			const params = new URLSearchParams();
			params.append('companyId', rootStore.companyStore.companyId.toString());
			params.append('contactId', contactId.toString());
			params.append('isContactDetails', 'true');

			return (await this.httpUserManagement.get<IContact[]>(this.endPoints.GetContacts(params.toString()))).data;
		});
	}

	async getUserPermissions(contactIds?: number[]) {
		return await this.safeExecuteAsync(async () => {
			const data = (
				await this.httpUserManagement.post<Array<UsersPermissionsByCompany>>(this.endPoints.getUserPermissions(rootStore.companyStore.companyId), {
					contactIds,
				})
			).data;

			return data;
		});
	}

	getCompanyEditor() {
		return userApi.get<UserEditorPermissions[]>('/contact/by-permissions');
	}

	async inviteContact(contactId: number, userPermissions: Partial<UserPermissions>) {
		return await this.safeExecuteAsync(async () => {
			const data = (
				await this.httpUserManagement.post<UserPermissions>(this.endPoints.InviteUser, {
					contactId: contactId,
					companyId: rootStore.companyStore.companyId,
					permission: {
						...userPermissions,
					},
				})
			).data;

			return data;
		});
	}

	async inviteContacts(payload: ShareInvitation[]) {
		return await this.safeExecuteAsync(async () => {
			const data = (
				await this.httpUserManagement.post<UserPermissions>(
					this.endPoints.InviteUsers,
					payload.map((c) => ({ ...c, companyId: rootStore.companyStore.companyId }))
				)
			).data;

			return data;
		});
	}

	async updateUserPermissions(userId: number, userPermissions: Partial<UserPermissions>) {
		return await this.safeExecuteAsync(async () => {
			const data = (
				await this.httpUserManagement.patch<UserPermissions>(this.endPoints.UserPermission(userId), {
					companyId: rootStore.companyStore.companyId,
					...userPermissions,
				})
			).data;

			return data;
		});
	}

	getCompanyAdmin() {
		return userApi.get<CompanyAdmin>('/user/companyOwner');
	}
}
