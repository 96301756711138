import { css } from '@emotion/css';
import { Collapse } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { forwardRef, ReactElement, useImperativeHandle, useMemo, useState } from 'react';
import appConfig from '../../../../config/config';
import { DataCollectionFieldTypeEnum } from '../../../../Models/API/DataCollection/comment-response';
import { FourONinePermission } from '../../../../Models/API/UsersAndPermissions/permissions-enum';
import Button from '../../../../Shared/Components/Button/Button';
import { TextAreaInput } from '../../../../Shared/Components/Input/Input.Style';
import Tooltip from '../../../../Shared/Components/Tooltip';
import UnreadMessages from '../../../../Shared/Components/UnreadMessagesIcon';
import useModal from '../../../../Shared/Hooks/useModal';
import { ForwardedRefType } from '../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { DataCollectionPermissionsEnum, hasProjectPermissions } from '../../helpers/utils';
import QuestionsHistory from '../QuestionHistory';

type Props = {
	title: string | ReactElement;
	fieldType: DataCollectionFieldTypeEnum;
	info?: string;
	renderActions: JSX.Element;
	headeredComment?: boolean;
	// isDisabled?: boolean;
};

const Style = css({
	label: 'DataCollectionQuestion',
	display: 'flex',
	flexDirection: 'column',
	padding: '2rem 0',
	'&:not(:last-child)': {
		borderBottom: `1px solid ${appConfig.style.colors.table}`,
	},
	'&__question-lbl': {
		display: 'flex',
		alignItems: 'center',
		gap: '1.2rem',
		marginBottom: '2rem',
		justifyContent: 'space-between',
		'> span': {
			display: 'flex',
			alignItems: 'center',
			gap: '1.2rem',
		},
	},
	'.actions': {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	'&__comments': {
		marginRight: '2rem',
	},
});

const Question = forwardRef<ForwardedRefType<string | undefined>, Props>(
	({ title, info, fieldType, renderActions, headeredComment: headeredActions }, forwardedRef) => {
		const [isCommentOpen, setIsCommentOpen] = useState<boolean>(false);
		const [comment, setComment] = useState<string>();
		const { showModal } = useModal();
		const { dataCollectionStore } = useRootStore();
		const hasCommentPermission =
			hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.SEND_COMMENT) || dataCollectionStore.isOwner;

		const comments = fieldType ? dataCollectionStore.commentsByQuestion[fieldType] : undefined;

		const unreadComments = useMemo(() => {
			const isClient = [FourONinePermission.Admin, FourONinePermission.Editor, FourONinePermission.FullAccess].includes(dataCollectionStore.currentRole);
			return (comments || []).reduce((acc, comment) => {
				if (comment.isValuationTeam && isClient && !comment.isRead) {
					return acc + 1;
				}
				if (!comment.isValuationTeam && !isClient && !comment.isRead) {
					return acc + 1;
				}
				return acc;
			}, 0);
		}, [comments]);

		const onToggleShow = () => {
			setIsCommentOpen((prev) => !prev);
			// setComment(undefined);
		};

		useImperativeHandle(forwardedRef, () => ({
			onValidate: () => true,
			getData: () => comment,
		}));

		const AddCommentElement =
			comments !== undefined &&
			(comments.length ? (
				<div
					className={`${Style}__comments`}
					onClick={() =>
						showModal({
							body: <QuestionsHistory fieldType={fieldType} />,
							isFrameless: true,
							width: '70rem',
							height: '70dvh',
							allowScrolling: false,
						})
					}
				>
					<UnreadMessages count={unreadComments} />
				</div>
			) : hasCommentPermission ? (
				<Button qaid="" label="Add comment" inverse cancel onClick={onToggleShow} />
			) : (
				<></>
			));

		const TextAreaInputElement = (
			<Collapse in={isCommentOpen} unmountOnExit className={headeredActions ? 'mb-2r' : 'mt-2r'}>
				<TextAreaInput
					data-qaid=""
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					style={{ resize: 'none', height: '100%', width: '100%' }}
					rows={4}
					maxLength={500}
					placeholder="Type text here..."
					name=""
					disabled={!hasCommentPermission}
				/>
			</Collapse>
		);

		return (
			<div className={Style}>
				<div className={`${Style}__question-lbl`}>
					<span>
						{title}
						{!!info && <Tooltip style={{ marginLeft: '1.2rem' }} title={info} />}
					</span>
					{!!headeredActions && <>{AddCommentElement}</>}
				</div>
				{!!headeredActions && TextAreaInputElement}

				<div className="actions">
					{renderActions}
					{!headeredActions && AddCommentElement}
				</div>
				{!headeredActions && TextAreaInputElement}
			</div>
		);
	}
);

export default observer(Question);
