import { useHistory } from 'react-router-dom'
import Button from '../../../../Shared/Components/Button/Button'
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table'
import { IC_DOCUMENT_PDF, IC_EXCEL_GREEN, IC_FORMS } from '../../../../Assets'
import useRootStore from '../../../../Shared/Hooks/useRootStore'

import AdditionalStyles from '../../Dashboard/AdditionalViews/AdditionalViews.module.css'
import { onOpenURL } from '../../../../Shared/Utilities';


interface FormTable {
    fileName: string
    fileType: 'xlsx' | 'pdf'
    fileUrl: string
}

interface FormRowProps {
    row: FormTable
}

const TrusteeForms = () => {

    const history = useHistory()
    const { trusteeStore } = useRootStore()

    const FormRow = (props: FormRowProps) => {

        const iconClickHandler = () => {
            console.log('Form row CLICKED !')
            // trusteeStore.GetSingleTrusteeGrantDocument(props.click)


        }

        const formIcon = props.row.fileType === 'pdf' ? IC_DOCUMENT_PDF : IC_EXCEL_GREEN

        const rowStyle: React.CSSProperties = {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            // alignItems: 'center',

            padding: '0',
            // marginTop: '8px'
        }

        return (
            <div style={rowStyle}>
                <p style={{ paddingTop: '1rem' }}>{props.row.fileName}</p>
                <img src={formIcon} width={24} onClick={iconClickHandler} />
            </div>
        )
    }

    const formsList: FormTable[] = [
        { fileName: 'W8 BEN', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/general/common/W8Ben.pdf' },
        { fileName: 'W9', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/general/common/W9.pdf' },
        { fileName: 'Update participant details', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/general/common/Update%20Participant%20Details.pdf' },
        { fileName: 'CRS - entity', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/general/he/CRS%20-%20%D7%98%D7%95%D7%A4%D7%A1%20%D7%99%D7%A9%D7%95%D7%AA.pdf' },
        { fileName: 'CRS - person', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/general/he/%D7%98%D7%95%D7%A4%D7%A1%20CRS%20-%20%D7%99%D7%97%D7%99%D7%93.pdf' },
        { fileName: 'Default currency BA', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/general/he/Default%20Currency%20BA.pdf' },

        { fileName: 'Exercise and release', fileType: 'xlsx', fileUrl: 'https://employees.portal.altshare.com/static/realization/common/Exercise%20and%20Release.pdf' },
        { fileName: 'Exercise options (3I / 102 Non-trustee)', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/realization/he/%D7%98%D7%95%D7%A4%D7%A1%20%D7%9E%D7%99%D7%9E%D7%95%D7%A9%20%D7%90%D7%95%D7%A4%D7%A6%D7%99%D7%95%D7%AA%20(3%D7%98%20%D7%90%D7%95%20102%20%D7%9C%D7%9C%D7%90%20%D7%A0%D7%90%D7%9E%D7%9F).pdf' },
        { fileName: 'Exercise options (102 Trustee)', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/realization/he/%D7%98%D7%95%D7%A4%D7%A1%20%D7%9E%D7%99%D7%9E%D7%95%D7%A9%20%D7%90%D7%95%D7%A4%D7%A6%D7%99%D7%95%D7%AA%20(102%20%D7%A2%D7%9D%20%D7%A0%D7%90%D7%9E%D7%9F).pdf' },
        { fileName: 'Same day sale options exercise', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/realization/he/%D7%98%D7%95%D7%A4%D7%A1%20%D7%9E%D7%99%D7%9E%D7%95%D7%A9%20%D7%90%D7%95%D7%A4%D7%A6%D7%99%D7%95%D7%AA%20%D7%9E%D7%95%D7%AA%D7%A0%D7%94%20%D7%91%D7%9E%D7%9B%D7%A8%20(Same%20Day%20Sale).pdf' },
        { fileName: 'Sale to cover', fileType: 'pdf', fileUrl: 'https://employees.portal.altshare.com/static/realization/he/%D7%98%D7%95%D7%A4%D7%A1%20%D7%9E%D7%99%D7%9E%D7%95%D7%A9%20%D7%90%D7%95%D7%A4%D7%A6%D7%99%D7%95%D7%AA%20%D7%9E%D7%9B%D7%99%D7%A8%D7%94%20%D7%9C%D7%A6%D7%95%D7%A8%D7%9A%20%D7%9B%D7%99%D7%A1%D7%95%D7%99%20%D7%9E%D7%97%D7%99%D7%A8%20%D7%9E%D7%99%D7%9E%D7%95%D7%A9%20(Sell%20To%20Cover).pdf' },

    ]


    const formsTable: TableColumn<FormTable>[] = [
        { label: 'Form name', name: 'fileName', render: (obj) => <FormRow row={obj} /> }
    ]

    return (
		<div style={AdditionalStyles} className={AdditionalStyles.mainContainer}>
			<div className={AdditionalStyles.btnHeader}>
				<Button qaid="trustee.orders.back" label="Back" inverse cancel onClick={() => history.push('/trustee')} />
			</div>
			<div className={AdditionalStyles.pageBody}>
				<div className={AdditionalStyles.pageHeader}>
					<div className={AdditionalStyles.headerMain}>
						<img src={IC_FORMS} />
						<h3>Forms</h3>
					</div>
				</div>

				<Table columns={formsTable} rows={formsList} onRowClick={(row) => onOpenURL(row.fileUrl)} />
			</div>
		</div>
	);
}

export default TrusteeForms