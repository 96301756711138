export type DataCollectionProjectPreview = {
	valuatorContactId: number;
	companyId: number;
	projectName: string;
	unreadMessages: number;
	unreadMessagesGeneral: number;
	fairMarketValue: number;
	reports: DataCollectionProjectReport[];
	paymentStatus: PaymentStatus;
	valuationProjectId: string;
	status: DataCollectionStatus[];
};

export enum ReportType {
	Draft = 1,
	Final = 2,
}

export enum PaymentStatus {
	PaymentNotInitialized = 1,
	PaymentInitialized = 2,
	PaymentCompleted = 3,
}

export type DataCollectionProjectReport = {
	id: string;
	valuationProjectId: string;
	uploadDate: string;
	reportType: ReportType;
	filePath: string;
};

export type DataCollectionStatus = {
	valuationProjectId: string;
	date: string;
	status: DataCollectionStatusEnum;
	id: string;
};

export enum DataCollectionStatusEnum {
	DataCollection = 1,
	DataReview = 2,
	ValuationProcess = 3,
	PaymentApproval = 4,
	DraftSent = 5,
	FinalReport = 6,
}
