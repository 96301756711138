import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IC_QUESTION_MARK_DARK, IC_WARNING_INFO_CIRCLE, IC_WARNING_INFO_CIRCLE_FILL } from '../../../../../Assets';
import { CapTableData } from '../../../../../Models/API/Waterfall/IForm';
import { ProductTypeEnum } from '../../../../../Models/API/enums';
import appConfig from '../../../../../config/config';
import useModal from '../../../../Hooks/useModal';
import { formatDate, formatDecimal, formatNumber, getUniqueId, isArray } from '../../../../Utilities';
import Button from '../../../Button/Button';
import Image from '../../../Image';
import Table, { extractCellValue, TableColumn } from '../../../Table/Table';
import { Cell, Row } from '../../../Table/Table.Style';

const Style = css({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	gap: '1rem',
	// marginBottom: "1.6rem",
	'&__title': {
		fontSize: '2.4rem',
	},
	'&__tooltip': {
		width: '3.6rem',
		height: '3.6rem',
	},
	'&__errors-container': {
		position: 'relative',
		'.tooltip-modal': {
			visibility: 'hidden',
			opacity: 0,
			// visibility: "visible",
			// opacity: 1,
			zIndex: 10,
			background: '#F4EADF',
			border: `1px solid ${appConfig.style.colors.color4}`,
			position: 'absolute',
			left: 'calc(100% + 0.8rem)',
			transform: 'translate(0, calc(-50% - 2rem))',
			padding: '0 1.4rem',
			borderRadius: 8,
			transition: 'all .3s',
			maxWidth: '53.4rem',
			width: 'max-content',
			boxShadow: '3px 3px 16px 0px #ABAFC699',
			'> div': {
				display: 'flex',
				gap: '0.8rem',
				padding: '3rem 0',
				letterSpacing: '2%',
				'&:not(:first-child)': {
					borderTop: `1px solid ${appConfig.style.colors.color4}`,
				},
			},
		},
		'&:hover': {
			'.tooltip-modal': {
				visibility: 'visible',
				opacity: 1,
			},
		},
	},
});

type Props = {
	onChangeSource: VoidFunction;
	singleCapTableData?: CapTableData;
	baseProductType?: ProductTypeEnum;
	isViewMode?: boolean;
};

const WaterfallTable = ({ onChangeSource, singleCapTableData, baseProductType, isViewMode }: Props) => {
	const { showModal, clearModals } = useModal();
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const { t } = useTranslation();

	useEffect(() => {
		clearModals();

		if (singleCapTableData?.isProjectDeleted) {
			showModal({
				type: 'error',
				title: 'Cap table deleted',
				body: 'The imported cap table has been deleted, please click on the Change button to re-import a new cap table',
				cancelButton: {
					label: 'Close',
				},
			});
		}
	}, [singleCapTableData]);

	useEffect(() => {
		if (!singleCapTableData) return;
		const { captableImportDate, captableLastUpdate } = singleCapTableData;
		setIsChanged(+new Date(captableImportDate) <= +new Date(captableLastUpdate));
	}, [singleCapTableData]);

	if (!singleCapTableData) return null;

	const { captableSummary, shareClass }: any = singleCapTableData;
	const rows = [...shareClass, captableSummary];

	// ShareClassInfo:
	const tableColumns: TableColumn<any>[] = [
		{
			name: 'seniorityLevel',
			label: 'waterfall.seniorityLevel',
		},
		{
			name: 'name',
			label: 'waterfall.shareClassOrHolder',
		},
		{
			name: 'shareOutstanding',
			label: 'waterfall.numberOfSharesOutstanding',

			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: 'warrants',
			label: 'waterfall.numberOfWarrants',

			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: 'issuePrice',
			label: 'waterfall.issuePricePerShare',

			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: 'conversionPriceShare',
			label: 'waterfall.conversionPrice',

			format(val) {
				return !val ? '-' : formatNumber(val);
			},
		},
		{
			name: 'shareFullyDiluted',
			label: 'waterfall.numberOfSharesAsFullyDiluted',

			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: 'investmentAmount',
			label: 'waterfall.investmentDateMil',

			format(val) {
				return formatDecimal(val);
			},
		},
	];

	// ShareHolderInfo:
	const shareHolderColumns: TableColumn<any>[] = [
		{
			name: '',
			label: 'Seniority Level (Top Down)',
		},
		{
			name: 'shareName',
			label: 'Share Class/Holder',
		},
		{
			name: 'shareOutstanding',
			label: 'Number of Shares Outstanding',
			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: 'warrants',
			label: 'Number of Warrants',
			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: 'IssuePricePerShare',
			label: 'Issue Price per Share ($)',
		},
		{
			name: 'conversionPriceShare',
			label: 'Conversion Value ($)',
			format(val) {
				return !val ? '-' : formatNumber(val);
			},
		},
		{
			name: 'shareFullyDiluted',
			label: 'Number of Shares as Fully Diluted',
			format(val) {
				return formatNumber(val);
			},
		},
		{
			name: 'investmentAmount',
			label: 'Investment Amount ($M)',
			format(val) {
				return formatDecimal(val);
			},
		},
	];

	const getWaterfallTableHeadingText = () => {
		const captableImportDate = singleCapTableData?.captableImportDate;
		const projectName = singleCapTableData?.captableName;
		const formattedDate = formatDate(captableImportDate);
		const isProductTypeFunding = baseProductType === 20;
		const textKeyBase = isProductTypeFunding ? 'waterfall.importedFromFundingProduct' : 'waterfall.importedFromCapTableProduct';
		// const textKeyChanged = isProductTypeFunding ? "waterfall.capTableChangedFunding" : "waterfall.capTableChanged";

		// const textKey = isChanged ? textKeyChanged : textKeyBase;
		const textKey = textKeyBase;
		const result = `${t(textKey, {
			date: formattedDate,
		})} ${projectName ? `- ${projectName}` : ''}`;
		return result;
	};

	return (
		<Table
			fullscreen
			showTotal
			columns={tableColumns}
			rows={rows}
			color="primary"
			scrollAfterRows={10}
			border={{ top: true }}
			rowClassName={(row) => (row.seniorityLevel ? 'bold' : '')}
			customHeaderRender={
				<div className={Style}>
					<div className={`${Style}__title`}>{getWaterfallTableHeadingText()}</div>
					{isChanged && (
						<div className={`${Style}__errors-container`}>
							<Image
								width="4rem"
								pointer
								className={`${Style}__tooltip`}
								src={IC_WARNING_INFO_CIRCLE}
								srcHover={IC_WARNING_INFO_CIRCLE_FILL}
								alt=""
							/>
							<div className="tooltip-modal">
								<div>
									<Image src={IC_QUESTION_MARK_DARK} />
									<div>The cap table has been changed since the last import. Please click on the Change button and re-import</div>
								</div>
							</div>
						</div>
					)}
				</div>
			}
			customActionsRender={
				<>
					<Button
						color="primary"
						onClick={onChangeSource}
						qaid="WaterfallTable.Button.ChangeBase"
						style={{ alignSelf: 'unset' }}
						disabled={isViewMode}
					>
						{t('waterfall.change')}
					</Button>
				</>
			}
			onAfterEachRowRender={(obj: any) => {
				return (
					isArray(obj.shareHolders) &&
					obj.shareHolders?.map((shareholder: any, index: number) => (
						<Row key={getUniqueId()} className="Table__Row">
							{shareHolderColumns.map((column: TableColumn<any>, cIdx: number) => (
								<Cell
									className={`${column.className} Table__Cell`}
									key={getUniqueId()}
									align={column.align}
									defaultCellSize={column.defaultCellSize}
									border={false}
								>
									{extractCellValue(column, shareholder)}
								</Cell>
							))}
						</Row>
					))
				);
			}}
		/>
	);
};

export default observer(WaterfallTable);
