import { ReactElement, cloneElement, useState } from "react";

export type MultiStepFormItem = {
    title?: string;
    caption?: string;
    element: ReactElement;
    isRequired?: boolean;
};

export type ForwardedRef = {
	onValidate: () => boolean | Promise<boolean>;
	onSave?: () => boolean | Promise<boolean>;
};

export type ForwardedRefType<T> = ForwardedRef & {
    getData: () => T;
};

export interface MultiStepElementProps {
    goTo?: (step: number) => void;
    onNextStep?: () => void;
    onPrevStep?: () => void;
}

const useMultiStepForm = (steps: Array<MultiStepFormItem>) => {
    const [currentStepIdx, setCurrentStepIdx] = useState<number>(0);

    const onNextStep = () => {
        setCurrentStepIdx((i) => {
            if (i >= steps.length - 1) return i;
            return i + 1;
        });
    };

    const onPrevStep = () => {
        setCurrentStepIdx((i) => {
            if (i <= 0) return i;
            return i - 1;
        });
    };

    const goTo = (index: number) => {
        if (index < 0 || index > steps.length - 1) return;
        setCurrentStepIdx(index);
    };

    return {
        steps,
        currentStepIdx,
        step: cloneElement(steps[currentStepIdx].element, { goTo, onNextStep, onPrevStep }),
        goTo,
        onNextStep,
        onPrevStep,
        isFirstStep: currentStepIdx === 0,
        isLastStep: currentStepIdx === steps.length - 1,
    };
};

export default useMultiStepForm;
