import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DataCollectionFieldTypeEnum } from '../../../../../../Models/API/DataCollection/comment-response';
import { DataCollectionInvestmentTypeEnum } from '../../../../../../Models/API/DataCollection/data-collection-investment-type-enum';
import DatePickerInput from '../../../../../../Shared/Components/Input/DatePickerInput';
import { TextAreaInput } from '../../../../../../Shared/Components/Input/Input.Style';
import NumberInput from '../../../../../../Shared/Components/Input/NumberInput';
import RadioButton from '../../../../../../Shared/Components/RadioButton/RadioButton';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import useGeneralModal from '../../../../../../Shared/Hooks/useGeneralModal';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { isNumber } from '../../../../../../Shared/Utilities';
import { WaterfallStep } from '../../../../../Waterfall/Components/AddEditWaterfall/index.style';
import { DataCollectionPermissionsEnum, hasProjectPermissions } from '../../../../helpers/utils';
import { RefsType } from '../../../../types';
import Question from '../../../Question';
import DataCollectionGeneralInfoStyle from './index.style';

const GeneralInfo = forwardRef<ForwardedRef, unknown>((_, forwardedRef) => {
	const { dataCollectionStore } = useRootStore();
	const data = dataCollectionStore.dataCollection;
	const isDisabled =
		!hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.UPDATE_PROJECT) || dataCollectionStore.isProjectDisabled;
	const questionsRef = useRef<RefsType>({} as RefsType);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { showErrorModal } = useGeneralModal();

	useImperativeHandle(forwardedRef, () => ({
		onValidate: async () => {
			if (!data.valuationDate) {
				showErrorModal('Please select a valuation date first.', 'Data is missing');
				return false;
			}

			const promises = Object.keys(questionsRef.current || []).map((key) => {
				const typedKey = key as unknown as keyof RefsType;

				const comment = questionsRef.current?.[typedKey].getData();
				if (!comment || !dataCollectionStore.valuationProjectId) return;

				return dataCollectionStore.addComment({
					fieldType: +typedKey,
					commentDescription: comment,
					valuationProjectId: dataCollectionStore.valuationProjectId,
				});
			});

			await Promise.all([...promises, isDisabled ? undefined : dataCollectionStore.updateDataCollection()]);

			return true;
		},
	}));

	useEffect(() => {
		if (data?.investmentType === DataCollectionInvestmentTypeEnum.Equity) {
			dataCollectionStore.setDataCollection('nextRoundValuation', null);
		}
	}, [data?.investmentType]);

	useEffect(() => {
		dataCollectionStore.valuationProjectId && dataCollectionStore.getDataCollection(dataCollectionStore.valuationProjectId);
	}, [dataCollectionStore.valuationProjectId]);

	if (!data || isLoading)
		return (
			<WaterfallStep>
				<Spinner center incorporated />
			</WaterfallStep>
		);

	return (
		<WaterfallStep small className={DataCollectionGeneralInfoStyle}>
			<span className={`${DataCollectionGeneralInfoStyle}__title`}>General Info</span>
			<Question
				title="For which date does your company need the 409A valuation?"
				info="the fill later"
				renderActions={
					<DatePickerInput
						containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
						qaid=""
						placeholder="Select..."
						value={data?.valuationDate ?? undefined}
						name="valuationDate"
						onChange={(value) => value && dataCollectionStore.setDataCollection('valuationDate', value, true)}
						disabled={isDisabled}
						maxDate={new Date()}
					/>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.ValuationDate] = el;
					}
				}}
				fieldType={DataCollectionFieldTypeEnum.ValuationDate}
			/>

			<Question
				title="Has your company raised money six months prior the valuation date?"
				info="the fill later"
				renderActions={
					<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
						<RadioButton
							qaid=""
							name="investmentExist"
							value={true}
							checked={data.investmentExist === true}
							label="Yes"
							onChange={(value) => dataCollectionStore.setDataCollection('investmentExist', value)}
							disabled={isDisabled}
						/>
						<RadioButton
							qaid=""
							name="investmentExist"
							value={false}
							checked={data.investmentExist === false}
							label="No"
							onChange={(value) => dataCollectionStore.setDataCollection('investmentExist', value)}
							disabled={isDisabled}
						/>
					</div>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.InvestmentExist] = el;
					}
				}}
				fieldType={DataCollectionFieldTypeEnum.InvestmentExist}
			/>
			{data.investmentExist ? (
				<>
					<Question
						title="Was the money raised through?"
						info="the fill later"
						renderActions={
							<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
								<RadioButton
									qaid=""
									name="investmentType"
									value={DataCollectionInvestmentTypeEnum.Equity}
									checked={data.investmentType === DataCollectionInvestmentTypeEnum.Equity}
									label="Issue shares"
									onChange={(value) => dataCollectionStore.setDataCollection('investmentType', value)}
									disabled={isDisabled}
								/>
								<RadioButton
									qaid=""
									name="investmentType"
									value={DataCollectionInvestmentTypeEnum.SAFE}
									checked={data.investmentType === DataCollectionInvestmentTypeEnum.SAFE}
									label="SAFE"
									onChange={(value) => dataCollectionStore.setDataCollection('investmentType', value)}
									disabled={isDisabled}
								/>
								<RadioButton
									qaid=""
									name="investmentType"
									value={DataCollectionInvestmentTypeEnum.ConvertibleLoan}
									checked={data.investmentType === DataCollectionInvestmentTypeEnum.ConvertibleLoan}
									label="Convertible loan"
									onChange={(value) => dataCollectionStore.setDataCollection('investmentType', value)}
									disabled={isDisabled}
								/>
							</div>
						}
						ref={(el) => {
							if (questionsRef.current && el) {
								questionsRef.current[DataCollectionFieldTypeEnum.InvestmentType] = el;
							}
						}}
						fieldType={DataCollectionFieldTypeEnum.InvestmentType}
					/>
					{isNumber(data.investmentType) &&
						[DataCollectionInvestmentTypeEnum.SAFE, DataCollectionInvestmentTypeEnum.ConvertibleLoan].includes(data.investmentType) && (
							<>
								<Question
									title="What is your estimation regarding the next round company valuation?"
									info="the fill later"
									renderActions={
										<NumberInput
											qaid=""
											name="nextRoundValuation"
											value={data.nextRoundValuation ?? undefined}
											onChange={(value) => value !== undefined && dataCollectionStore.setDataCollection('nextRoundValuation', value)}
											placeholder="Type in $..."
											number="float"
											disabled={isDisabled}
										/>
									}
									ref={(el) => {
										if (questionsRef.current && el) {
											questionsRef.current[DataCollectionFieldTypeEnum.NextRoundValuation] = el;
										}
									}}
									fieldType={DataCollectionFieldTypeEnum.NextRoundValuation}
								/>
							</>
						)}
				</>
			) : data.investmentExist === false ? (
				<>
					<Question
						title="Has your company generated revenues in the last 12 months?"
						info="the fill later"
						renderActions={
							<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
								<RadioButton
									qaid=""
									name="companyProfitable"
									value={true}
									checked={data.companyProfitable === true}
									label="Yes"
									onChange={(value) => dataCollectionStore.setDataCollection('companyProfitable', value)}
									disabled={isDisabled}
								/>
								<RadioButton
									qaid=""
									name="companyProfitable"
									value={false}
									checked={data.companyProfitable === false}
									label="No"
									onChange={(value) => dataCollectionStore.setDataCollection('companyProfitable', value)}
									disabled={isDisabled}
								/>
							</div>
						}
						ref={(el) => {
							if (questionsRef.current && el) {
								questionsRef.current[DataCollectionFieldTypeEnum.CompanyProfitable] = el;
							}
						}}
						fieldType={DataCollectionFieldTypeEnum.CompanyProfitable}
					/>
					{data.companyProfitable === true && (
						<Question
							title="Has your company generated operational profit in the last 12 months or projected to generate a profit in the next 1-2 years?"
							info="the fill later"
							renderActions={
								<div className={`${DataCollectionGeneralInfoStyle}__radio-container`}>
									<RadioButton
										qaid=""
										name="companyProfitableInFuture"
										checked={data.companyProfitableInFuture === true}
										value={true}
										label="Yes"
										onChange={(value) => dataCollectionStore.setDataCollection('companyProfitableInFuture', value)}
										disabled={isDisabled}
									/>
									<RadioButton
										qaid=""
										name="companyProfitableInFuture"
										checked={data.companyProfitableInFuture === false}
										value={false}
										label="No"
										onChange={(value) => dataCollectionStore.setDataCollection('companyProfitableInFuture', value)}
										disabled={isDisabled}
									/>
								</div>
							}
							ref={(el) => {
								if (questionsRef.current && el) {
									questionsRef.current[DataCollectionFieldTypeEnum.CompanyProfitable] = el;
								}
							}}
							fieldType={DataCollectionFieldTypeEnum.None}
						/>
					)}
				</>
			) : (
				<></>
			)}
			<Question
				title="What’s the company estimation regarding the time in years for an exit (M&A, IPO) or other sell of the company assets?"
				info="the fill later"
				renderActions={
					<NumberInput
						qaid=""
						value={data.timeToLiquidity ?? undefined}
						name="timeToLiquidity"
						onChange={(value) => value !== undefined && dataCollectionStore.setDataCollection('timeToLiquidity', value)}
						containerClassName={`${DataCollectionGeneralInfoStyle}__input-container`}
						number="float"
						placeholder="Type in years..."
						disabled={isDisabled}
					/>
				}
				ref={(el) => {
					if (questionsRef.current && el) {
						questionsRef.current[DataCollectionFieldTypeEnum.TimeToLiquidity] = el;
					}
				}}
				fieldType={DataCollectionFieldTypeEnum.None}
			/>
			<Question
				title="Add a link to your company About Us page or write a short description (Maximum 500 characters)"
				info="the fill later"
				renderActions={
					<TextAreaInput
						data-qaid=""
						value={data.aboutUs ?? undefined}
						onChange={(e) => dataCollectionStore.setDataCollection('aboutUs', e.target.value)}
						style={{ resize: 'none', height: '100%', width: '100%' }}
						rows={4}
						maxLength={500}
						placeholder="Type text here..."
						name=""
						disabled={isDisabled}
					/>
				}
				fieldType={DataCollectionFieldTypeEnum.None}
			/>
		</WaterfallStep>
	);
});

export default observer(GeneralInfo);
