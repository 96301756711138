import { observer } from "mobx-react-lite";
import useRootStore from "../../../../Shared/Hooks/useRootStore";
import Tab from "../../../../Shared/Components/Tab";
import { css } from "@emotion/css";
import appConfig from "../../../../config/config";

const Style = css({
    label: "DocumentTabs",
    display: "flex",
    borderBottom: `1px solid ${appConfig.style.colors.border1}`,
    width: "100%",
});

const Tabs = () => {
    const {
        documentsStore: { selectedTab, setSelectedTab },
    } = useRootStore();

    return (
		<div className={Style}>
			<Tab label="Signatures" onClick={() => setSelectedTab('signatures')} isActive={selectedTab === 'signatures'} qaid="Documents.Tabs.Signatures" />
			<Tab label="Templates" onClick={() => setSelectedTab('templates')} isActive={selectedTab === 'templates'} qaid="Documents.Tabs.Templates" />
		</div>
	);
};

export default observer(Tabs);
