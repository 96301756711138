import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import { IPaymenPlan } from "../../../Models/App/PaymentModels";
import { Routes } from "../../../Routes";
import { rootStore } from "../../..";
import useModal from "../../../Shared/Hooks/useModal";
import { PaymentPlanEnum, UserStatus } from "../../../Models/API/enums";
import Flex from "../../../Shared/Components/Layout/Flex";
import Checkout from "../Checkout";
import useScreenSize from "../../../Shared/Hooks/useScreenSize";
import { isNullOrUndefined, onOpenURL } from '../../../Shared/Utilities';
import { PricingStyle } from "./Pricing.Style";
import Plan from "./Plan";
import CarouselCard from "./Carousel/Carousel";
import Spinner from "../../../Shared/Components/Spinner/Spinner";
import { getPlanData } from "./utils/utils";

const Pricing = () => {
	const { showModal } = useModal();
	const history = useHistory();
	const { isMobile } = useScreenSize();
	const { paymentStore, auth, companyStore } = useRootStore();

	const [isLoading, setIsLoading] = useState(false);
	const [plansList, setPlansList] = useState<IPaymenPlan[]>([]);
	const [currentPlan, setCurrentPlan] = useState<IPaymenPlan | null>(null);
	const [disabledPlans, setDisabledPlans] = useState<PaymentPlanEnum[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const res = await paymentStore.getPlans();

			if (res) {
				setPlansList(res.usd);
			}

			if (auth.isUserActive) {
				const res = await paymentStore.getCurrentPlan();
				if (res.data) {
					setCurrentPlan(res.data);
					onChangeDisabledPlans(res.data.code);
				}
			}

			setIsLoading(false);
		};

		fetchData();
	}, [rootStore.companyStore.companyId]);

	const onChangeDisabledPlans = (code: PaymentPlanEnum) => {
		switch (code) {
			case PaymentPlanEnum.Starter:
				!companyStore.isInCompanyCreationProcess && setDisabledPlans([PaymentPlanEnum.Starter]);

				break;
			case PaymentPlanEnum.Growth:
				setDisabledPlans([PaymentPlanEnum.Starter, PaymentPlanEnum.Growth]);

				break;

			case PaymentPlanEnum.Scale:
				setDisabledPlans([PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale]);

				break;

			case PaymentPlanEnum.Enterprise:
				setDisabledPlans([PaymentPlanEnum.Starter, PaymentPlanEnum.Growth, PaymentPlanEnum.Scale, PaymentPlanEnum.Enterprise]);

				break;

			default:
				break;
		}
	};

	const onShowUpgradeModal = (plan: IPaymenPlan, recaptchaKey: string) => {
		// showModal({
		// 	type: 'confirm',
		// 	title: 'Are you sure?',
		// 	body: (
		// 		<>
		// 			<span>
		// 				Your account will be charged
		// 				<br />
		// 				according to the plan&nbsp;
		// 				<span style={{ fontWeight: '700' }}>{getPlanData(plan.code).name}</span>
		// 			</span>
		// 		</>
		// 	),
		// 	showClose: false,
		// 	confirmButton: {
		// 		label: "I'm sure!",
		// 	},
		// 	onConfirm: async () => {
		// 		await paymentStore.CustomerUpgradePlan(plan.planId, recaptchaKey);
		// 		history.push(Routes.account.companyProfile);
		// 	},
		// });
	};

	const onSelectPlan = async (plan: IPaymenPlan, recaptchaKey: string) => {
		// if (auth.isUserActive && currentPlan?.code !== PaymentPlanEnum.Starter) {
		// 	onShowUpgradeModal(plan, recaptchaKey);

		// 	return;
		// }

		switch (plan.code) {
			case PaymentPlanEnum.Starter:
				setIsLoading(true);
				const billingInfo = await paymentStore.getBillingInfo();

				if (isNullOrUndefined(billingInfo)) {
					setIsLoading(false);
					return;
				}

				if (auth.isUserActive && companyStore.isInCompanyCreationProcess) {
					companyStore.setCompanyCreationProcessStatus(false);
					history.push(Routes.dashboard.index);
					break;
				}

				const subscribeRes = await paymentStore.customerSubscribePlan(billingInfo, plan.planId, recaptchaKey, true);

				if (!subscribeRes) {
					setIsLoading(false);

					return;
				}

				const res = await auth.updateUserStatus(UserStatus.Active);

				if (!res.isSuccess) {
					setIsLoading(false);

					return;
				}

				companyStore.setCompanyCreationProcessStatus(false);
				auth.handleUserData(UserStatus.Active);
				isMobile ? history.push(`${Routes.dashboard.index}?mobile-first-reg`) : history.push(Routes.dashboard.index);

				break;
			case PaymentPlanEnum.Growth:
			case PaymentPlanEnum.Enterprise:
			case PaymentPlanEnum.Scale:
				paymentStore.setSelectedPlan(plan);

				if (isMobile) {
					history.push(Routes.payment.paymentPay);
					return;
				}

				showModal({
					isFrameless: true,
					body: <Checkout />,
					width: '100%',
					height: '100%',
				});

				break;

			case PaymentPlanEnum.Custom:
				onOpenURL(Routes.contactUs.external);

				break;
		}
	};

	const cards = plansList?.map((plan, i) => ({
		key: i,
		content: (
			<Plan
				key={i}
				plan={plan}
				focus={
					currentPlan && !companyStore.inCompanyCreationProcess
						? plan.code === currentPlan?.code
						: plan.code === PaymentPlanEnum.Growth
				}
				disabled={disabledPlans.includes(plan.code)}
				carouselView={true}
				onSelectPlan={onSelectPlan}
				maxNumOfStakeholders={plan.maxNumOfStakeholders}
			/>
		),
	}));

	if (isLoading || plansList.length === 0) {
		return (
			<Spinner
				center
				incorporated
			/>
		);
	}

	return (
		<section className={PricingStyle}>
			<Flex
				justify="start"
				direction="column"
			>
				{/* {isMobile ?
					<div className="carouselContainer">
						<CarouselCard
							cards={cards}
							offset={2}
							offsetRadius={0}
						/>
					</div> : */}
				<Flex
					justify="start"
					direction="column"
				>
					<div className="plansContainer">
						{plansList.map((plan, i) => (
							<Plan
								key={i}
								plan={plan}
								focus={
									currentPlan && !companyStore.inCompanyCreationProcess
										? plan.code === currentPlan?.code
										: plan.code === PaymentPlanEnum.Growth
								}
								disabled={disabledPlans.includes(plan.code)}
								onSelectPlan={onSelectPlan}
								maxNumOfStakeholders={plan.maxNumOfStakeholders}
							/>
						)
						)}
					</div>
				</Flex>
				{/* } */}
			</Flex>
		</section >
	);
};

export default observer(Pricing);
