import { observer } from "mobx-react-lite";
import { BenTable } from "../../../../../Models/App/EquityPlans/Beneficiaries";
import DoughnutChart from "../../../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart";
import { defaultColors } from "../../../../../Shared/Components/WidgetDashboard/shared/components/DoughnutChart/DoughnutChart.style";
import useRootStore from "../../../../../Shared/Hooks/useRootStore";
import { formatDecimal } from "../../../../../Shared/Utilities";
import ChartLegend from "../../../../../Shared/Components/WidgetDashboard/shared/components/ChartLegend/ChartLegend";
import BeneficiarySummaryStyles from "./BeneficiarySummary.style";

interface Props {
    data: BenTable;
}

const BeneficiarySummary = (props: Props) => {
    const { equityPlansStore } = useRootStore();
    const minPercentage = 0.01;

    const labels = ["Unvested", "Exercisable", "Saleable", "Sold", "Canceled"];

    const total = props.data.unvested + props.data.exercisable + props.data.shares + props.data.sold + props.data.canceled;

    const figures = () => {
        if (!props.data) {
            return [];
        }
        else {
            let values = [props.data.unvested, props.data.exercisable, props.data.shares, props.data.sold, props.data.canceled];

            const percentages = values.map(value => {
                const percentage = (value / total) * 100;
                return value === 0 ? percentage : Math.max(parseFloat(percentage.toFixed(10)), minPercentage);
            });

            const sumPercentages = percentages.reduce((sum, value) => sum + +(formatDecimal(value)), 0);
            const remainder = 100 - sumPercentages;

            if (remainder > 0) {
                percentages[1] += remainder;
            }

            return percentages.map((item) => formatDecimal(item));
        }
    }

    const tooltipFigures = [props.data.unvested, props.data.exercisable, props.data.shares, props.data.sold, props.data.canceled];

    return (
        <div className={BeneficiarySummaryStyles}>
            <h3 className={`${BeneficiarySummaryStyles}__title`}>Total equity awards</h3>
            <div className={`${BeneficiarySummaryStyles}__pieWrap`}>
                <DoughnutChart
                    ap={{
                        labels: labels,
                        figures: figures(),
                        tooltipFigures: tooltipFigures,
                        esopTooltip: true,
                        colors: defaultColors,
                        centerTotal: total
                    }}
                />
                <ChartLegend
                    className="ChartLegend"
                    labels={labels}
                    figures={figures()}
                    colors={defaultColors}
                />
            </div>
        </div>
    );
};

export default observer(BeneficiarySummary);