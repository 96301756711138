import { Collapse } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import InputLabel from '../../../../../../config/style/components/InputLabel/InputLabel';
import {
	Industry,
	PeerSearchParams,
	VolatilityAndMultiple,
	Volatility as VolatilityType,
	VolatilityTableData,
	WaterfallPeer,
} from '../../../../../../Models/API/Waterfall/IForm';
import { IndustryEnum } from '../../../../../../Models/App/Valuation/industry-enum';
import { SectorEnum } from '../../../../../../Models/App/Valuation/sector-enum';
import Button from '../../../../../../Shared/Components/Button/Button';
import ExpandToggle from '../../../../../../Shared/Components/ExpandToggle';
import Hr from '../../../../../../Shared/Components/HorizontalRule/Hr';
import { TextAreaInput } from '../../../../../../Shared/Components/Input/Input.Style';
import Label from '../../../../../../Shared/Components/Layout/Label';
import OverflowText from '../../../../../../Shared/Components/OverflowText';
import Select from '../../../../../../Shared/Components/Select/Select';
import Tooltip from '../../../../../../Shared/Components/Tooltip';
import { ForwardedRef } from '../../../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../../../Shared/Hooks/useRootStore';
import { formatDecimal, isNullOrUndefined, isNumber } from '../../../../../../Shared/Utilities';
import { WaterfallStep } from '../../../../../Waterfall/Components/AddEditWaterfall/index.style';
import WaterfallSubtitle from '../../../../../Waterfall/Components/WaterfallSubtitle/WaterfallSubtitle';
// import { sectorOptions } from '../../../../utils';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { NumberOption } from '../../../../../../Models/API/All/NumberOption';
import { VolatilityChartPointType } from '../../../../../../Models/API/Valuation/summary';
import CheckBox from '../../../../../../Shared/Components/CheckBox/CheckBox';
import CircularSwitchToggle from '../../../../../../Shared/Components/CircularSwitchToggle/CircularSwitchToggle';
import NumberInput from '../../../../../../Shared/Components/Input/NumberInput';
import Spinner from '../../../../../../Shared/Components/Spinner/Spinner';
import { ScatterChartProps } from '../../../../../Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types';
import { industriesLabels, sectorLabels } from '../../../../utils';
import useVolatilityTooltip from '../../../../utils/useVolatilityTooltip';
import ValuationCardScatterChart from '../Summary/ValuationSummary/ValuationCardScatterChart';
import VolatilityStyle from './index.style';
import PeerSearch from './PeerSearch';
import MultiplesTable from './Tables/MultiplesTable';
import VolatilityTable from './Tables/VolatilityTable';
import { InputValidationRef } from '../../../../../../Shared/Hooks/useFormValidation';
import { ExportVolatilityProps, onExportVolatility } from '../../../../utils/exportSummary';

const Volatility = forwardRef<ForwardedRef, any>((_, forwardedRef) => {
	const { valuationStore, appState } = useRootStore();
	const [isListOpen, setIsListOpen] = useState<boolean>(false);
	const [selectedSector, setSelectedSector] = useState<SectorEnum>();
	const [selectedIndustry, setSelectedIndustry] = useState<-1 | IndustryEnum>(-1);
	const [keywords, setKeywords] = useState<string>();
	const [industryPeers, setIndustryPeers] = useState<WaterfallPeer[]>();
	const [sectorPeers, setSectorPeers] = useState<WaterfallPeer[]>();
	const [keywordsPeers, setKeywordsPeers] = useState<WaterfallPeer[]>();
	const [isFiltering, setIsFiltering] = useState<boolean>(false);
	const [isByMultiple, setIsByMultiple] = useState<boolean>(false);
	const [volatilityTableData, setVolatilityTableData] = useState<VolatilityTableData>();
	const [volatilityAndMultipleData, setVolatilityAndMultipleData] = useState<VolatilityAndMultiple>();
	const industryRef = useRef<Industry[]>();
	const ref = useRef<HTMLDivElement>(null);
	const [isFilterDisabled, setIsFilterDisabled] = useState<boolean>(false);
	const { getTooltip, onTooltipEnter, onTooltipLeave, tooltipData, clearData } = useVolatilityTooltip();
	const volatilityInputRef = useRef<InputValidationRef>();

	useImperativeHandle(forwardedRef, () => ({
		onValidate: async () => {
			const isVolatlityValid =
				isNumber(volatilityTableData?.chosenVolatility) && volatilityTableData.chosenVolatility <= 200 && volatilityTableData.chosenVolatility > 0;
			if (!isVolatlityValid) {
				volatilityInputRef.current?.showError();
				return false;
			}

			const res = await onUpdateHandler(volatilityTableData.chosenVolatility);
			return !!res?.isSuccess;
		},
	}));

	const onUpdateHandler = (chosenVolatility?: number | null) => {
		if (isNullOrUndefined(chosenVolatility)) return;
		return valuationStore.updateVolatilityProject({
			chosenVolatility,
			peersKeywordsSearch: keywords ?? null,
			selectedIndustry: selectedIndustry === -1 ? null : selectedIndustry,
			selectedSector: selectedSector ?? null,
		});
	};

	const isExpandedListDisabled =
		(selectedIndustry === -1 && !keywordsPeers?.length) || (isNumber(selectedIndustry) && !isNumber(selectedSector) && !keywordsPeers?.length);

	const listItems = useMemo(() => {
		if (isExpandedListDisabled) {
			setIsListOpen(false);
			return [];
		}
		if (isNumber(selectedIndustry) && isNumber(selectedSector)) {
			return keywordsPeers?.length ? [...keywordsPeers, ...(sectorPeers || [])] : sectorPeers;
		}

		if (isNumber(selectedIndustry) || (selectedIndustry === -1 && keywordsPeers)) {
			return keywordsPeers;
		}
	}, [sectorPeers, industryPeers, keywordsPeers]);

	useEffect(() => {
		clearData();
		(async () => {
			setIsFiltering(true);
			const [availablePeers, _, sectorAndInsdustryRes, volatilityAndMultipleRes] = await Promise.all([
				fetchAvailablePeers(),
				getVolatilityProject(),
				valuationStore.getSectorAndIndstryList(),
				valuationStore.getVolatilityAndMultiple(),
			]);
			setIsFiltering(false);
			industryRef.current = sectorAndInsdustryRes.data?.industries;
			setIndustryPeers(availablePeers);
			setVolatilityAndMultipleData(volatilityAndMultipleRes.data);
		})();

		return () => {
			setIndustryPeers(undefined);
			setSectorPeers(undefined);
			setIsFiltering(false);
		};
	}, []);

	const chartData: ScatterChartProps = useMemo(() => {
		const exitValuePointData = volatilityTableData?.commonShareFairValues?.find((value) => value.type === VolatilityChartPointType.ChosenVolatility);
		return {
			data: volatilityTableData?.commonShareFairValues?.map((value) => ({ x: value.volatility * 100, y: value.commonShareFairValue })) ?? [],
			yAxisLabel: 'Common share fair value ($)',
			xAxisLabel: 'Volatility (%)',
			exitValuePoint: exitValuePointData ? { x: exitValuePointData.volatility * 100, y: exitValuePointData.commonShareFairValue } : undefined,
		};
	}, [volatilityTableData?.commonShareFairValues]);

	const volatilityRange = useMemo(() => {
		const data = volatilityAndMultipleData?.volatilities?.filter((peer) => peer.companyName === 'median' || peer.companyName === 'average');
		const numbers = data?.map((v) => v.data.map((d) => d.volatility)).flat(2) || [0, 2];

		return {
			min: Math.min(...numbers) * 100,
			max: Math.max(...numbers) * 100,
		};
	}, [volatilityAndMultipleData?.volatilities]);

	const getVolatilityProject = async () => {
		const res = await valuationStore.getVolatilityProject();
		setVolatilityTableData(res.data);
		setSelectedIndustry(res.data?.selectedIndustry ?? -1);
		setSelectedSector(res.data?.selectedSector ?? undefined);
		setKeywords(res.data?.peersKeywordsSearch ?? undefined);
	};

	const getSectorPeers = async (sectorEnum: SectorEnum | undefined) => {
		const peers = await fetchAvailablePeers({ sectorEnum });
		setSectorPeers(peers);
	};

	const getIndustryPeers = async (industryEnum: IndustryEnum | undefined) => {
		const peers = await fetchAvailablePeers({ industryEnum });
		setIndustryPeers(peers);
	};

	const getKeywordPeers = async (keywords: string | undefined, industryEnum: IndustryEnum | undefined) => {
		if (!keywords) return setKeywordsPeers(undefined);
		const peers = await fetchAvailablePeers({ industryEnum, keywords });
		setKeywordsPeers(peers);
	};

	const fetchAvailablePeers = async (params?: PeerSearchParams) => {
		const res = await valuationStore.getAvailablePeers(params);
		return sortList(res.data);
	};

	const sortList = (data?: WaterfallPeer[]) => {
		return (
			data?.sort((a, b) => {
				return a.companyName > b.companyName ? 1 : -1;
			}) || []
		);
	};

	const setVolatilities = (volatilities: VolatilityType[] | null | undefined) => {
		if (volatilities === undefined) return;
		setVolatilityAndMultipleData((prev) => ({ ...(prev || { multiples: null, peers: null }), volatilities }));
	};

	const togglePeer = async (identifier: string, isExists?: boolean) => {
		if (volatilityTableData === undefined) return;
		appState.isLoading = true;
		const res = await (isExists ? valuationStore.deletePeer(identifier) : valuationStore.addPeer(identifier));
		appState.isLoading = false;

		setVolatilityAndMultipleData(res.data);

		ref.current?.scrollIntoView({ behavior: 'smooth' });
		return res;
	};

	const industryOptions: NumberOption[] = [
		{
			label: 'All industries',
			value: -1,
		},
		...(industryRef.current?.map((industry) => ({
			value: IndustryEnum[industry.industry],
			label: industriesLabels[industry.industry],
		})) || []),
	];

	const sectorOptions: NumberOption[] =
		industryRef.current
			?.find((industry) => IndustryEnum[industry.industry] === selectedIndustry)
			?.sectors?.map((sector) => ({
				value: SectorEnum[sector],
				label: sectorLabels[sector],
			})) || [];

	const onExportHandler = () => {
		if (valuationStore.generalForm.valuationDate === undefined || valuationStore.generalForm.projectName === undefined) return;

		const data = (volatilityAndMultipleData?.volatilities || []).reduce(
			(acc, volatility) => {
				acc.volatility?.volatilities?.push({
					...volatility,
					data: volatility.data.filter((d) => d.expectedTermsInYears === valuationStore.generalForm.timeToExit),
				});
				const isTotal = volatility.companyName === 'median' || volatility.companyName === 'average';
				const waterfallPeer = industryPeers?.find((p) => p.companyName === volatility.companyName);
				if (waterfallPeer && !isTotal) {
					acc.volatility?.peers?.push({
						companyName: waterfallPeer.companyName,
						ticker: waterfallPeer.ticker,
						description: waterfallPeer.description,
					});
				}
				return acc;
			},
			{
				volatility: { volatilities: [], peers: [] },
				peersMultiple: { multiples: volatilityAndMultipleData?.multiples, multiplesData: volatilityAndMultipleData?.multiples_data },
				projectName: valuationStore.generalForm.projectName,
				valuationDate: valuationStore.generalForm.valuationDate,
			} as ExportVolatilityProps
		);
		onExportVolatility(data);
	};

	if (!valuationStore.generalForm.valuationDate) {
		return (
			<WaterfallStep small className={VolatilityStyle}>
				<Spinner incorporated center />
			</WaterfallStep>
		);
	}

	return (
		<WaterfallStep small className={VolatilityStyle}>
			<WaterfallSubtitle>Volatility Measurement</WaterfallSubtitle>
			<div className={`${VolatilityStyle}__container`}>
				{getSectionTitle("Define Evaluated Company's Sector & Industry")}

				<div className={`${VolatilityStyle}__filters`}>
					<div className="selectable">
						<Select
							qaid="Volatility.Select.Industry"
							value={selectedIndustry}
							options={industryOptions}
							onChange={(val) => {
								if (val !== -1 && !isNumber(val)) return;
								if (val === -1) {
									setIsListOpen(false);
									setSelectedSector(undefined);
								}
								setSelectedIndustry(val);
								getIndustryPeers(val === -1 ? undefined : val);
							}}
							label="Industry"
							defaultValue="All Industries"
							isLoading={isFiltering}
							required
							disabled={valuationStore.isViewMode}
						/>
						<Select
							qaid="Volatility.Select.Sector"
							value={selectedSector}
							options={sectorOptions}
							onChange={(val) => {
								if (!isNumber(val)) return;
								setSelectedSector(val);
								getSectorPeers(val);
							}}
							label="Sector"
							isLoading={isFiltering}
							disabled={selectedIndustry === -1 || valuationStore.isViewMode}
						/>
					</div>
					<div className="keywords">
						<Label>Search by keywords (optional):</Label>
						<TextAreaInput
							style={{ resize: 'none', width: '100%' }}
							rows={2}
							maxLength={200}
							name="businessDescription"
							value={keywords}
							onChange={(e: any) => {
								setKeywords(e.target.value);
								setIsFilterDisabled(false);
							}}
							data-qaid="Volatility.Input.Keywords"
							placeholder="Type peers' description with keywords. Separate terms by comma (,)"
							disabled={isFiltering || valuationStore.isViewMode}
						/>
						<Button
							qaid="Volatility.Button.Filter"
							label="Filter"
							position="end"
							isLoading={isFiltering}
							onClick={() => {
								getKeywordPeers(keywords, selectedIndustry === -1 ? undefined : selectedIndustry);
								setIsFilterDisabled(true);
							}}
							disabled={isFiltering || isFilterDisabled || valuationStore.isViewMode}
						/>
					</div>
				</div>
			</div>
			<div className={`${VolatilityStyle}__container`}>
				{getSectionTitle('Adding Peers')}

				<div className={`${VolatilityStyle}__peers`}>
					<div className="inputs">
						<PeerSearch
							peers={industryPeers?.filter((p) => !volatilityAndMultipleData?.volatilities?.some((v) => v.identifier === p.identifier))}
							addPeer={togglePeer}
							disabled={valuationStore.isViewMode}
						/>
						<div
							className={classNames('btn-show-list', { disabled: isExpandedListDisabled })}
							data-qaid="Volatility.Button.ToggleList"
							onClick={() => !isExpandedListDisabled && setIsListOpen((prev) => !prev)}
						>
							<OverflowText>Show sector & keywords full list selection</OverflowText>
							<ExpandToggle isOpen={isListOpen} />
						</div>
						<div className="btn-ask-altshare" data-qaid="Volatility.Button.AskAltshare">
							Didn't find your peer company? Ask altshare
						</div>
					</div>
					<Collapse unmountOnExit in={isListOpen}>
						<div className="list-card" ref={ref}>
							<div className="list-title">Select peers (up to 12):</div>
							<div className="list-container">
								{sortList(listItems).map((peer) => (
									<Tooltip
										onMouseEnter={() => {
											onTooltipLeave(peer.stockId);
											onTooltipEnter(peer.stockId);
										}}
										title={getTooltip(peer, tooltipData)}
										key={peer.stockId}
										placement="right"
									>
										<div>
											<CheckBox
												qaid={`Volatility.CheckBox.Peer-${peer.identifier}`}
												label={peer.companyName}
												isChecked={volatilityAndMultipleData?.volatilities?.some((v) => v.identifier === peer.identifier)}
												type="secondary"
												onClick={async () => {
													const isExists = volatilityAndMultipleData?.volatilities?.some((v) => v.identifier === peer.identifier);
													togglePeer(peer.identifier, isExists);
												}}
											/>
										</div>
									</Tooltip>
								))}
							</div>
						</div>
					</Collapse>
				</div>
			</div>
			<div className={`${VolatilityStyle}__container`}>
				<CircularSwitchToggle
					value={isByMultiple}
					actions={[
						{ value: false, label: 'Peers volatility' },
						{ value: true, label: 'Peers multiples' },
					]}
					type="secondary"
					onChange={(val) => setIsByMultiple(val)}
					className="type-selection"
					size="sm"
				/>

				{isByMultiple ? (
					<MultiplesTable
						timeToLiquiity={valuationStore.generalForm.timeToExit}
						multiples={volatilityAndMultipleData?.multiples || []}
						peers={industryPeers}
						onExport={onExportHandler}
					/>
				) : (
					<VolatilityTable
						data={volatilityTableData}
						timeToLiquiity={valuationStore.generalForm.timeToExit}
						volatilities={volatilityAndMultipleData?.volatilities || []}
						setData={async (newData, volatilities) => {
							const res = await valuationStore.calculateAddedPeers(newData);
							valuationStore.getVolatilityProject().then((res) => {
								setVolatilityTableData(res.data);
							});
							setVolatilities(res.data?.volatilities);
						}}
						onDelete={
							valuationStore.isViewMode
								? undefined
								: async (identifier) => {
										togglePeer(identifier, true);
										// const res = await valuationStore.deletePeer(identifier);
										// setVolatilities(res.data?.volatilities);
								  }
						}
						peers={industryPeers}
						onExport={onExportHandler}
					/>
				)}
			</div>

			<div className={`${VolatilityStyle}__container`}>
				{getSectionTitle('Define volatility')}
				<div className="flex gap-3" style={{ margin: '2rem 0 1rem 0' }}>
					<NumberInput
						qaid="Volatility.Input.Volatility"
						label="Enter volatility (%)"
						value={volatilityTableData?.chosenVolatility ?? undefined}
						disabled={valuationStore.isViewMode}
						onChange={(value) =>
							setVolatilityTableData((prev) => {
								if (!prev || !isNumber(value)) return prev;
								return {
									...prev,
									chosenVolatility: value,
								};
							})
						}
						error={
							isNumber(volatilityTableData?.chosenVolatility) &&
							volatilityTableData?.chosenVolatility <= 200 &&
							volatilityTableData.chosenVolatility > 0
								? undefined
								: 'Volatility must be greater than 0 and less than 200'
						}
						containerStyle={{ maxWidth: '30rem' }} // Daniel to re-design
						onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
							const { value } = e.target;
							if (!value || !isNumber(+value) || +value === 0 || +value > 200) return;
							onUpdateHandler(+value);
						}}
						ref={(el: InputValidationRef) => (volatilityInputRef.current = el)}
						number="float"
					/>
					{!!volatilityAndMultipleData?.volatilities?.length && (
						<div style={{ alignContent: 'flex-end' }}>
							Volatility Range <span className="bold">{formatDecimal(volatilityRange.min)}%</span> -
							<span className="bold"> {formatDecimal(volatilityRange.max)}%</span>
						</div>
					)}
				</div>
				{!!volatilityTableData?.commonShareFairValues && <ValuationCardScatterChart {...chartData} graphTreshhold={{ x: 0.02, y: 1 }} />}
			</div>
		</WaterfallStep>
	);
});

const getSectionTitle = (text: string) => {
	return (
		<>
			<InputLabel
				ap={{
					htmlFor: '',
					text,
				}}
			/>
			<Hr
				ap={{
					spacing: 'xs',
				}}
			/>
		</>
	);
};

export default observer(Volatility);
