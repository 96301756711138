import * as ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { toast as Toast } from 'react-toastify';
import { Currencies, DataRoomPermissionsLVL, EmployeesWebsitePermission } from '../Models/API/enums';
import { ExtraAssetTypes, currencies } from './Config';

import CryptoJS from 'crypto-js';

import { format, isValid, parse } from 'date-fns';
import { de, es, fr, it, ja, nl, ru } from 'date-fns/locale';
import i18next, { t } from 'i18next';
import { DecimalFormatProps } from '../Models/App/Inputs/types';
import appConfig from '../config/config';
import { TableColumn, TableProps } from './Components/Table/Table';
import { countries } from './StaticData/countries';
import { IPaymenPlan } from '../Models/App/PaymentModels';
import { UserPermissions } from '../Models/API/UsersAndPermissions/user-permissions-info';
import { CapTablePermission, OptionsPermission, WaterfallPermission } from '../Models/API/UsersAndPermissions/permissions-enum';

type MimeType = 'doc' | 'docx' | 'xls' | 'xlsx' | 'pdf' | 'jpeg' | 'jpg' | 'png';

export const REGEX_NUMBERS = /^[0-9\b]+$/;
export const REGEX_NUMBERS_FLOAT = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
export const REGEX_PERCENTAGE = /^(?:100(?:\.0{1,2})?|[0-9]{1,2}(?:\.[0-9]{1,2})?)$/;
export const REGEX_PERCENTAGE_6 = /^(?:100(?:\.0{1,2})?|[0-9]{1,2}(?:\.[0-9]{1,6})?)$/;
export const REGEX_ONLY_LETTERS_SPACES = /^[a-zA-Z ]+$/;
export const REGEX_EMAIL =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;<>,.?~])(?=.*[^\s]).{8,}$/;

export const isArray = (value: unknown): value is Array<unknown> => Array.isArray(value);
export const isAsyncFn = (fn?: Function) => fn?.constructor.name === 'AsyncFunction';
export const isFunction = (fn?: any): fn is Function => fn?.constructor?.name === 'Function';
export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean';
export const isDate = (value?: unknown): value is Date => value instanceof Date;
export const isNumber = (value: unknown): value is number => typeof value === 'number';
export const isObject = (obj: unknown): obj is object => typeof obj === 'object' && obj?.constructor === Object;
export const isString = (value: unknown): value is string => typeof value === 'string';

const isVerySmallNumber = (num: number) => (num > 0 && num < 0.0000000001) || (num < 0 && num > -0.0000000001);

export function formatNumber(n?: number | string, includeZero = true) {
	if (isString(n)) n = +n;
	if (n === 0 && !includeZero) return '';
	if (!isNullOrUndefined(n) && !isNaN(n)) {
		if (isVerySmallNumber(n)) return '0';
		return new Intl.NumberFormat().format(n);
	}
	return '';
}

export function formatDecimal(n: any, settings?: DecimalFormatProps): string {
	if (isNullOrUndefined(n) || isNaN(+n)) return '';

	if (isVerySmallNumber(n)) return '0';

	const numbAfterDot = settings?.decimalLength ?? 2;

	var parts = n.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	if (parts[1]) parts[1] = parts[1].slice(0, numbAfterDot);
	return parts.join('.');
}

export const languageDetector = (lng: string = i18next.language) => {
	switch (lng) {
		case 'fr-FR':
			return fr;
		case 'it-IT':
			return it;
		case 'de-DE':
			return de;
		case 'ja':
			return ja;
		case 'ru-RU':
			return ru;
		case 'es-ES':
			return es;
		case 'nl':
			return nl;

		default:
			return undefined;
	}
};
export function zeroIfNegative(n?: number) {
	return isNullOrUndefined(n) || n < 0 ? 0 : n;
}
export const getFileExtension = (fname: string, withDot: boolean = true) => {
	if (fname.lastIndexOf('.') === -1) return '';
	return (withDot ? '.' : '') + fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2).toLocaleLowerCase();
};

export const getFileNameWithoutExtension = (fname: string) => {
	return fname.replace(fname.substr(fname.lastIndexOf('.')), '');
};
export const fileToBase64 = (file: File): Promise<string | undefined> => {
	return new Promise<string | undefined>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const str = reader.result?.toString();
			if (str) {
				const index = str.indexOf('base64,') + 7;
				const subStr = str.substr(index);
				return resolve(subStr);
			}
		};
		reader.onerror = (error) => reject(error);
	});
};

export function blobToBase64(blob: Blob): Promise<string> {
	return new Promise((resolve, _) => {
		const reader = new FileReader();
		reader.onload = () => {
			const str = reader.result?.toString();
			if (str) {
				const index = str.indexOf('base64,') + 7;
				const subStr = str.substr(index);
				return resolve(subStr);
			}
		};
		reader.readAsDataURL(blob);
	});
}

/**
 * Convert BASE64 to BLOB
 * @param base64 Pass Base64  data to convert into the BLOB
 */
export const convertBase64ToBlob = (base64: string, mimeType: string) => {
	// Decode Base64 string
	const decodedData = window.atob(base64);

	// Create UNIT8ARRAY of size same as row data length
	const uInt8Array = new Uint8Array(decodedData.length);

	// Insert all character code into uInt8Array
	for (let i = 0; i < decodedData.length; ++i) {
		uInt8Array[i] = decodedData.charCodeAt(i);
	}

	// Return BLOB image after conversion
	return new Blob([uInt8Array], { type: mimeType });
};

export const base64ToBinary = (base64: string) => {
	const binaryString = atob(base64.split(',')[1]); // Remove the data URL prefix
	const len = binaryString.length;
	const bytes = new Uint8Array(len);

	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return bytes;
};

export const base64ToFile = (base64: string, fileName: string, mimeType: MimeType) => {
	const type = getMimeType(mimeType);
	const binaryData = base64ToBinary(`data:${type};base64,${base64}`);
	const blob = new Blob([binaryData], { type });
	const file = new File([blob], fileName, { type });

	return file;
};

// Returns mime type based on file extension
export const getMimeType = (extension: MimeType) => {
	const _ext = extension.toLowerCase();
	switch (_ext) {
		case 'doc':
			return 'application/msword';
		case 'docx':
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

		case 'xls':
			return 'application/vnd.ms-excel';
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'pdf':
			return 'application/pdf';

		case 'jpeg':
		case 'jpg':
			return 'image/jpeg';
		case 'png':
			return 'image/png';
		default:
			return 'application/octet-stream';
	}
};

export const getExtensionByType = (type: string) => {
	const _ext = type.toLowerCase();
	switch (_ext) {
		case 'application/msword':
			return '.doc';
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			return '.docx';
		case 'application/vnd.ms-excel':
			return '.xls';
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return '.xlsx';
		case 'application/pdf':
			return '.pdf';

		case 'image/jpeg':
			return '.jpg';
		case 'image/png':
			return '.png';
		default:
			return '';
	}
};

export const pdfTronBase64ToBlob = (base64: string): Blob => {
	const binaryString = window.atob(base64);
	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; ++i) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return new Blob([bytes], { type: 'application/pdf' });
};

export function setPropsValue<T>(obj: T, newPropsValues: Partial<T>) {
	for (var key in newPropsValues) {
		obj[key] = newPropsValues[key] as any;
	}
}

// export function toastSuccess(message?: string | null) {

//   Toast.success(message, {
//     position: "top-right",
//     autoClose: 6000,
//     hideProgressBar: false,
//     //closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//   });
// }

export function toastError(message?: string | null) {
	if (process.env.REACT_APP_IS_ERROR_NOTIFICATIONS_ACTIVE) {
		Toast.error(message, {
			position: 'top-right',
			autoClose: 8000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}
interface ToastPromiseParams {
	pending?: string;
	success?: string;
	error?: string;
}
export function toastPromise<T>(promise: Promise<T>, messages: ToastPromiseParams) {
	return Toast.promise(promise, messages);
}

export const capTableAssetTypeSwitch = <TReturn>(
	id: number | undefined | null,
	onSafe: () => TReturn,
	onConvertibleLoan: () => TReturn,
	onClass: () => TReturn
) => {
	if (id) {
		if (id === ExtraAssetTypes.SAFE) {
			return onSafe();
		} else if (id === ExtraAssetTypes.ConvertibleLoan) {
			return onConvertibleLoan();
		} else {
			return onClass();
		}
	}
};

export const encrypt = (password: string) => {
	return CryptoJS.MD5(password).toString().replace('=', '').substring(0, 19);
};

export const encryptAes = (password: string) => {
	const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_KEY!);
	const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_IV!);

	let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key, {
		keySize: 128 / 8,
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	}).toString();

	return encrypted;
};

// Check if role
export function isInRoleGroup(roleToCheck: DataRoomPermissionsLVL, group: DataRoomPermissionsLVL) {
	const permissions = new Map<DataRoomPermissionsLVL, Array<DataRoomPermissionsLVL>>([
		[
			DataRoomPermissionsLVL.Admin,
			[
				DataRoomPermissionsLVL.Admin,
				DataRoomPermissionsLVL.Owner,
				DataRoomPermissionsLVL.Contributer,
				DataRoomPermissionsLVL.Downloader,
				DataRoomPermissionsLVL.Viewer,
			],
		],
		[
			DataRoomPermissionsLVL.Owner,
			[DataRoomPermissionsLVL.Owner, DataRoomPermissionsLVL.Contributer, DataRoomPermissionsLVL.Downloader, DataRoomPermissionsLVL.Viewer],
		],
		[DataRoomPermissionsLVL.Contributer, [DataRoomPermissionsLVL.Contributer, DataRoomPermissionsLVL.Downloader, DataRoomPermissionsLVL.Viewer]],
		[DataRoomPermissionsLVL.Downloader, [DataRoomPermissionsLVL.Downloader, DataRoomPermissionsLVL.Viewer]],
		[DataRoomPermissionsLVL.Viewer, [DataRoomPermissionsLVL.Viewer]],
	]);

	const groupPermissions = permissions.get(roleToCheck);
	if (groupPermissions) {
		return groupPermissions?.indexOf(group) > -1;
	}

	return false;
}
export function isInRoleGroupAction(roleToCheck: DataRoomPermissionsLVL, group: DataRoomPermissionsLVL) {
	const isInGroup = isInRoleGroup(roleToCheck, group);
	return {
		render: (element: any) => {
			if (isInGroup) {
				return element;
			}
		},
	};
}

export function sleep(ms: number) {
	return new Promise((r) => setTimeout(r, ms));
}

export function truncate(str: string, n: number) {
	return str.length > n ? str.substr(0, n - 1) + '...' : str;
}

export function emptyCache() {
	if ('caches' in window) {
		caches.keys().then((names) => {
			// Delete all the cache files
			names.forEach((name) => {
				caches.delete(name);
			});
		});

		window.location.reload();
	}
}

export function getSumOfArray(array: object[], field: string) {
	return array.reduce((acc: number, data: any) => data[field] + acc, 0);
}

function checkNullOrUndefined(ob: any) {
	return ob === null || ob === undefined;
}

export function isNullOrUndefined(param: unknown): param is null | undefined {
	if (isArray(param)) {
		return param.some((mbr) => checkNullOrUndefined(mbr));
	}
	return checkNullOrUndefined(param);
}

export function eliminateTimeZone(date: Date) {
	const newDate = new Date(date);
	const timeOffset = newDate.getTimezoneOffset();
	const prevTime = newDate.getTime();
	const fullTime = new Date(prevTime - timeOffset * 60 * 1000).toISOString();

	return fullTime;
}

export enum DateFormatsEnum {
	NORMAL = 'dd/MM/yyyy',
	NORMAL_HYPHEN = 'dd-MM-yyyy',
	NORMAL_US = 'MM/dd/yyyy',
	GENERAL = 'dd/mm/yyyy',
	LABEL_SHORT = 'dd MMM yyyy',
	LABEL_TIME_SHORT = 'dd MMM, yyyy HH:mm',
	LABEL_AMPM_TIME_SHORT = 'dd MMM, yyyy hh:mm a',
	LABEL_LONG = 'MMMM dd, yyyy',
	TIME = 'HH:mm:ss',
	MONTH_YEAR = 'MMMM, yyyy',
	MONTH_YEAR_SHORT = 'MMM, yyyy',
	FULL = 'MMM dd, yyyy HH:mm:ss',
	DASHED = 'yyyy-MM-dd',
}

export const formatDate = (date: Date | string | number | undefined | null, dateFormat: DateFormatsEnum = DateFormatsEnum.LABEL_SHORT): string | undefined => {
	if (isNullOrUndefined(date) || !isDateValid(new Date(date)) || +new Date(date) === 0) return undefined;
	return format(new Date(date), dateFormat);
};

export const isDateValid = (date: any) => isValid(date);

export const copyToClipboard = (text: string) => {
	var textField = document.createElement('textarea');
	textField.innerText = text;
	document.body.appendChild(textField);
	textField.select();
	navigator.clipboard.writeText(text);
	textField.remove();
};

export const getLocalStorage = (key: string): string | boolean | object | number | undefined => {
	try {
		const data = localStorage.getItem(key);
		return data ? JSON.parse(data) : undefined;
	} catch (error) {
		return undefined;
	}
};

export const setLocalStorage = (key: string, value: any): void => {
	try {
		localStorage.setItem(key, JSON.stringify(value));
	} catch (error) {
		return undefined;
	}
};

export const deleteLocalStorage = (key: string): void => {
	try {
		localStorage.removeItem(key);
	} catch (error) {
		return undefined;
	}
};

export const getSessionStorage = (key: string): string | boolean | object | number | undefined => {
	try {
		const data = sessionStorage.getItem(key);
		return data ? JSON.parse(data) : undefined;
	} catch (error) {
		return undefined;
	}
};

export const setSessionStorage = (key: string, value: any): void => {
	try {
		sessionStorage.setItem(key, JSON.stringify(value));
	} catch (error) {
		return undefined;
	}
};

export const deleteSessionStorage = (key: string): void => {
	try {
		sessionStorage.removeItem(key);
	} catch (error) {
		return undefined;
	}
};

export interface ExcelProps<T> extends Pick<TableProps<T>, 'color' | 'exportToExcel' | 'rows'> {
	columns: Partial<TableColumn<T>>[];
	showTotal?: boolean;
	isDownload?: boolean;
	onAfterDataHandler?: (data: ExcelJS.Worksheet) => ExcelJS.Worksheet;
	onBeforeDataHandler?: (data: ExcelJS.Worksheet) => ExcelJS.Worksheet;
	workbook?: ExcelJS.Workbook;
	frozen?: number[];
}

export const onExportToExcel = <T extends object>({
	rows,
	exportToExcel,
	columns,
	color,
	showTotal,
	isDownload = true,
	onAfterDataHandler,
	onBeforeDataHandler,
	workbook,
	frozen,
}: ExcelProps<T>) => {
	const wb = workbook || new ExcelJS.Workbook();
	const workbookName = `Altshare - ${exportToExcel?.fileName}.xlsx`;
	const worksheetName = exportToExcel?.sheetName;
	let ws = wb.addWorksheet(worksheetName, {
		properties: {
			// tabColor: { argb: appConfig.style.colors.color1.replace('#', '') },
			defaultColWidth: 25,
			defaultRowHeight: 30,
		},
		pageSetup: {
			horizontalCentered: true,
			verticalCentered: true,
		},
		views: frozen?.map((f) => ({ state: 'frozen', ySplit: f })),
	});

	ws.columns = columns.map((col: Partial<TableColumn<T>>) => ({
		header: col.label ? t(col.label) : (col.name as string),
		key: col.label as string,
	}));

	if (onBeforeDataHandler) {
		ws = onBeforeDataHandler(ws);
	}

	const data =
		rows?.map((row: T) =>
			columns.map((col) =>
				col.format
					? col.format(row[col.name as keyof T], row) || ''
					: isArray(col.name)
					? col.name.map((key) => row[key]).join(' ')
					: row[col.name as keyof T] || ''
			)
		) || [];

	ws.addRows([...data]);

	// Setting background to header
	ws.getRow(1).eachCell((cell, idx) => {
		// if (idx === 1) return;
		cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: {
				argb: appConfig.style.colors[color === 'primary' ? 'color1' : 'text1'].replace('#', ''),
			},
		};
		cell.font = {
			...cell.font,
			color: {
				argb: 'ffffff',
			},
			bold: true,
		};
	});

	// Aligning to center all the cells
	const borderColor = appConfig.style.colors[color === 'primary' ? 'color1Secondary' : 'gray2'].replace('#', '');
	ws.eachRow((row) => {
		row.height = 30;
		row.eachCell((cell) => {
			cell.border = {
				...cell.border,
				top: {
					style: 'thin',
					color: {
						argb: borderColor,
					},
				},
				bottom: {
					style: 'thin',
					color: {
						argb: borderColor,
					},
				},
				left: {
					style: 'thin',
					color: {
						argb: borderColor,
					},
				},
				right: {
					style: 'thin',
					color: {
						argb: borderColor,
					},
				},
			};
			cell.alignment = {
				vertical: 'middle',
				horizontal: 'center',
			};
			const cellValue = cell.value?.toString() || '';
			const isNumber = /^[+-]?\d+(\.\d+)?$/.test(cellValue.replaceAll(',', ''));
			if (isNumber) {
				cell.value = parseFloat(cellValue.replaceAll(',', ''));
				cell.numFmt = cell.value % 1 === 0 ? '#,##0' : '#,##0.00';
			} else if (!isNaN(parseInt(cellValue)) && cellValue.endsWith('%')) {
				cell.value = parseFloat(cellValue) / 100;
				cell.numFmt = '0.00%';
			} else if (isValid(parse(cellValue, DateFormatsEnum.LABEL_SHORT, new Date()))) {
				cell.numFmt = DateFormatsEnum.LABEL_SHORT;
			}
		});
	});

	// Last row
	if (showTotal) {
		ws.getCell(ws.rowCount, 1).value = t('options.total') as string;
		ws.getRow(ws.rowCount).font = { bold: true };
		ws.getRow(ws.rowCount).eachCell((cell) => {
			cell.border = {
				...cell.border,
				// top: { style: "medium", color: { argb: "a7aab7" } },
			};
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: {
					argb: appConfig.style.colors.background1.replace('#', ''),
				},
			};
		});
	}

	// // if (sheetName === "Grants") {
	// //   ws.columns[1].numFmt = "@";
	// //   ws.columns[1].width = 25;
	// // }

	if (onAfterDataHandler) {
		ws = onAfterDataHandler(ws);
	}

	// ws.getColumn(1).width = 25;
	if (!isDownload) return wb;
	wb.xlsx.writeBuffer({ useStyles: true }).then((buffer) => {
		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), workbookName);
	});
};

export const checkQaidConvention = (string: string) => {
	const qaidPattern = /(^[A-Z].*)(.Button|.Link|.Select|.Option|.Input|.CheckBox)(.[A-Z].*)/;
	// if (!qaidPattern.test(string)) {
	// 	console.log(
	// 		`%cWarning: The qaid "${string}" does not match the convention ([Page/Component/Dialog Name].[Button | Link | Select | Option | Input | CheckBox].[Description])`,
	// 		`padding: 5px; background: ${appConfig.style.colors.danger}; color: white; font-size: 10px; font-weight: bold;`,
	// 	);
	// }
};

export function getUniqueId(): string {
	return Math.random().toString(16).slice(2);
}

export const sortArrayBy = (array: any[], key: string, isDesc: boolean = true) => array.sort((a, b) => b[key] - a[key] * (isDesc ? 1 : -1));

export const addDaysToDate = (date: string | Date, days: number) => {
	const newDate = new Date(date);
	return new Date(newDate.setDate(newDate.getDate() + days));
};

export const addMonthsToDate = (date: string | Date, months: number) => {
	const newDate = new Date(date);
	return new Date(newDate.setMonth(newDate.getMonth() + months));
};

export const addYearsToDate = (date: string | Date, years: number) => {
	const newDate = new Date(date);
	return new Date(newDate.setFullYear(newDate.getFullYear() + years));
};

export const getLastDayOfMonath = (date: string | Date) => {
	const d = new Date(date);
	return new Date(d.getFullYear(), d.getMonth() + 1, 0);
};

export const multiplyNumbers = (number1: number | undefined, number2: number | undefined) => {
	if (number1 !== undefined && number2 !== undefined) {
		return number1 * number2;
	} else {
		return undefined;
	}
};

export const getSum = (...args: Array<number | undefined | null>) => args.reduce((acc: number, arg: number | undefined | null): number => acc + (arg || 0), 0);

export const checkIfMacUser = () => navigator.platform?.toLowerCase().includes('mac');

export const getPercentrage = (number: number) => {
	return isNaN(number) ? '' : `${formatDecimal(number * 100)}%`;
};

export const getRandomNumberBetween = (min: number, max: number) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export type AsyncFunction<T = any> = (...args: any[]) => Promise<T>;
export const simulateWithMockData = <T extends (...args: any[]) => Promise<any>>(
	originalFunction: T,
	mockData: ReturnType<T> extends Promise<infer U> ? U : never
): T => {
	return ((...args: any[]) => {
		console.log(`Using mock data for ${originalFunction.name}`);
		try {
			return Promise.resolve(mockData);
		} catch (error) {
			console.error(`Error simulating ${originalFunction.name}:`, error);
			return Promise.reject(error);
		}
	}) as T;
};

enum ScreenBreakpoints {
	sm = 1280,
	md = 1366,
	lg = 1710,
	xl = 1920,
}

export const print = (text: any, type: 'error' | 'info' | 'success' = 'info') => {
	const background = type === 'error' ? '#dc3545' : type === 'success' ? '#28a745' : '#ff6607';
	// console.log(`%c${text}`, `color: #ffffff; background: ${background}; padding: 2px; font-weight: bold;`);
};

export const getCalculatedFontSize = (): number | undefined => {
	const {
		screen: { width },
		devicePixelRatio,
	} = window;

	print(`Screen width: ${width}px, Scale: ${devicePixelRatio * 100}%`);
	let pixel: number | undefined = 9;

	if (checkIfMacUser()) {
		switch (true) {
			case width >= ScreenBreakpoints.xl:
				print(`Rescaling font size case # 1`);
				pixel = 9.5;
				break;

			case width >= ScreenBreakpoints.lg:
				print(`Rescaling font size case # 2`);
				pixel = 9;
				break;

			case width >= ScreenBreakpoints.md:
				print(`Rescaling font size case # 3`);
				pixel = 7;
				break;

			case width >= ScreenBreakpoints.sm:
				print(`Rescaling font size case # 4`);
				pixel = 6;
				break;

			default:
				pixel = undefined;
				break;
		}
		return pixel;
	}

	const sizeWithRatio = width * devicePixelRatio;

	if (sizeWithRatio >= ScreenBreakpoints.xl) {
		switch (true) {
			case devicePixelRatio === 1.25:
				print(`Rescaling font size case # 100`);
				pixel = 7;
				break;

			case devicePixelRatio === 1.5:
				print(`Rescaling font size case # 101`);
				pixel = 6.6;
				break;

			case devicePixelRatio >= 1.75:
				print(`Rescaling font size case # 102`);
				pixel = 5;
				break;

			default:
				break;
		}
	} else if (sizeWithRatio >= ScreenBreakpoints.lg) {
		switch (true) {
			case devicePixelRatio === 1.25:
				print(`Rescaling font size case # 200`);
				pixel = 7;
				break;

			case devicePixelRatio === 1.5:
				print(`Rescaling font size case # 201`);
				pixel = 5;
				break;

			case devicePixelRatio >= 1.75:
				print(`Rescaling font size case # 202`);
				pixel = 5;
				break;
			default:
				pixel = undefined;
				break;
		}
	} else if (sizeWithRatio >= ScreenBreakpoints.sm) {
		switch (true) {
			case devicePixelRatio === 1:
				print(`Rescaling font size case # 300`);
				pixel = 7;
				break;

			case devicePixelRatio === 1.25:
				print(`Rescaling font size case # 301`);
				pixel = 5.5;
				break;

			default:
				pixel = undefined;
				break;
		}
	} else {
		switch (true) {
			case devicePixelRatio === 1:
				print(`Rescaling font size case # 400`);
				pixel = 7;
				break;

			case devicePixelRatio === 1.25:
				print(`Rescaling font size case # 401`);
				pixel = 5.5;
				break;

			default:
				pixel = undefined;
				break;
		}
	}
	return pixel;
};

export const getCountry = (countryCode: number | string | undefined) => {
	return countries.find((country) => country[isNumber(countryCode) ? 'value' : 'label'] === countryCode);
};

export const getCurrency = (currency: Currencies) => {
	return currencies.find((c) => c.currencyId === currency) || { label: '', currencyId: 1, symbol: '' };
};

const SECOND = 1000;
const MIN = 60 * SECOND;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;

export const TIMING_MS = {
	SECOND,
	MIN,
	HOUR,
	DAY,
};

export function getProductsInPlan(plan: IPaymenPlan) {
	return {
		waterfall: plan.waterfall,
		capTable: plan.capTableManagement,
		equityPlans: plan.optionsManagement,
		funding: plan.fundingManagement,
		employeeWebsite: plan.employeeInterface,
		valuation: plan.valuationReport,
		expensing: plan.valuationReport,
	};
}

export function getDisabledProducts(permissions?: UserPermissions) {
	return {
		capTable: permissions?.capTablePermission === CapTablePermission.no_access,
		waterfall: permissions?.waterfallPermission === WaterfallPermission.no_access,
		expensing: permissions?.optionPermission === OptionsPermission.NotAllowed,

		equityPlans:
			!process.env.REACT_APP_IS_OPTIONS_ACTIVE ||
			(permissions ? !(permissions?.optionPermission <= OptionsPermission.editor && permissions?.optionPermission >= CapTablePermission.admin) : true),

		funding: !process.env.REACT_APP_IS_FUNDING_ACTIVE || (permissions ? permissions?.capTablePermission > CapTablePermission.editor : true),

		docs: permissions ? !(permissions?.optionPermission <= OptionsPermission.editor && permissions?.optionPermission >= CapTablePermission.admin) : true,

		employeeWebsite: permissions ? !(permissions?.employeeManagementPermission > EmployeesWebsitePermission.no_access) : true,

		equityPlansTrustee: Boolean(!process.env.REACT_APP_IS_OPTIONS_ACTIVE || !permissions?.optionPermission), //rootStore.equityPlansStore.showTrustee
	};
}

export const onOpenURL = (url: string, target: '_blank' | '_self' = '_blank') => window.open(url, target);