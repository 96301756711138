import { observer } from 'mobx-react-lite';
import { createRef, SyntheticEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IC_APPROVAL_CIRCLE_GRAY, IC_ISRAEL, IC_MSG_GRAY } from '../../../../Assets';
import appConfig from '../../../../config/config';
import { DataCollectionStatusEnum } from '../../../../Models/API/DataCollection/project-preview';
import { Routes } from '../../../../Routes';
import ActionsMenu from '../../../../Shared/Components/ActionsMenu/ActionsMenu';
import Button from '../../../../Shared/Components/Button/Button';
import ProgressBar from '../../../../Shared/Components/ProgressBar';
import useModal from '../../../../Shared/Hooks/useModal';
import useMultiStepForm, { ForwardedRef, MultiStepFormItem } from '../../../../Shared/Hooks/useMultiStepForm';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { isNumber } from '../../../../Shared/Utilities';
import { WaterfallFooter } from '../../../Waterfall/Components/AddEditWaterfall/index.style';
import { ActionsMenuStyle } from '../../../Waterfall/Components/WaterfallHeader/WaterfallHeader.style';
import { DataCollectionPermissionsEnum, hasProjectPermissions } from '../../helpers/utils';
import AddDocuments from './Steps/AddDocuments';
import GeneralInfo from './Steps/GeneralInfo';
import ImportCapTable from './Steps/ImportCapTable';

const AddEditDataCollection = () => {
	const { dataCollectionStore } = useRootStore();
	const params = useParams<{ step: string; valuationProjectId: string }>();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [lastAllowedStep, setLastAllowedStep] = useState<number>(0);
	const ref = createRef<ForwardedRef>();
	const { showModal } = useModal();
	const { step, onNextStep, onPrevStep, goTo, currentStepIdx, steps, isLastStep, isFirstStep } = useMultiStepForm([
		{
			element: <GeneralInfo ref={ref} />,
			caption: 'General information',
		},
		{
			element: <ImportCapTable ref={ref} />,
			caption: 'Import cap table',
		},
		{
			element: <AddDocuments ref={ref} />,
			caption: 'Add documents',
		},
	]);

	useEffect(() => {
		const step = +params.step;

		if (!isNaN(step)) goTo(step);

		return () => {
			// history.push(Routes.waterfall.index);
			dataCollectionStore.resetValuationProject();
			setIsLoading(false);
		};
	}, []);

	useEffect(() => {
		if (dataCollectionStore.valuationProjectId) {
			// dataCollectionStore.getDataCollection(dataCollectionStore.valuationProjectId);
		} else {
			dataCollectionStore.valuationProjectId = params.valuationProjectId;
		}
	}, [dataCollectionStore.valuationProjectId]);

	useEffect(() => {
		setLastAllowedStep(dataCollectionStore.dataCollection.capTableId ? 2 : dataCollectionStore.dataCollection.valuationDate ? 1 : 0);
	}, [dataCollectionStore.dataCollection]);

	useEffect(() => {
		if (!dataCollectionStore.valuationProjectId) return;
		history.replace(`${Routes.dataCollection.form}/${dataCollectionStore.valuationProjectId}/${currentStepIdx}`);
	}, [currentStepIdx, dataCollectionStore.valuationProjectId]);

	const onStepClickHandler = async (targetStepIdx: number) => {
		const isStepAllowed = checkIfStepAllowed(targetStepIdx);
		if (hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.SKIP_STEPS)) {
			return goTo(targetStepIdx);
		}

		if (isFirstStep && isStepAllowed) {
			return onSubmit(undefined, true, targetStepIdx);
		}

		if (isNextStep(targetStepIdx)) {
			return onSubmit(undefined, true);
		}
		if (!isStepAllowed) {
			return goTo(lastAllowedStep);
		}
		const stepsBetween = steps.slice(currentStepIdx + 1, targetStepIdx);
		const isSkippable = stepsBetween.every((step) => step.isRequired === false); // If all between steps are not mandatory, skip

		setLastAllowedStep(targetStepIdx);

		if (isSkippable) {
			await onSubmit(undefined, true, targetStepIdx);
			return goTo(targetStepIdx);
		}
	};

	const isNextStep = (targetStepIdx: number): boolean => {
		return targetStepIdx - 1 === currentStepIdx;
	};

	const checkIfStepAllowed = (targetStepIdx: number): boolean => {
		return targetStepIdx <= lastAllowedStep;
	};

	const onSubmit = async (e?: SyntheticEvent, isStepClick?: boolean, step?: number) => {
		e?.preventDefault();
		if (isLoading) return;

		setIsLoading(true);
		const isValid = await ref.current?.onValidate();
		setIsLoading(false);

		if (!isValid) return;
		// setLastAllowedStep(step ?? ((prev) => prev + 1));

		if (isNumber(step)) return goTo(step);

		if (!isLastStep) {
			return onNextStep(); // If not last step, continue to next step
		}

		onSaveHandler();

		// Otherwise submit
		// alert("Saved!");
	};

	const onSaveHandler = async () => {
		ref.current?.onValidate();
		history.push(Routes.dataCollection.index);
	};

	const onSubmitHandler = async () => {
		showModal({
			type: 'warning',
			title: 'Submit for review?',
			body: "altshare's valuation team will review the data collection and comments you added.",
			onConfirm: async () => {
				if (dataCollectionStore.dataCollectionProjectId === null) return;
				ref.current?.onValidate();
				const res = await dataCollectionStore.submitDataCollection(dataCollectionStore.dataCollectionProjectId);
				if (res.errorMessage) {
					return showModal({
						type: 'error',
						title: 'Submit for review failed',
						body: res.error?.data.validationErrors?.join(', ') || res.errorMessage,
					});
				}
				history.push(Routes.dataCollection.index);
			},
			confirmButton: {
				label: 'Confirm',
			},
			cancelButton: {
				label: 'Cancel',
			},
		});
	};

	const isSubmitAvailable =
		isLastStep &&
		hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.SUBMIT_FOR_REVIEW) &&
		[DataCollectionStatusEnum.DataCollection].includes(dataCollectionStore.currentStatus);

	return (
		<>
			<ProgressBar
				pageCaptions={steps.map((step: MultiStepFormItem) => step.caption || '')}
				onStepClickHandler={onStepClickHandler}
				currentStepIdx={currentStepIdx}
				title={
					<>
						<span style={{ color: appConfig.style.colors.text2 }}>Valuation name:</span>{' '}
						<span className="semi-bold">{dataCollectionStore.projectName}</span>
					</>
				}
				lastCompletedIdx={lastAllowedStep}
				actions={
					<div className={ActionsMenuStyle}>
						{isSubmitAvailable && <Button qaid="ProgressBar.Button.Submit" onClick={onSubmitHandler} label="Submit for review" />}
						<Button qaid="ProgressBar.Button.Quit" onClick={onSaveHandler} label="Save & Quit" cancel />
						{hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.RUN_MENU_ACTIONS) &&
							dataCollectionStore.isOwner && (
								<ActionsMenu
									actions={[
										{
											icon: IC_APPROVAL_CIRCLE_GRAY,
											label: dataCollectionStore.isProjectDisabled ? 'Re-open' : 'Approve data',
											onClick: () => {
												showModal({
													type: 'warning',
													title: 'Are you sure?',
													body: dataCollectionStore.isProjectDisabled
														? 'If you Re-open the data collection the customer will be able to change the data from his side.'
														: 'Once you approve the data collection, the customer will not be able to change the data from his side. You can always re-open the data collection',
													onConfirm: () => {
														dataCollectionStore.dataCollection.valuationProjectId &&
															dataCollectionStore.approveProject(dataCollectionStore.dataCollection.valuationProjectId);
													},
													confirmButton: {
														label: dataCollectionStore.isProjectDisabled ? 'Continue' : 'Confirm',
													},
												});
											},
											qaid: dataCollectionStore.isProjectDisabled
												? 'AddEditDataCollection.Button.Reopen'
												: 'AddEditDataCollection.Button.ApproveData',
										},
										{
											icon: IC_MSG_GRAY,
											label: 'Send notification',
											onClick: () => {
												showModal({
													type: 'warning',
													title: 'Are you sure?',
													body: 'The customer will receive an email that there are comments you have added for his reference regarding data collection.',
													onConfirm: () => {
														if (dataCollectionStore.dataCollection.valuationProjectId === null) return;
														dataCollectionStore.sendNotification(dataCollectionStore.dataCollection.valuationProjectId);
													},
													confirmButton: {
														label: 'Continue',
													},
												});
											},
											qaid: 'AddEditDataCollection.Button.SendNotification',
										},
										{
											icon: IC_ISRAEL,
											label: 'Israeli customer',
											onClick: () => {
												if (dataCollectionStore.isIsraeliCustomer) {
													return showModal({
														type: 'warning',
														title: 'Please note',
														body: 'The data collection has already been marked as an Israeli customer.',
													});
												}
												showModal({
													type: 'warning',
													title: 'Are you sure?',
													body: 'Please note that the payment process with an Israeli customer should be done outside of the platform.',
													onConfirm: () => {
														if (dataCollectionStore.dataCollection.valuationProjectId === null) return;
														dataCollectionStore.setIsraeliCustomer(dataCollectionStore.dataCollection.valuationProjectId);
													},
													confirmButton: {
														label: 'Continue',
													},
												});
											},
											qaid: 'AddEditDataCollection.Button.IsraeliCustomer',
										},
									]}
								/>
							)}
					</div>
				}
				isLastStepHighlighed
			/>
			{step}
			<WaterfallFooter>
				{!isFirstStep && (
					<Button qaid="AddEditWaterfall.Button.Back" type="button" disabled={isLoading} inverse onClick={onPrevStep} label="general.back" />
				)}

				<Button
					style={{ marginLeft: 'auto' }}
					qaid="AddEditWaterfall.Button.Next"
					isLoading={isLoading}
					onClick={isSubmitAvailable ? onSubmitHandler : onSubmit}
					label={isSubmitAvailable ? 'Submit for review' : isLastStep ? 'general.finish' : 'general.next'}
				/>
			</WaterfallFooter>
		</>
	);
};

export default observer(AddEditDataCollection);
