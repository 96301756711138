import { makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";
import TrusteeService, { ReqTypePivotal } from "../Services/TrusteeService";
import {
    BeneficiariesSummary, TrusteeGrantDocument, GrantDocuments, PoolSummary, TrusteeBeneficiaries, TrusteeCancelation, TrusteeGrant, TrusteeOrder, TrusteeManagerReport, GrantsSummary, GrantDocumentStatus,
} from "../Screens/TrusteeEquity/Types/trusteeTypes";
import { PagedTableReqParams, TrusteeBeneficiariesRes, TrusteeCancelationsRes, TrusteeFileReq, TrusteeGrantsRes, TrusteeOrdersRes, TrusteeReportParams, UpcomingVestingsRes } from "../Screens/TrusteeEquity/Types/ReqRes";

export default class TrusteeStore {
    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
        rootStore.companyStore.onCompanyChange.subscribe(this.initServices);
        this._dataAsOfDate = new Date();
    }

    private initServices = () => {
        if (this.rootStore.companyStore.companyId) {
            this.ResetStore()
            this.trusteeService = new TrusteeService(this.rootStore.companyStore.companyId)
            this.trusteeBeneficiariesTable = {} as TrusteeBeneficiariesRes

            RootStore.subscribeToLoading([], this.rootStore)
        }
    };

    ResetStore() {
        this._dataAsOfDate = new Date()

        this.poolSummary = {} as PoolSummary;
        this.grantsSummary = {} as GrantsSummary;
        this.benficiariesSummary = {} as BeneficiariesSummary;
        this.grantDocumentsSummary = {} as GrantDocuments;
        this.upcomingVestings = {} as UpcomingVestingsRes;


        this.trusteeGrants = {} as TrusteeGrant[];
        this.trusteeGrantsTable = {} as TrusteeGrantsRes;
        this.trusteeBeneficiaries = {} as TrusteeBeneficiaries[];
        this.trusteeBeneficiariesTable = {} as TrusteeBeneficiariesRes;
        this.trusteeOrders = {} as TrusteeOrdersRes;
        this.trusteeCancelations = {} as TrusteeCancelationsRes;
        this.trusteeGrantDocuments = {} as TrusteeGrantDocument[];
        this.trusteeManagerReports = {} as TrusteeManagerReport[];

    }

    trusteeService: TrusteeService = null as any;
    cardTableItemsInPage = 5
    mainTableItemsInPage: 5 | 10 = 5
    private _pivotalRegistration: boolean = false
    private _pivotalRegistrationOpen: boolean = false
    _dataAsOfDate: Date;
    _beneficiaryId: string | undefined;
    _grantId: string | undefined;

    get pivotalRegistration() {
        return this._pivotalRegistration
    }

    get pivotalRegistrationOpen() {
        return this._pivotalRegistrationOpen
    }

    set beneficiaryId(id: string) {
        console.log("Setting beneficiaryId", id);
        this._beneficiaryId = id;
    }

    set grantId(id: string) {
        console.log("Setting grantId", id);
        this._grantId = id;
    }

    get dataAsOfDate() {
        return this._dataAsOfDate;
    }

    set dataAsOfDate(date: Date) {
        this._dataAsOfDate = date
    }

    // Summaries
    poolSummary: PoolSummary = {} as PoolSummary;
    grantsSummary: GrantsSummary = {} as GrantsSummary;
    benficiariesSummary: BeneficiariesSummary = {} as BeneficiariesSummary;
    grantDocumentsSummary: GrantDocuments = {} as GrantDocuments;
    upcomingVestings: UpcomingVestingsRes = {} as UpcomingVestingsRes;

    // Main Tables
    trusteeGrants: TrusteeGrant[] = {} as TrusteeGrant[];
    trusteeGrantsTable: TrusteeGrantsRes = {} as TrusteeGrantsRes;
    trusteeBeneficiaries: TrusteeBeneficiaries[] = {} as TrusteeBeneficiaries[];
    trusteeBeneficiariesTable: TrusteeBeneficiariesRes = {} as TrusteeBeneficiariesRes;
    trusteeOrders: TrusteeOrdersRes = {} as TrusteeOrdersRes;
    trusteeCancelations: TrusteeCancelationsRes = {} as TrusteeCancelationsRes;
    trusteeGrantDocuments: TrusteeGrantDocument[] = {} as TrusteeGrantDocument[];
    trusteeManagerReports: TrusteeManagerReport[] = {} as TrusteeManagerReport[];

    // Beneficiary
    beneficiaryGrants: TrusteeGrant[] = {} as TrusteeGrant[];
    beneficiaryGrantsTable: TrusteeGrantsRes = {} as TrusteeGrantsRes;
    beneficiaryOrders: TrusteeOrder[] = {} as TrusteeOrder[];
    beneficiaryOrdersTable: TrusteeOrdersRes = {} as TrusteeOrdersRes;
    beneficiaryCancelations: TrusteeCancelation[] = {} as TrusteeCancelation[];
    beneficiaryCancelationsTable: TrusteeCancelationsRes = {} as TrusteeCancelationsRes;

    // Grant
    singleGrant: TrusteeGrant = {} as TrusteeGrant;
    grantOrders: TrusteeOrdersRes = {} as TrusteeOrdersRes;
    grantCancelations: TrusteeCancelationsRes = {} as TrusteeCancelationsRes;
    _companyLegalName: string = '';

    get companyId() {
        return this.rootStore.companyStore.companyId;
    }

    get companyLegalName() {
        return this._companyLegalName;
    }

    set companyLegalName(name: string) {
        this._companyLegalName = name;
    }


    set setRegistration(registration: boolean) {
        if (this._pivotalRegistration === registration) return;
        this._pivotalRegistration = registration
    }

    SetRegistrationModalOpen(isOpen: boolean) {
        this._pivotalRegistrationOpen = isOpen
    }

    // async TalkToPivotal<T, U extends any>(args: any,func: (x: U) => Promise<HigherLevelResponse<T>>) {
    //     const obj: HigherLevelResponse<T> = {
    //         data: {} as T,
    //         error: undefined,
    //         isSuccess: true,
    //         errorCode: undefined,
    //     } 



    //     return await func(args)
    // }

    // async Checker(num: string) {
    //     this.TalkToPivotal(num, this.trusteeService.GetTrusteeBeneficiaryCancelationsAsync(num))
    // }

    async SetUserCompanyToken(obj: ReqTypePivotal) {
        const res = await this.trusteeService.PutUserCompanyToken(obj)
        return res;
    }

    async GetPoolSummary() {
        const res = await this.trusteeService.GetPoolSummaryAsync(this.dataAsOfDate)
        if (res.data) {
            this.poolSummary = res.data as PoolSummary;
            // this._pivotalRegisterd = true
        }

        return res;
    }

    async GetGrantsSummary() {
        const res = await this.trusteeService.GetGrantsSummaryAsync(this.dataAsOfDate)
        if (res.data) {
            this.grantsSummary = res.data as GrantsSummary;
        }

        return res;
    }

    async GetBeneficiariesSummary() {
        const res = await this.trusteeService.GetBeneficiariesSummaryAsync(this.dataAsOfDate)
        if (res.data) {
            this.benficiariesSummary = res.data as BeneficiariesSummary;
        }
    }

    async GetGrantDocumentsSummary() {
        const res = await this.trusteeService.GetGrantDocumentsSummaryAsync(this.dataAsOfDate)
        if (res.data) {
            this.grantDocumentsSummary = res.data as GrantDocuments;
        }
    }

    async GetUpcomingVestingsSummary() {
        const res = await this.trusteeService.GetUpcomingVestingsSummaryAsync(this.dataAsOfDate);
        if (res.data) {
            this.upcomingVestings = res.data;
        }
    }

    GetUpcomingVestingsSummaryByParams = async (params: Partial<PagedTableReqParams>) => {
        params.AsOfDate = new Date().toISOString();
        params.ItemInPage = 3;
        // console.log('GetUpcomingVestingsSummaryByParams', params)
        const res = await this.trusteeService.GetUpcomingVestingsSummaryByParamsAsync(params);
        // console.log(' CRUSHED ? ')
        if (res.data) {
            this.upcomingVestings = res.data;
            return res.data.totalVestingEvents;
        }
    };

    async GetTrusteeGrants() {
        const res = await this.trusteeService.GetTrusteeGrantsAsync(this.dataAsOfDate);
        if (res.data) {
            this.trusteeGrantsTable = res.data;
        }
        // console.log('Pivotal Grants: ', res)
    }

    // async GetTrusteeGrantsByPage(pageNum: number) {
    //     const res = await this.trusteeService.GetTrusteeGrantsAsync(pageNum)
    //     if (res.data) {
    //         this.trusteeGrantsTable = res.data
    //         return res.data.numberOfRows
    //     }

    //     console.log('Pivotal Grants Paged: ', res)
    // }

    async GetTrusteeGrantsByParamsAsync(params: PagedTableReqParams) {
        params.AsOfDate = new Date().toISOString();
        // params.ItemInPage = 5

        const res = await this.trusteeService.GetTrusteeGrantsByParamsAsync(params);
        if (res.data) {
            this.trusteeGrantsTable = res.data;
        }
        // console.log('Pivotal Grants PARAMED: ', res)
        return res.data?.numberOfRows
    }

    async GetTrusteeBeneficiaries() {
        const res = await this.trusteeService.GetTrusteeBeneficiariesAsync(this.dataAsOfDate);
        if (res.data) {
            this.trusteeBeneficiaries = res.data.beneficiarys;
        }
    }

    async GetTrusteeBeneficiariesByParams(params: PagedTableReqParams) {
        params.AsOfDate = new Date().toISOString();

        const res = await this.trusteeService.GetTrusteeBeneficiariesByParamsAsync(params);
        if (res.data) {
            this.trusteeBeneficiariesTable = res.data;
            return res.data?.numberOfTotalBeneficiarys;
        }
    }

    async GetTrusteeOrders() {
        const res = await this.trusteeService.GetTrusteeOrdersAsync(this.dataAsOfDate);
        if (res.data) {
            this.trusteeOrders = res.data;
        }
    }

    GetTrusteeOrdersByParams = async (params: PagedTableReqParams) => {
        params.ItemInPage = 10;
        params.AsOfDate = this.dataAsOfDate;
        const res = await this.trusteeService.GetTrusteeOrdersByParamsAsync(params);
        if (res.data) {
            this.trusteeOrders = res.data;
            return res.data.totalNumberOfOrders;
        }
    };

    async GetTrusteeCancelations() {
        const res = await this.trusteeService.GetTrusteeCancelationsAsync(this.dataAsOfDate);
        if (res.data) {
            this.trusteeCancelations = res.data;
        }
    }

    GetTrusteeCancelationsByParams = async (params: PagedTableReqParams) => {
        params.ItemInPage = 10;
        params.AsOfDate = this.dataAsOfDate;

        const res = await this.trusteeService.GetTrusteeBeneficiaryCancelationsByParamsAsync(params);

        if (res.data) {
            this.trusteeCancelations = res.data;
            return res.data.totalNumberOfOrders;
        }
    };

    //      Grant Letters

    async GetTrusteeGrantDocuments(status: GrantDocumentStatus) {
        const res = await this.trusteeService.GetTrusteeGrantDocumentsAsync(status);
        if (res.data) {
            this.trusteeGrantDocuments = res.data.documents;
        }
    }

    async GetSingleTrusteeGrantDocument(documentId: string) {
        const res = await this.trusteeService.GetSingleTrusteeGrantDocumentAsync(documentId);
        console.log("Get Single TrusteeGrantDocument", res);
        return res;
    }

    async PostTrusteeGrantDocumentsSignage(docs: string[]) {
        const res = await this.trusteeService.SignTrusteeGrantDocuments(docs);
        console.log("Post TrusteeGrantDocuments Signage", res);
        return res;
    }

    async GetTrusteeManagerReports() {
        const res = await this.trusteeService.GetTrusteeManagerReportsAsync();
        if (res.data) {
            this.trusteeManagerReports = res.data.reportsToSee;
        }
    }

    async GenerateTrusteeReport(params: TrusteeReportParams) {
        const res = await this.trusteeService.GetSingleTrusteeReportAsync(params);
        if (res.data) return res.data;
    }

    // Grant Card
    async GetTrusteeGrantAndVests(grantId: string) {
        const res = await this.trusteeService.GetTrusteeGrantAndVestsAsync(grantId);
        if (res.data) {
            this.singleGrant = res.data.grants[0];
        }
    }

    async GetTrusteeGrantOrders(grantId: string) {
        const res = await this.trusteeService.GetTrusteeGrantOrdersAsync(grantId);
        if (res.data) {
            this.grantOrders = res.data;
        }
    }

    async GetTrusteeGrantOrdersByParams(params: PagedTableReqParams) {
        if (!this.grantId) {
            console.log("No Grant Id");
            return;
        }
        params.AsOfDate = new Date().toISOString();
        params.GrantId = this.grantId;
        params.ItemInPage = this.cardTableItemsInPage;

        const res = await this.trusteeService.GetTrusteeGrantOrdersByParamsAsync(params);
        if (res.data) {
            this.grantOrders = res.data;
            return res.data.totalNumberOfOrders;
        }
    }

    async GetTrusteeGrantCancelations(grantId: string) {
        const res = await this.trusteeService.GetTrusteeGrantCancelationsAsync(grantId);
        if (res.data) {
            this.grantCancelations = res.data;
        }
    }

    async GetTrusteeGrantCancelationsbyParams(params: PagedTableReqParams) {
        if (!this.grantId) {
            console.log("No Grant Id");
            return;
        }
        params.AsOfDate = new Date().toISOString();
        params.GrantId = this.grantId;
        params.ItemInPage = this.cardTableItemsInPage;

        const res = await this.trusteeService.GetTrusteeGrantCancelationsByParamsAsync(params);
        if (res.data) {
            this.grantCancelations = res.data;
            return res.data.totalNumberOfOrders;
        }
    }

    // Beneficiary Card
    async GetTrusteeBeneficiaryGrants(beneficiaryId: string) {
        console.log("Getting ben grants", !this.beneficiaryId);
        // if (!this.beneficiaryId) return

        const res = await this.trusteeService.GetTrusteeBeneficiaryGrantsAsync(beneficiaryId);
        if (res.data) {
            this.beneficiaryGrantsTable = res.data;
        }
    }

    async GetTrusteeBenficiaryGrantsByParams(params: PagedTableReqParams) {
        if (!this._beneficiaryId) {
            console.log("No Ben Id");
            return;
        }
        params.AsOfDate = new Date().toISOString();
        params.BeneficiaryId = this.beneficiaryId.toString();
        params.ItemInPage = this.cardTableItemsInPage;

        const res = await this.trusteeService.GetTrusteeGrantsByParamsAsync(params);
        if (res.data) {
            this.beneficiaryGrantsTable = res.data;
        }
        // console.log('Pivotal Grants PARAMED: ', res)
        return res.data?.numberOfRows
    }

    async GetTrusteeBenficiaryOrdersByParams(params: PagedTableReqParams) {
        if (!this._beneficiaryId) {
            // console.log('No Ben Id')
            return
        }

        params.AsOfDate = new Date().toISOString()
        params.BeneficiaryId = this.beneficiaryId.toString()
        params.ItemInPage = this.cardTableItemsInPage

        const res = await this.trusteeService.GetTrusteeBeneficiaryOrdersByParamsAsync(params);
        if (res.data) {
            this.beneficiaryOrdersTable = res.data;
        }
        // console.log('Pivotal orders PARAMED: ', res)
        return res.data?.totalNumberOfOrders
    }

    async GetTrusteeBeneficiaryOrders(beneficiaryId: string) {
        const res = await this.trusteeService.GetTrusteeBeneficiaryOrdersAsync(beneficiaryId);
        if (res.data) {
            this.beneficiaryOrdersTable = res.data;
        }
    }

    async GetTrusteeBeneficiaryOrdersByParams(params: PagedTableReqParams) {
        console.log("GetTrusteeBeneficiaryOrdersByParams", params);
        if (!this.beneficiaryId) {
            // console.log('No Ben Id')
            return
        }
        params.AsOfDate = new Date().toISOString();
        params.BeneficiaryId = this.beneficiaryId.toString();
        params.ItemInPage = this.cardTableItemsInPage;
        const res = await this.trusteeService.GetTrusteeBeneficiaryOrdersByParamsAsync(params);

        if (res.data) {
            this.beneficiaryOrdersTable = res.data;
        }
    }

    async GetTrusteeBeneficiaryCancelations(beneficiaryId: string) {
        const res = await this.trusteeService.GetTrusteeBeneficiaryCancelationsAsync(beneficiaryId);
        if (res.data) {
            this.beneficiaryCancelationsTable = res.data;
        }
    }

    async GetTrusteeBeneficiaryCancelationsByParams(params: PagedTableReqParams) {
        if (!this.beneficiaryId) {
            // console.log('No Ben Id')
            return
        }
        params.AsOfDate = new Date().toISOString();
        params.BeneficiaryId = this.beneficiaryId.toString();
        params.ItemInPage = this.cardTableItemsInPage;
        const res = await this.trusteeService.GetTrusteeBeneficiaryCancelationsByParamsAsync(params);

        if (res.data) {
            this.beneficiaryCancelationsTable = res.data;
            return res.data.totalNumberOfOrders;
        }
    }

    async BeneficiaryTerminationRequest(remarks: string, beneficiaryId: string, date: Date) {
        return await this.trusteeService.PostTermintaionRequestAsync(remarks, beneficiaryId, date);
    }

    async TrusteeFileRequest(data: TrusteeFileReq) {
        return await this.trusteeService.PostTrusteeRequestAsync(data);
    }

    async GetHistorySubmitFiles() {
        return await this.trusteeService.GetHistorySubmitFilesRequestAsync();
    }

    async GetSubmitFile(KeyContactRequestId: string, submitFilesToTrusteeType: number) {
        return await this.trusteeService.GetSubmitFileRequestAsync(KeyContactRequestId, submitFilesToTrusteeType);
    }

    async GetSaasMainDahsboard() {
        const res = await this.trusteeService.GetSaasMainDashboardTrusteeDataAsync()
        // console.log('GetSaasMainDashboard Res', res)
        return res
    }

    async PostTerminationTermiantion(requestId: string) {
        const res = await this.trusteeService.PostTerminationTerminationAsync(requestId)
        return res
    }

    GetDahboradData() {
        this.GetPoolSummary();
        this.GetGrantsSummary();
        this.GetBeneficiariesSummary();
        this.GetGrantDocumentsSummary();
        this.GetUpcomingVestingsSummary();
        this.GetTrusteeGrants();
        this.GetTrusteeBeneficiaries();
        // this.GetTrusteeGrantDocuments()
        this.GetTrusteeManagerReports();
    }
}
