// import { DataCollectionFieldType } from '../../../Models/API/DataCollection/comment-response';
// import { DataCollectionPayload } from '../../../Models/API/DataCollection/data-collection-payload';

import { DataCollectionInvestmentTypeEnum } from '../../../Models/API/DataCollection/data-collection-investment-type-enum';
import { DataCollectionPayload } from '../../../Models/API/DataCollection/data-collection-payload';
import { DataCollectionProjectReport, DataCollectionStatus, DataCollectionStatusEnum, ReportType } from '../../../Models/API/DataCollection/project-preview';
import { FourONinePermission } from '../../../Models/API/UsersAndPermissions/permissions-enum';
import { isArray, isNullOrUndefined } from '../../../Shared/Utilities';
import { ProjectStatusList } from '../Components/ProjectStatus';

export const validateDataCollectionBeforeSubmit = (data: DataCollectionPayload): [boolean, boolean] => {
	const validation: [boolean, boolean] = [true, true];
	if (data.capTableId === null) validation[1] = false;
	if (data.valuationDate === null) validation[0] = false;

	if (data.investmentExist === null) validation[0] = false;
	if (data.investmentExist === true && data.investmentType === null) validation[0] = false;
	if (
		data.investmentExist === true &&
		(data.investmentType === DataCollectionInvestmentTypeEnum.SAFE || data.investmentType === DataCollectionInvestmentTypeEnum.ConvertibleLoan) &&
		(data.nextRoundValuation === null || data.timeToLiquidity === null)
	)
		validation[0] = false;

	if (data.investmentExist === false && data.companyProfitable === null) validation[0] = false;
	if (data.investmentExist === false && data.companyProfitable === true && data.companyProfitableInFuture === null) validation[0] = false;
	if (data.investmentExist === false && data.companyProfitable === false && data.timeToLiquidity === null) validation[0] = false;
	return validation;
};

export const getStatusTitle = (status: DataCollectionStatusEnum) => {
	switch (status) {
		case DataCollectionStatusEnum.DataCollection:
			return 'Data collection';
		case DataCollectionStatusEnum.DataReview:
			return 'Data review';
		case DataCollectionStatusEnum.ValuationProcess:
			return 'Valuation process';
		case DataCollectionStatusEnum.PaymentApproval:
			return 'Payment approval';
		case DataCollectionStatusEnum.DraftSent:
			return 'Draft sent';
		case DataCollectionStatusEnum.FinalReport:
			return 'Final report';
	}
};

export const getDataCollectionStatus = (dcStatus: DataCollectionStatus[] = []) => {
	if (isNullOrUndefined(dcStatus)) return { statusList: [], currentStatus: DataCollectionStatusEnum.DataCollection };
	const statusArray = Object.keys(DataCollectionStatusEnum).slice(0, Object.keys(DataCollectionStatusEnum).length / 2);
	const statusList: ProjectStatusList = statusArray.map((status) => {
		const s = dcStatus.find((stat) => stat.status === +status);
		return {
			label: getStatusTitle(+status),
			date: s?.date ?? null,
			status: +status,
		};
	});
	const currentStatus = dcStatus[dcStatus.length - 1]?.status || DataCollectionStatusEnum.DataCollection;

	return {
		statusList,
		currentStatus,
	};
};

export const getDataCollectionReports = (reports: DataCollectionProjectReport[] = []) => {
	return reports.reduce(
		(acc, report) => {
			if (report.reportType === ReportType.Final) {
				return {
					...acc,
					final: report,
				};
			}

			if (isArray(acc.drafts)) {
				if (acc.drafts.length === 3) acc.drafts.shift();
				acc.drafts = [...acc.drafts, report];
			} else {
				acc.drafts = [report];
			}
			return acc;
		},
		{ drafts: undefined, final: undefined } as {
			drafts: DataCollectionProjectReport[] | undefined;
			final: DataCollectionProjectReport | undefined;
		}
	);
};

// permissions.js
export enum DataCollectionPermissionsEnum {
	ORDER_PROJECT,
	UPDATE_PROJECT,
	SEND_PAYMENT,
	SEND_COMMENT,
	READ_COMMENTS,
	SKIP_STEPS,
	SUBMIT_FOR_REVIEW,
	RUN_MENU_ACTIONS,
	APPROVE_ISSUE,
}

const permissionsByRoles: Record<FourONinePermission, DataCollectionPermissionsEnum[]> = {
	[FourONinePermission.NotAllowed]: [],
	[FourONinePermission.Admin]: [
		DataCollectionPermissionsEnum.ORDER_PROJECT,
		DataCollectionPermissionsEnum.UPDATE_PROJECT,
		DataCollectionPermissionsEnum.SEND_COMMENT,
		DataCollectionPermissionsEnum.READ_COMMENTS,
		DataCollectionPermissionsEnum.SUBMIT_FOR_REVIEW,
		DataCollectionPermissionsEnum.SEND_PAYMENT,
	],
	[FourONinePermission.Editor]: [
		DataCollectionPermissionsEnum.ORDER_PROJECT,
		DataCollectionPermissionsEnum.UPDATE_PROJECT,
		// DataCollectionPermissionsEnum.SEND_COMMENT,
		DataCollectionPermissionsEnum.READ_COMMENTS,
		DataCollectionPermissionsEnum.SUBMIT_FOR_REVIEW,
	],
	[FourONinePermission.FullAccess]: [DataCollectionPermissionsEnum.READ_COMMENTS],
	[FourONinePermission.SeeOnlymMine]: [],
	[FourONinePermission.NoAccess]: [],
	[FourONinePermission.Chief]: [
		DataCollectionPermissionsEnum.READ_COMMENTS,
		DataCollectionPermissionsEnum.SKIP_STEPS,
		DataCollectionPermissionsEnum.RUN_MENU_ACTIONS,
		DataCollectionPermissionsEnum.APPROVE_ISSUE,
	],
	[FourONinePermission.Valuation]: [
		DataCollectionPermissionsEnum.READ_COMMENTS,
		DataCollectionPermissionsEnum.SKIP_STEPS,
		DataCollectionPermissionsEnum.RUN_MENU_ACTIONS,
		DataCollectionPermissionsEnum.APPROVE_ISSUE,
	],
};

export const hasProjectPermissions = (valuationPermission: FourONinePermission, requiredPermission: DataCollectionPermissionsEnum) => {
	return permissionsByRoles[valuationPermission].includes(requiredPermission);
};
