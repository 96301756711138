import Flex from '../../../../../Shared/Components/Layout/Flex';
import { SelectedPlanStyle } from '../../SelectedPlan/SelectedPlan.Style';
import useRootStore from '../../../../../Shared/Hooks/useRootStore';
import Image from '../../../../../Shared/Components/Image';
import { IC_PAYMENT_PLAN_CHECK, IMG_PAYMENT_CHECKOUT_ALTSHARE_LOGO, STAKEHOLDERS } from '../../../../../Assets';
import { observer } from 'mobx-react-lite';
import { getPlanData } from '../../../Pricing/utils/utils';
import Tooltip from '../../../../../Shared/Components/Tooltip';
import { formatDecimal, formatNumber, isNullOrUndefined } from '../../../../../Shared/Utilities';
import React from 'react';
import { PreviewInvoice } from '../../../../../Models/App/PaymentModels';

type Props = {
	addons: Addon[];
};

type Addon = {
	name: string;
	price: number;
};

const SelectedAddon = ({ addons }: Props) => {
	const { paymentStore } = useRootStore();

	const plan = getPlanData(paymentStore.selectPlanDetails.code);
	const totalPrice = addons.reduce((acc, addon) => acc + addon.price, 0);

	return (
		<div className={SelectedPlanStyle}>
			<Flex className="topContainer">
				<Image className="topContainer__logo" src={plan.img} alt="altshare logo" />
				<span className="topContainer__title">{paymentStore.selectedPlan.name} Plan</span>
				<Flex className="priceContainer">
					<span className="priceContainer__price">${formatDecimal(totalPrice)}</span>
				</Flex>
				<span className="topContainer__subTitle">
					{addons.map((addon) => addon.name).join(', ')} (Up to {paymentStore.selectedPlan.maxNumOfStakeholders} stakeholders)
				</span>
				<span className="topContainer__subTitle comment">Excluding sales taxes</span>
			</Flex>
			<hr className="divider" />
			<hr className="divider" />
			<div className="invoiceContainer">
				<span className="invoiceContainer__title">Payment summary</span>
				<div className="invoiceContainer__paymentsContiner">
					{addons.map((addon) => (
						<div className="invoiceContainer__payment" key={addon.name}>
							<span className="desc">{addon.name}</span>
							<span className="amount">{formatDecimal(addon.price)}</span>
						</div>
					))}
					<hr className="divider clean" />
					<div className="invoiceContainer__payment total">
						<span className="desc">Total amount</span>
						<span className="amount">${formatNumber(totalPrice)}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default observer(SelectedAddon);
