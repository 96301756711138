import { CircularProgress } from "@mui/material";
import classNames from "classnames";
import { debounce } from "lodash";
import { CSSProperties, createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { Fade } from "react-bootstrap";
import { default as ReactDatePicker } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { AsyncAutoCompleteProps, InputRef } from "../../../Models/App/Inputs/types";
import { checkQaidConvention, isArray, isNullOrUndefined, isNumber, isString } from "../../Utilities";
import StyledSelect, { Option } from "../Select/Select.Style";
import Spinner from "../Spinner/Spinner";
import { StyledInput, inputClassName } from "./Input.Style";
import InputWrapper from "./InputWrapper";
import Flex from "../Layout/Flex";
import OverflowText from "../OverflowText";

const AsyncAutoCompleteInput = forwardRef<InputRef, AsyncAutoCompleteProps<any>>(
    ({ loadItems, onChange, onSelect, itemKeyLabel, treshhold = 1, ...props }, ref) => {
        checkQaidConvention(props.qaid);
        const { t } = useTranslation();
        const [isFocusOnce, setIsFocusOnce] = useState<boolean>(false);
        const inputRef = createRef<HTMLInputElement>();
        const optionsRef = createRef<HTMLInputElement>();
        const arrowRef = createRef<HTMLInputElement>();
        const dateRef = createRef<ReactDatePicker>();
        const [items, setItems] = useState<object[] | undefined>(undefined);
        const [isFetching, setIsFetching] = useState<boolean>(false);
        const [isInputChanged, setIsInputChanged] = useState<boolean>(false);
        const [isSelected, setIsSelected] = useState<boolean>(false);
        const [internalValue, setInternalValue] = useState<string>("");
        const [optionsStyle, setOptionsStyle] = useState<CSSProperties>();
        const [arrowStyle, setArrowStyle] = useState<CSSProperties>({ position: "absolute" });
        const [error, setError] = useState<string>();

        useEffect(() => {
            if (!inputRef?.current) return;

            const observer = new ResizeObserver((entries: any) => {
                const target = entries[0].target;
                setOptionsStyle((prevState) => ({
                    ...prevState,
                    width: `${target.clientWidth}px`,
                    top: `${target.offsetTop + 40}px`,
                    left: `${target.offsetLeft}px`,
                }));
                setArrowStyle((prevState) => ({
                    ...prevState,
                    right: `${target.offsetRight}px`,
                }));
            });

            observer.observe(inputRef.current);

            return () => observer.disconnect();
        }, [inputRef.current]);

        const debouncedSearch = useCallback(
            debounce(async (keyword) => {
                if (isNullOrUndefined(keyword) || keyword.toString().length < treshhold) return setItems(undefined);

                setIsFetching(true);

                try {
                    const items = await loadItems?.(keyword.toString());
                    setItems(items);
                } catch (error) {
                    console.log(error);
                    setItems([]); // To display 'None' unclick clicked outside in case of an error
                }

                setIsFetching(false);
            }, 500),
            [loadItems]
        );

        useEffect(() => {
            setInternalValue(props.value?.toString() || "");
        }, [props.value]);

        useEffect(() => {
            if (!loadItems || !isInputChanged) return;
            // if (!loadItems || !isFocusOnce || !isInputChanged) return;
            debouncedSearch(internalValue);
        }, [internalValue]);

        useImperativeHandle(ref, () => ({
			resetError() {
				setIsFocusOnce(false);
			},
			showError() {
				setIsFocusOnce(true);
			},
			focus() {
				inputRef?.current?.focus();
				inputRef?.current?.scrollIntoView({ behavior: 'smooth' });
				dateRef?.current?.setFocus();
			},
			clear() {
				setInternalValue('');
			},
			getName() {
				return props.name;
			},
			value: internalValue,
		}));

        const renderOption = (item: any) => {
            if (isNullOrUndefined(itemKeyLabel)) return;

            if (!isArray(itemKeyLabel)) return item[itemKeyLabel];

            if (isArray(itemKeyLabel[0])) {
                return itemKeyLabel.map((labels: any, idx: number) => (
                    <div
                        className={`text-line-${idx}`}
                        key={idx}
                    >
                        {labels.map((label: any) => item[label] || "---").join(" ")}
                    </div>
                ));
            }
            if (isArray(itemKeyLabel)) {
                return isString(itemKeyLabel[0]) && itemKeyLabel.map((key: any) => item[key]).join(" ");
            }
        };

        const showError = (isFocusOnce || props.forceValidation) && props.error;

        return (
            <InputWrapper
                {...props}
                error={showError ? props.error : undefined}
                relative
            >
                {props.isViewMode ? (
                    <Flex
                        height="3.6rem"
                        align="end"
                        justify="start"
                    >
                        <OverflowText>{internalValue || "---------"}</OverflowText>
                    </Flex>
                ) : (
                    <>
                        <StyledInput
                            {...props}
                            flex={1}
                            className={classNames(inputClassName, props.className || "", {
                                inverse: props.inverse,
                                flat: props.flat,
                            })}
                            disabled={props.disabled || props.isLoading}
                            onBlur={(e) => {
                                setIsInputChanged(false);
                                setIsFocusOnce(true);
                                props.onBlur?.(e);
                            }}
                            placeholder={props.placeholder ? t(props.placeholder) : props.placeholder}
                            onChange={(e: any) => {
                                setIsFocusOnce(false);
                                setInternalValue(e.target.value);
                                onChange?.(e.target.value, e.target.name);
                            }}
                            onInput={(e) => {
                                setIsSelected(false);
                                setIsInputChanged(true);
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                props.onClick?.(e);
                            }}
                            value={internalValue}
                            data-value={internalValue}
                            onFocus={(e) => {
                                setIsSelected(false);
                                props.onFocus?.(e);
                            }}
                            ref={inputRef}
                            data-qaid={props.qaid}
                        />
                        {props.isLoading && (
                            <CircularProgress
                                className="loading-spinner"
                                size={14}
                            />
                        )}
                        <Fade
                            in={Boolean(isFetching || items)}
                            unmountOnExit
                        >
                            <StyledSelect.OptionsContainer
                                className="options-container"
                                ref={optionsRef}
                                style={optionsStyle}
                            >
                                {isFetching ? (
                                    <Option
                                        style={{ height: 30 }}
                                        className="disabled"
                                    >
                                        <Spinner
                                            incorporated
                                            center
                                            size={12}
                                        />
                                    </Option>
                                ) : !items?.length ? (
                                    <Option className="disabled">{t("general.none")}</Option>
                                ) : (
                                    items?.map((item: any, idx: number) => (
                                        <Option
                                            onClick={() => {
                                                onSelect?.(item, props.name);
                                                setItems(undefined);
                                            }}
                                            isColumn={isArray(itemKeyLabel) && isArray(itemKeyLabel[0])}
                                            isAutocomplete
                                            key={idx}
                                        >
                                            {renderOption(item)}
                                        </Option>
                                    ))
                                )}
                            </StyledSelect.OptionsContainer>
                        </Fade>
                    </>
                )}
            </InputWrapper>
        );
    }
);

export default AsyncAutoCompleteInput;
