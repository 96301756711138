import { IC_DOCUMENT, IC_EDIT2, IC_EQUITY_SETTINGS, IC_EQUITY_SETTINGS_BLACK, IC_FORM, IC_REPORT, IC_SUBMIT } from "../../../../Assets";
import { MenuActionType } from "./ActionsMenu";

export const equityMenuActions: MenuActionType[] = [
    {
        label: "General Equity Settings",
        icon: IC_EQUITY_SETTINGS_BLACK,
        // onClick: () => history.push("/ESOP/settings"),
        qaid: "ActionsMenu.Button.NewDraft",
        navigateTo: "/ESOP/settings",
    },
    {
        label: "Reports",
        icon: IC_REPORT,
        qaid: "ActionsMenu.Button.NewDraft",
        navigateTo: "/ESOP/reports",
    },
];

export const trusteeMenuActions: MenuActionType[] = [
    {
        label: "General Equity Settings",
        icon: IC_EQUITY_SETTINGS_BLACK,
        qaid: "ActionsMenu.Button.NewDraft",
        navigateTo: "/ESOP/settings",
    },
    {
        label: "Submit files to trustee",
        icon: IC_SUBMIT,
        qaid: "ActionsMenu.Button.NewDraft",
        navigateTo: "/trustee/submit_file",
    },
    {
        label: "Reports",
        icon: IC_REPORT,
        qaid: "ActionsMenu.Button.NewDraft",
        navigateTo: "/trustee/reports",
    },
    {
        label: "Forms",
        icon: IC_FORM,
        qaid: "ActionsMenu.Button.NewDraft",
        navigateTo: "/trustee/forms",
    },
    {
        label: "Grant documents",
        icon: IC_DOCUMENT,
        qaid: "ActionsMenu.Button.NewDraft",
        navigateTo: "/trustee/grantDocuments",
    },
];
