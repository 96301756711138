import { makeAutoObservable } from 'mobx';
import StoreUpdater from './StoreUpdater';
import { RootStore } from './RootStore';
import { TIMING_MS } from '../Shared/Utilities';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';

const LOGOUT_TRESHHOLD = 29.5 * TIMING_MS.MIN; // 29.5m (30s each check so 30m will be logged out)

export class AppStateStore {
	isLoading = false;
	error?: string;
	protected appFontSize: number = 10;
	protected handledErrorCode: number | undefined = undefined;
	lastApiCall?: Date = undefined;
	tutorial: Record<string, string> = {
		capTable: 'Jiya-V-uML0?si=qhlDv-v68zfMGsOD',
	};
	redirectUrlAfterLogin: string | undefined = undefined;

	protected pageTitle: string = 'Dashboard';

	updateStoreValue = new StoreUpdater(this).dispatcher();

	resetStoreToDefaultValues = () => {
		clearPersistedStore(this);
		this.redirectUrlAfterLogin = undefined;
	};

	constructor(public rootStore: RootStore) {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'AppStateStore',
			properties: ['redirectUrlAfterLogin'],
			storage: window.sessionStorage,
			expireIn: 10800000, // 3 hours
		});
	}

	get isDev(): boolean {
		return process.env.REACT_APP_ENV === 'dev';
	}

	get isProd(): boolean {
		return process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'stg';
	}

	setPageTitle(title: string) {
		this.pageTitle = title;
	}

	setErrorCode(code: number) {
		this.handledErrorCode = code;
	}

	setFontSize(size: number) {
		this.appFontSize = size;
	}

	get fontSize() {
		return this.appFontSize;
	}

	get errorCode(): number | undefined {
		return this.handledErrorCode;
	}

	get currentPageTitle(): string {
		return this.pageTitle;
	}

	updateLastApiCall(date?: Date) {
		this.lastApiCall = date;
	}

	get isTokenExpired(): boolean {
		if (!this.lastApiCall) return false;
		const secSinceLastCall = +new Date() - +this.lastApiCall;

		return secSinceLastCall > LOGOUT_TRESHHOLD;
	}
}
