import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IC_VALUATION_LOGO } from '../../Assets';
import { Routes } from '../../Routes';

import Button from '../../Shared/Components/Button/Button';
import useRootStore from '../../Shared/Hooks/useRootStore';
import { isArray } from '../../Shared/Utilities';
import { WaterfallFormSteps } from '../../Store/WaterfallStore';
import { SummaryStatusCode } from '../Waterfall/Components/AddEditWaterfall/Steps/Summary/deps/types';
import { WaterfallIndexStyle } from '../Waterfall/index.Style';
import MainMenu from './Components/MainMenu';
import { FourONinePermission } from '../../Models/API/UsersAndPermissions/permissions-enum';
// import MainMenu from './Components/MainMenu/MainMenu';
// import { WaterfallIndexStyle } from './index.Style';
// import { SummaryStatusCode } from './Components/AddEditWaterfall/Steps/Summary/deps/types';

const ValuationScreen = () => {
	const history = useHistory();

	const { valuationStore, appState } = useRootStore();

	useEffect(() => {
		if (valuationStore.currentRole === FourONinePermission.Chief && !valuationStore.economicUsers.length) {
			valuationStore.getEconomicUsers();
		}
		let interval: NodeJS.Timer;
		(async () => {
			const isProjectRunning = await getProjectsAndCheckIfRunning();
			if (!isProjectRunning) return;

			interval = setInterval(async () => {
				const isProjectRunning = await getProjectsAndCheckIfRunning();
				if (!isProjectRunning) clearInterval(interval);
			}, 6000);
		})();
		return () => {
			clearInterval(interval);
		};
	}, []);

	const getProjectsAndCheckIfRunning = async () => {
		const res = await valuationStore.getProjects();
		if (!isArray(res.data)) return false;
		return res.data.some((proj) => proj.summaryStatus?.status === SummaryStatusCode.RUNNING);
	};

	return (
		<section>
			<MainMenu />
			<div className={WaterfallIndexStyle}>
				<div className={`${WaterfallIndexStyle}__container`}>
					<img className="analysis-img" src={IC_VALUATION_LOGO} alt="" />
					<div className="analysis-text">409A</div>
					{/* <Button
						position="center"
						qaid="WaterfallScreen.Button.CreateNewAnalysis"
						label="waterfall.createNewAnalysis"
						onClick={() => {
							valuationStore.setProjectViewMode(false);
							valuationStore.isNewProject = true;
							history.push(`${Routes.dataCollection.valuation}/0/${WaterfallFormSteps.scenarios}`);
						}}
						className="btn-create-analysis"
					/> */}
				</div>
			</div>
		</section>
	);
};

export default observer(ValuationScreen);
