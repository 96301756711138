import { useRef, useState } from "react";
import AddEditNewCompany from ".";
import { CompanyRegistration } from "../../../Models/API/Auth";
import Title from "../../../Shared/Components/Layout/Title";
import AddEditNewCompanyStyle from "./index.style";
import useRootStore from "../../../Shared/Hooks/useRootStore";
import { isNullOrUndefined } from "../../../Shared/Utilities";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../Routes";
import useRecaptcha from "../../../Shared/Hooks/useRecaptcha";
import { ModalBodyProps } from '../../../Shared/Components/Modal/types';

interface Props {
	// onSubmit: (data: CompanyRegistration) => Promise<void> | void;
}

const AddNewCompany = (props: ModalBodyProps) => {
	const { auth, paymentStore, companyStore } = useRootStore();
	const history = useHistory();
	const { execute } = useRecaptcha();

	const onSubmitHandler = async (companyInfo: CompanyRegistration) => {
		companyStore.isCreatingCompanyFromCompany = true;
		const registerRes = await auth.registerCompany(companyInfo);
		if (!registerRes.isSuccess) return;
		const res = await Promise.all([paymentStore.getPlans(), paymentStore.updateBillingContactDetails()]);

		const billingInfo = await paymentStore.getBillingInfo();

		await execute(async (token) => {
			if (isNullOrUndefined(billingInfo)) return;
			if (isNullOrUndefined(res[0])) return;

			await paymentStore.customerSubscribePlan(billingInfo, res[0].usd[0].planId, token, true);
			await paymentStore.updateBillingContactDetails();
			companyStore.isCreatingCompanyFromCompany = false;
			companyStore.triggerCompanyChange(companyStore.company);
			props.removeModal?.();
			history.push(Routes.payment.pricing);
		});
	};

	return (
		<div className={AddEditNewCompanyStyle}>
			<Title className={`${AddEditNewCompanyStyle}__title`}>Create New Company</Title>
			<AddEditNewCompany onSubmit={onSubmitHandler} />
		</div>
	);
};

export default AddNewCompany;
