import { createContext, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { isNullOrUndefined } from "../../Utilities";
import Modal from "./Modal";
import { ModalContextProps, ModalProps, ModalProviderProps, ModalRef, ModalResponse, ModalWrapper } from "./types";

const ModalContext = createContext<Omit<ModalContextProps, "modals">>({} as ModalContextProps);

const ModalProvider = ({ children }: ModalProviderProps) => {
    const modalsRef = useRef<ModalWrapper>(null);

    const showModal = (props: ModalProps): ModalResponse => {
        const modalId = modalsRef.current?.getModalId() ?? 0;
        modalsRef.current?.show({ ...props, isShow: true, modalId });
        return { modalId };
    };

    const showAsyncModal = async (props: ModalProps): Promise<boolean> => {
        const modalId = modalsRef.current?.getModalId() ?? 0;
        modalsRef.current?.show({ ...props, isShow: true, modalId });
        await new Promise((res) => setTimeout(res, 50));
        return modalsRef.current ? modalsRef.current?.getModals()[modalId].getResolver() : true;
    };

    const removeModal = (modalId: number) => {
        modalsRef.current?.remove(modalId);
    };

    const hideModal = (modalId: number) => {
        modalsRef.current?.hide(modalId);
    };

    const displayModal = (modalId: number) => {
        modalsRef.current?.display(modalId);
    };

    const clearModals = () => {
        modalsRef.current?.clearModals();
    };

    const removeCurrentModal = () => {
        modalsRef.current?.removeCurrentModal();
    };

    return (
        <ModalContext.Provider value={{ showModal, removeModal, hideModal, displayModal, clearModals, showAsyncModal, removeCurrentModal }}>
            <Modals ref={modalsRef} />
            {children}
        </ModalContext.Provider>
    );
};

const Modals = forwardRef<any, any>((props, forwardedRef) => {
    const [modals, setModals] = useState<ModalProps[] | undefined>(undefined);
    const modalsRef = useRef<{ [key: number]: ModalRef }>({});

    useImperativeHandle(forwardedRef, () => ({
        getModalId() {
            return (modals?.[modals.length - 1]?.modalId ?? 0) + 1;
        },
        remove(modalId: number) {
            onRemoveHandler(modalId);
        },
        show(modal: ModalProps) {
            if (modal.qaid && modals?.some((m) => m.qaid === modal.qaid)) return;
            setModals((prevModals) => (prevModals ? [...prevModals, modal] : [modal]));
        },
        hide(modalId: number) {
            toggleDisplayModal(modalId, false);
        },
        display(modalId: number) {
            toggleDisplayModal(modalId, true);
        },
        clearModals() {
            setModals(undefined);
        },
        getModals() {
            return modalsRef.current;
        },
        removeCurrentModal() {
            return setModals((prevState) => prevState?.slice(0, prevState.length - 1));
        },
    }));

    const onRemoveHandler = (modalId: number = 0): Promise<void> => {
        return new Promise((res) => {
            setModals((modals) => modals?.filter((m) => m.modalId !== modalId));
        });
    };

    const toggleDisplayModal = async (modalId: number = 0, isShow: boolean): Promise<void> => {
        return new Promise((res) => {
            const modalIdx = modals?.findIndex((modal) => modal.modalId === modalId);
            if (isNullOrUndefined(modalIdx) || modalIdx === -1) return setTimeout(res, 50);
            setModals((prevState) =>
                prevState
                    ? [...prevState.slice(0, modalIdx), { ...prevState[modalIdx], isShow }, ...prevState.slice(modalIdx + 1)]
                    : undefined
            );

            setTimeout(res, 50);
        });
    };

    return (
        <div
            ref={forwardedRef}
            className="Modals"
        >
            {modals?.map((modal: ModalProps, idx: number) => (
                <Modal
                    key={modal.modalId}
                    ref={(el) => {
                        if (isNullOrUndefined(modalsRef.current) || isNullOrUndefined(el)) return;
                        modalsRef.current[modal.modalId ?? 0] = el;
                    }}
                    {...modal}
                    isLast={idx === modals.length - 1}
                    isFirst={idx === 0}
                    removeModal={() => onRemoveHandler(modal.modalId)}
                    hideModal={() => toggleDisplayModal(modal.modalId, false)}
                    displayModal={() => toggleDisplayModal(modal.modalId, true)}
                />
            ))}
        </div>
    );
});

export { ModalContext, ModalProvider };
