import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IC_VALUATION_LOGO } from '../../Assets';
import { Routes } from '../../Routes';
import Button from '../../Shared/Components/Button/Button';
import useQueryParams from '../../Shared/Hooks/useQueryParams';
import useRootStore from '../../Shared/Hooks/useRootStore';
import { WaterfallIndexStyle } from '../Waterfall/index.Style';
import MainMenu from './Components/MainMenu';
import { useDataCollectionModal } from './helpers/useDataCollectionModal';
import { DataCollectionPermissionsEnum, hasProjectPermissions } from './helpers/utils';

const DataCollection = () => {
	const params = useQueryParams();
	const history = useHistory();
	const {
		dataCollectionStore,
		companyStore: { companyId },
	} = useRootStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { onFilePreviewHandler } = useDataCollectionModal();

	useEffect(() => {
		dataCollectionStore.getProjects();
	}, [companyId]);

	useEffect(() => {
		if (!params.preview) return;
		const project = dataCollectionStore.projects?.find((proj) => proj.valuationProjectId === params.preview);
		if (project === undefined) return;

		onFilePreviewHandler(project, params.reportId, () => history.replace(Routes.dataCollection.index));
	}, [params, dataCollectionStore.projects]);

	const onOrderHandler = async () => {
		setIsLoading(true);
		const res = await dataCollectionStore.createProject();
		if (!res.data?.data.valuationProjectId) return;

		const dcRes = await dataCollectionStore.createDataCollection({
			valuationProjectId: res.data.data.valuationProjectId,
		});
		setIsLoading(false);
		if (!dcRes.data?.data.dataCollectionId) return;

		dataCollectionStore.valuationProjectId = res.data.data.valuationProjectId;
		history.push(`${Routes.dataCollection.form}/${dcRes.data.data.dataCollectionId}/0`);
	};

	return (
		<section>
			<MainMenu />
			<div className={WaterfallIndexStyle}>
				<div className={`${WaterfallIndexStyle}__container`}>
					<img className="analysis-img" src={IC_VALUATION_LOGO} alt="" />
					<div className="analysis-text">Create 409A for your company</div>
					{hasProjectPermissions(dataCollectionStore.currentRole, DataCollectionPermissionsEnum.ORDER_PROJECT) && (
						<Button
							qaid="DataCollection.Button.Order409A"
							label="Order new 409A"
							onClick={onOrderHandler}
							isLoading={isLoading}
							className="btn-create-analysis"
						/>
					)}
				</div>
			</div>
		</section>
	);
};

export default observer(DataCollection);
