import { Fade } from '@mui/material';
import classNames from 'classnames';
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import {
	IC_CAPTABLE_ARROW_LEFT,
	IC_CAPTABLE_ARROW_RIGHT,
	IC_EDIT,
	IC_VIEW,
	IC_FULLSCREEN_EXPAND,
	IC_FULLSCREEN_SHRINK,
	IC_LOOKUP,
	IC_PIN,
	IC_QUESTION_MARK_DARK,
	IC_WARNING_INFO_CIRCLE,
	IC_WARNING_INFO_CIRCLE_FILL,
} from '../../../../Assets';
import ExpandButton from '../../../../Shared/Components/Button/ExpandButton';
import Clickable from '../../../../Shared/Components/Clickable/Clickable';
import Image from '../../../../Shared/Components/Image';
import TextInput from '../../../../Shared/Components/Input/TextInput';
import Flex from '../../../../Shared/Components/Layout/Flex';
import { BorderStyle, Table } from '../../../../Shared/Components/Table/Table.Style';
import useModal from '../../../../Shared/Hooks/useModal';
import useScreenSize from '../../../../Shared/Hooks/useScreenSize';
import { isNullOrUndefined } from '../../../../Shared/Utilities';
import appConfig from '../../../../config/config';
import { CapTableColType, CapTableColumn, CapTableData, CapTableFilteredData, MainCapTableProps } from '../../types';
import EditCommonShares from './Dialogs/EditCommonShares';
import MainTableStyle, { CAPTABLE_CELL_HEIGHT } from './MainCapTable.style';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import OverflowText from '../../../../Shared/Components/OverflowText';
import { CapTableFullscreenMode } from '../../../../Models/API/enums';

const BOTTOM_OFFSET = 4;
const TOP_OFFSET = 1;
const MAX_ROWS = 8 + TOP_OFFSET * 2.5;
const MAX_SCROLL = 99999999;

const MainCapTable = ({
	data,
	onAddEditRound,
	onUpdateClass,
	onAddCommonShares,
	onAddOptions,
	onAddShareholder,
	isCommonSharesEmpty,
	minNumberOfShares = 0,
	numberOfRegisteredShares = 0,
	isPublishMode = false,
	isViewMode = false,
	onFullscreen,
	title,
	errors = [],
	canEditCommon = false,
	onSortBy,
	sortBy,
	fullscreenMode,
	isSearchDisabled,
}: MainCapTableProps) => {
	const [pinnedCols, setPinnedCols] = useState<string[]>([]);
	const [search, setSearch] = useState<string>();
	// const [isFullscreen, setIsFullScreen] = useState<boolean>(false);
	const [isScrollingHorizontal, setIsScrollingHorizontal] = useState<boolean>(false);
	const [isScrollingVertical, setIsScrollingVertical] = useState<boolean>(false);
	// const [showCommonSharesDialog, setShowCommonSharesDialog] = useState<boolean>(false);
	const [showTotalOptionsAndWarrants, setShowTotalOptionsAndWarrants] = useState<boolean>(false);
	const totalsRef = useRef<HTMLDivElement>(null);
	const addClassRef = useRef<HTMLDivElement>();
	const addShareHolderRef = useRef<HTMLDivElement>();
	const pinCommonRef = useRef<HTMLDivElement>(null);
	const pinOptionsRef = useRef<HTMLDivElement>(null);
	const tableRef = useRef<HTMLDivElement>(null);
	const shareholdersRef = useRef<HTMLDivElement>();
	const { showModal } = useModal();
	const { width, clearListener, isAboveTarget, fontSize } = useScreenSize();
	const prevData = useRef<CapTableData>();

	const isFullscreen = fullscreenMode === CapTableFullscreenMode.fullscreen;
	const isExpand = fullscreenMode === CapTableFullscreenMode.expand;

	const sortedData = useMemo(() => {
		let filteredData = [...data.columns];
		if (search) {
			const colsToFilter = filteredData.filter((col) => col.isSearchable);
			const idxToDisplay: number[] = colsToFilter.flatMap((col) =>
				col.cells.reduce((acc: number[], cell, idx: number) => {
					if (idx < col.cells.length - BOTTOM_OFFSET && cell.value?.toLowerCase().includes(search.toLocaleLowerCase())) {
						acc.push(idx);
					}
					return acc;
				}, [])
			);

			filteredData = filteredData.map((col) => ({
				...col,
				cells: col.cells.filter((cell, cellIdx) => {
					return cellIdx >= col.cells.length - BOTTOM_OFFSET || idxToDisplay.includes(cellIdx);
				}),
			}));
		}

		const initialFilter: CapTableFilteredData = {} as CapTableFilteredData;

		const filteredObjectsByType = filteredData.reduce((acc, col) => {
			if (col.type === CapTableColType.optionsAndWarrants || col.type === CapTableColType.fullyDiluted || col.type === CapTableColType.issuedShares) {
				return acc;
			}
			return {
				...acc,
				[col.id]: acc[col.id] ? [...acc[col.id as keyof CapTableFilteredData], col] : [col],
			};
		}, initialFilter);

		// console.clear();

		const colsById = Object.keys(filteredObjectsByType).map((key) => filteredObjectsByType[key]);

		// const pinCols = filteredData.filter((col) => pinnedCols.includes(col.id));
		// const unpinnedCols = filteredData
		// 	.slice(0, filteredData.length - 6)
		// 	.filter((col) => !pinnedCols.includes(col.id));

		return {
			classes: filteredData.filter((col) => col.type === CapTableColType.class),
			commonShares: filteredData.filter((col) => col.type === CapTableColType.commonShares),
			pinnedCols: colsById.filter((cols) => pinnedCols.includes(cols[0]?.id)),
			unpinnedCols: colsById.filter((cols) => !pinnedCols.includes(cols[0]?.id)),
			totalCols: [
				[filteredData[filteredData.length - 6], filteredData[filteredData.length - 5]],
				[filteredData[filteredData.length - 4], filteredData[filteredData.length - 3]],
				[filteredData[filteredData.length - 2], filteredData[filteredData.length - 1]],
			],
		};
	}, [data, pinnedCols, search]);

	useEffect(() => {
		// debounce after 300ms to set position (because of the fade)
		const setButtonPosition = setTimeout(() => {
			if (isNullOrUndefined(addClassRef.current)) return;

			const isScrollingHorizontal = sortedData.pinnedCols[0]?.[0]?.cells.length > 12;
			addClassRef.current.style.left = `calc(calc(100% - ${(totalsRef.current?.scrollWidth ?? 0) / fontSize}rem) - ${
				isScrollingVertical ? '2.4rem' : '2rem'
			})`;

			if (isNullOrUndefined(addShareHolderRef.current)) return;

			addShareHolderRef.current.style.left = `calc(${(shareholdersRef.current?.scrollWidth ?? 0) / 2 / fontSize}rem - ${
				isScrollingVertical ? '2.4rem' : '2rem'
			})`;
		}, 300);

		return () => clearTimeout(setButtonPosition);
	}, [width, showTotalOptionsAndWarrants, addClassRef.current, data, sortedData]);

	useEffect(() => {
		if (isNullOrUndefined(tableRef.current)) return;
		setIsScrollingHorizontal(tableRef.current.scrollWidth > tableRef.current.offsetWidth);
		setIsScrollingVertical(tableRef.current.scrollHeight > tableRef.current.offsetHeight);
	}, [width, tableRef.current, data]);

	useEffect(() => {
		if (
			(onAddEditRound || onUpdateClass) &&
			prevData.current &&
			!isPublishMode &&
			!isViewMode &&
			sortedData.classes.some((shareClass) => shareClass.isDisabled === false)
		) {
			scrollToRight();
		}

		if (prevData.current && prevData.current?.totalShareholders !== data.totalShareholders && tableRef.current) {
			tableRef.current.scrollTop = MAX_SCROLL;
		}
		const shCol = data.columns.find((col) => col.type === CapTableColType.shareholders);
		shCol && setPinnedCols([shCol.id]);

		prevData.current = data;
		// setShowCommonSharesDialog(false);
	}, [data]);

	useEffect(() => {
		return clearListener;
	}, []);

	const onShowCommonShares = () => {
		showModal({
			body: (
				<EditCommonShares
					minNumberOfShares={minNumberOfShares}
					numberOfRegisteredShares={numberOfRegisteredShares}
					onAddCommonShares={onAddCommonShares}
				/>
			),
			isFrameless: true,
			width: '44.2rem',
		});
	};

	const getBottomOffset = (cellIdx: number, length: number) => {
		return cellIdx >= length - BOTTOM_OFFSET ? `${((length - cellIdx - 1) * CAPTABLE_CELL_HEIGHT) / 10}rem` : 'inherit';
	};

	const getTopOffset = (cellIdx: number) => {
		return cellIdx < TOP_OFFSET ? `${(cellIdx * CAPTABLE_CELL_HEIGHT) / 10}rem` : 'inherit';
	};

	const getTableMinHeight = () => {
		if (data.totalShareholders <= 4) return 'unset';
		return `${(CAPTABLE_CELL_HEIGHT * MAX_ROWS) / 10 + (isScrollingHorizontal ? 0.4 : 0)}rem`;
	};

	const getTableHeight = () => {
		if (data.totalShareholders <= 4) return 'unset';
		if (isFullscreen) return 'calc(100vh - 21rem)';
		if (isExpand) return 'calc(calc(100vh - 21rem) - 10.5rem)';
		return getTableMinHeight(); // 0.8rem of scrollbar
	};

	const getBorderClasses = (border?: BorderStyle | false) => {
		if (!border) return `${MainTableStyle}__border-right ${MainTableStyle}__border-top`;
		let classes = '';
		if (border.left) classes += `${MainTableStyle}__border-left `;
		if (border.right) classes += `${MainTableStyle}__border-right `;
		if (border.top) classes += `${MainTableStyle}__border-top `;
		if (border.bottom) classes += `${MainTableStyle}__border-bottom`;
		return classes;
	};

	const scrollToRight = () => {
		setTimeout(() => {
			if ((!onAddEditRound && !onUpdateClass) || isNullOrUndefined(tableRef.current)) return;

			tableRef.current.scrollLeft = MAX_SCROLL;
		}, 300);
	};

	const memo = () => {
		let cache = {} as any;
		return (val: any) => {
			if (val in cache) return false;
			cache[val] = true;
			return true;
		};
	};
	const headerCachedRenders = memo();

	const renderNoteIcon = (colId: string, isCommon: boolean = false) =>
		isScrollingHorizontal ? (
			<Clickable
				qaid="CapTable.Button.Pin"
				onClick={() => {
					const isExists = pinnedCols.some((id) => id === colId);
					setPinnedCols((prev) => (isExists ? prev.filter((idx) => idx !== colId) : [...prev, colId]));
					if (!isCommon) {
						const commonCol = data.columns.find((col) => col.type === CapTableColType.commonShares);

						if (isExists) {
							commonCol && setPinnedCols((prevState) => prevState.filter((id) => id !== commonCol.id));
						} else {
							commonCol && setPinnedCols((prevState) => [...prevState, commonCol.id]);
						}
					} else {
						const optionCol = data.columns.find((col) => col.type === CapTableColType.options);

						if (isExists) {
							optionCol && setPinnedCols((prevState) => prevState.filter((id) => id !== optionCol.id));
						}
					}
				}}
				style={{
					transform: pinnedCols.includes(colId) ? 'rotate(-90deg)' : 'unset',
					position: 'absolute',
					cursor: 'pointer',
					top: '0.2rem',
					right: '0.8rem',
				}}
				flex={0}
				width="fit-content"
				ref={isCommon ? pinCommonRef : pinOptionsRef}
			>
				{/* {pinnedCols.includes(colId) ? "unpin" : "pin"} */}
				<img src={IC_PIN} alt="pin" className={`${MainTableStyle}__pin-icon`} />
			</Clickable>
		) : (
			<></>
		);

	const renderClassSubtitle = (cols: CapTableColumn[], noBorderRight: boolean = false) => {
		return cols.map((cs, idx) => (
			<Flex
				key={idx}
				className={classNames(`${MainTableStyle}__cell h-100`, getBorderClasses({ right: idx !== cols.length - 1 }), {
					[`${MainTableStyle}__border-right-thick`]: !noBorderRight && idx === cols.length - 1,
				})}
				style={{
					paddingRight: noBorderRight && idx < cols.length - 1 ? 2 : 0,
				}}
			>
				{cs.subtitle}
			</Flex>
		));
	};

	const renderHeader = (col: CapTableColumn) => {
		const { totalCols, classes, commonShares } = sortedData;
		switch (col.type) {
			case CapTableColType.shareholders:
				return (
					<div
						onClick={() => onSortBy?.(CapTableColType.shareholders)}
						className={classNames(
							`${MainTableStyle}__cell`,
							`${MainTableStyle}__sticky-top header`,
							`${MainTableStyle}__border-right`,
							`${MainTableStyle}__border-bottom-thick`,
							`${MainTableStyle}__top-header`,
							'pointer'
						)}
						style={{
							top: 0,
						}}
					>
						{col.title}
						{sortBy?.key === CapTableColType.shareholders && (sortBy?.isDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
					</div>
				);
			case CapTableColType.commonShares:
				const isCommonShareRendered = headerCachedRenders(CapTableColType.commonShares);
				if (isCommonShareRendered === false) return;

				return commonShares?.length === 1 ? (
					<div
						className={classNames(
							`${MainTableStyle}__cell`,
							`${MainTableStyle}__sticky-top header`,
							`${MainTableStyle}__border-right`,
							`${MainTableStyle}__border-bottom-thick`
						)}
						style={{
							top: 0,
						}}
					>
						{!isCommonSharesEmpty && !isPublishMode && onAddCommonShares && !isViewMode && (
							<Clickable
								qaid="CapTable.Link.AddCommonShares"
								style={{
									position: 'absolute',
									top: '1.4rem',
								}}
								onClick={onShowCommonShares}
								flex={0}
								width="fit-content"
							>
								<img src={IC_EDIT} alt="add options" className={`${MainTableStyle}__edit-icon`} />
							</Clickable>
						)}
						<Flex margin="1.2rem 0 0 0">{col.title}</Flex>
						{renderNoteIcon(col.id, true)}
					</div>
				) : (
					<div
						className={classNames(
							`${MainTableStyle}__cell`,
							`${MainTableStyle}__sticky-top header`,
							// `${MainTableStyle}__border-left-thick`,
							`${MainTableStyle}__border-bottom-thick`
						)}
						style={{
							top: 0,
						}}
					>
						<Flex direction="column" className="double-header-container">
							<Flex className={classNames(`${MainTableStyle}__border-right-thick`, `${MainTableStyle}__border-bottom`)} gap="0.8rem">
								<OverflowText className={classNames('text-ellipsis', `${MainTableStyle}__header-title`)}>{col.title}</OverflowText>
								{!isPublishMode && canEditCommon && !isViewMode && (
									<Clickable qaid="CapTable.Link.AddCommonShares" onClick={onShowCommonShares} flex={0} width="fit-content">
										<img src={IC_EDIT} alt="add common shares" className={`${MainTableStyle}__edit-icon`} />
									</Clickable>
								)}
							</Flex>

							<Flex>{renderClassSubtitle(commonShares.filter((cl) => cl.type === CapTableColType.commonShares))}</Flex>
						</Flex>
						{renderNoteIcon(col.id)}
					</div>
				);
			case CapTableColType.options:
				return (
					<div
						className={classNames(
							`${MainTableStyle}__cell`,
							`${MainTableStyle}__sticky-top header`,
							`${MainTableStyle}__border-right`,
							`${MainTableStyle}__border-bottom-thick`
						)}
						style={{
							top: 0,
						}}
					>
						{!isPublishMode && onAddOptions && !isViewMode && (
							<Clickable
								qaid="CapTable.Link.AddOptions"
								style={{ position: 'absolute', top: '1.4rem' }}
								onClick={onAddOptions}
								flex={0}
								width="fit-content"
							>
								<img src={IC_EDIT} alt="add options" className={`${MainTableStyle}__edit-icon`} />
							</Clickable>
						)}
						<OverflowText>
							<Flex margin="1.2rem 0 0 0">{col.title}</Flex>
						</OverflowText>
						{renderNoteIcon(col.id)}
					</div>
				);
			case CapTableColType.class:
				const isClassRendered = headerCachedRenders(col.shareClassInfo?.shareClassID);
				if (isClassRendered === false) return;
				const currentClass = classes.find((cl) => cl.shareClassInfo?.shareClassID === col.shareClassInfo?.shareClassID);
				return (
					<div
						className={classNames(`${MainTableStyle}__cell`, `${MainTableStyle}__sticky-top header`, `${MainTableStyle}__border-bottom-thick`, {
							// [`${MainTableStyle}__border-right`]: !isLastClass,
						})}
						style={{
							top: 0,
						}}
					>
						<Flex direction="column" height="100%">
							<Flex
								className={classNames(`${MainTableStyle}__cell`, `${MainTableStyle}__border-bottom`, `${MainTableStyle}__border-right-thick`)}
								align="center"
								justify="center"
								gap="1rem"
							>
								<OverflowText className={classNames('text-ellipsis', `${MainTableStyle}__header-title`)}>{col.title}</OverflowText>

								{(onUpdateClass || onAddEditRound) && !col.isDisabled && !isViewMode && (
									<Clickable
										flex={0}
										// className={`${MainTableStyle}__edit-btn`}
										qaid={isPublishMode ? 'CapTable.Link.ViewClass' : 'CapTable.Link.EditClass'}
										onClick={() =>
											onUpdateClass?.(currentClass?.shareClassInfo, isPublishMode) || onAddEditRound?.(col.shareClassInfo?.shareClassID)
										}
									>
										<Image
											width="2rem"
											src={isPublishMode ? IC_VIEW : IC_EDIT}
											alt={isPublishMode ? 'view mode' : 'edit class'}
											className={`${MainTableStyle}__edit-icon`}
										/>
									</Clickable>
								)}
							</Flex>

							<Flex>{renderClassSubtitle(classes.filter((cl) => cl.shareClassInfo?.shareClassID === col.shareClassInfo?.shareClassID))}</Flex>
						</Flex>
					</div>
				);
			case CapTableColType.issuedShares:
				const issuedSharesRes = headerCachedRenders(CapTableColType.issuedShares);
				if (issuedSharesRes === false) return;
				return (
					<div
						className={classNames(
							`${MainTableStyle}__cell`,
							`${MainTableStyle}__sticky-top header`,
							// `${MainTableStyle}__border-left-thick`,
							`${MainTableStyle}__border-bottom-thick`
						)}
						style={{
							top: 0,
						}}
					>
						<Flex direction="column" className="double-header-container bg-total">
							<Flex
								align="center"
								justify="center"
								className={classNames(`${MainTableStyle}__top-header`, 'clickable', getBorderClasses({ bottom: true }))}
								onClick={() => onSortBy?.(CapTableColType.issuedShares)}
							>
								{col.title}
								{sortBy?.key === CapTableColType.issuedShares && (sortBy?.isDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
								{!showTotalOptionsAndWarrants && (
									<div
										onClick={(e: SyntheticEvent) => {
											e.stopPropagation();
											setShowTotalOptionsAndWarrants(true);
										}}
										style={{
											position: 'absolute',
											cursor: 'pointer',
											top: 'calc(50% - 1.5rem)',
											right: -1,
											zIndex: 3,
											color: 'red',
										}}
									>
										<Image
											src={IC_CAPTABLE_ARROW_LEFT}
											alt="show total options and warrants"
											tooltip="Click to view the total options and warrants"
										/>
									</div>
								)}
							</Flex>
							<Flex className="h-100">
								{renderClassSubtitle(totalCols.map((cl) => cl.filter((cl) => cl.type === CapTableColType.issuedShares)).flat(), true)}
							</Flex>
						</Flex>
					</div>
				);
			case CapTableColType.optionsAndWarrants:
				const optionsAndWarrantsRes = headerCachedRenders(CapTableColType.optionsAndWarrants);
				if (optionsAndWarrantsRes === false) return;
				return (
					<div
						className={classNames(
							`${MainTableStyle}__cell`,
							`${MainTableStyle}__sticky-top header`,
							// `${MainTableStyle}__border-left-thick`,
							`${MainTableStyle}__border-bottom-thick`
						)}
						style={{
							top: 0,
						}}
					>
						<Flex direction="column" className="double-header-container bg-total">
							<Flex
								align="center"
								justify="center"
								className={classNames(`${MainTableStyle}__top-header`, 'clickable', getBorderClasses({ bottom: true }))}
								onClick={() => onSortBy?.(CapTableColType.optionsAndWarrants)}
							>
								{col.title}
								{sortBy?.key === CapTableColType.optionsAndWarrants && (sortBy?.isDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
								<div
									onClick={(e: SyntheticEvent) => {
										e.stopPropagation();
										setShowTotalOptionsAndWarrants(false);
									}}
									style={{
										position: 'absolute',
										cursor: 'pointer',
										top: 'calc(50% - 1.5rem)',
										left: -1,
										zIndex: 3,
										color: 'red',
									}}
								>
									<Image
										src={IC_CAPTABLE_ARROW_RIGHT}
										alt="hide total options and warrants"
										tooltip="Click to hide the total options and warrants"
									/>
								</div>
							</Flex>
							<Flex>
								{renderClassSubtitle(totalCols.map((cl) => cl.filter((cl) => cl.type === CapTableColType.optionsAndWarrants)).flat(), true)}
							</Flex>
						</Flex>
					</div>
				);
			case CapTableColType.fullyDiluted:
				const fullyDilutedRes = headerCachedRenders(CapTableColType.fullyDiluted);
				if (fullyDilutedRes === false) return;
				return (
					<div
						className={classNames(
							`${MainTableStyle}__cell`,
							`${MainTableStyle}__sticky-top header`,
							// `${MainTableStyle}__border-left-thick`,
							`${MainTableStyle}__border-bottom-thick`
						)}
						style={{
							top: 0,
						}}
					>
						<Flex direction="column" className="double-header-container bg-total">
							<Flex
								align="center"
								justify="center"
								className={classNames(`${MainTableStyle}__top-header`, 'clickable', getBorderClasses({ bottom: true }))}
								onClick={() => onSortBy?.(CapTableColType.fullyDiluted)}
							>
								{col.title}
								{sortBy?.key === CapTableColType.fullyDiluted && (sortBy?.isDesc ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
							</Flex>
							<Flex>{renderClassSubtitle(totalCols.map((cl) => cl.filter((cl) => cl.type === CapTableColType.fullyDiluted)).flat(), true)}</Flex>
						</Flex>
					</div>
				);
			default:
				return <></>;
		}
	};

	return (
		<div className={MainTableStyle}>
			<Flex className={`Captable__menu ${MainTableStyle}__actions`} height="fit-content" margin="0 0 1.4rem 0">
				{title?.()}
				{!!errors.length && (
					<div className={`${MainTableStyle}__errors-container`}>
						<Image
							width="4rem"
							pointer
							className={`${MainTableStyle}__tooltip`}
							src={IC_WARNING_INFO_CIRCLE}
							srcHover={IC_WARNING_INFO_CIRCLE_FILL}
							alt=""
						/>
						<div className="tooltip-modal">
							{errors.map((error) => (
								<div key={error.message}>
									<Image src={IC_QUESTION_MARK_DARK} />
									<div>{error.message}</div>
								</div>
							))}
						</div>
					</div>
				)}

				{onAddShareholder && !isViewMode && (
					<ExpandButton
						qaid="CapTable.Button.AddShareholder"
						label="Add shareholder"
						width="16rem"
						onClick={async () => {
							onAddShareholder({
								isCommonSharesEmpty,
								minNumberOfShares,
								onAddCommonShares,
							});
						}}
						disabled={isPublishMode}
						ref={(el: HTMLDivElement) => (addShareHolderRef.current = el)}
						style={{
							position: 'absolute',
							top: 'unset',
							transition: 'all .5s',
						}}
						tooltip={isPublishMode ? 'No changes can be made on the published version, save as draft to start editing' : undefined}
					/>
				)}
				{onUpdateClass && !isViewMode && (
					<ExpandButton
						qaid="CapTable.Button.AddClass"
						label="Add class"
						onClick={onUpdateClass}
						disabled={isPublishMode}
						width="11.5rem"
						ref={(el: HTMLDivElement) => (addClassRef.current = el)}
						style={{
							position: 'absolute',
							top: 'unset',
							transition: 'all .5s',
						}}
						tooltip={isPublishMode ? 'No changes can be made on the published version, save as draft to start editing' : undefined}
					/>
				)}
				{onAddEditRound && !isViewMode && (
					<ExpandButton
						qaid="CapTable.Button.AddRound"
						label="Add funding class"
						onClick={onAddEditRound}
						disabled={isPublishMode}
						width="17rem"
						ref={(el: HTMLDivElement) => (addClassRef.current = el)}
						style={{
							position: 'absolute',
							top: 'unset',
							transition: 'all .5s',
						}}
					/>
				)}
				<Flex justify="end" align="center" gap="2rem" margin={0}>
					{!isSearchDisabled && (
						<TextInput
							qaid="CapTable.Input.Search"
							value={search}
							onChange={(val) => setSearch(val)}
							placeholder="Search in table"
							endIcon={IC_LOOKUP}
							disabled={!data.totalShareholders}
							width="28rem"
							containerClassName="mb-0"
						/>
					)}
					{onFullscreen && (
						<Clickable
							qaid="CapTable.Fullscreen.Expand"
							flex="0 0 3.6rem"
							onClick={() => onFullscreen(isFullscreen ? CapTableFullscreenMode.none : CapTableFullscreenMode.fullscreen)}
							className={`${MainTableStyle}__expand-toggle`}
							width="3.6rem"
							height="3.6rem"
						>
							<Image
								tooltip={isFullscreen ? 'Exit full screen' : 'Full screen'}
								src={isFullscreen ? IC_FULLSCREEN_SHRINK : IC_FULLSCREEN_EXPAND}
								alt={`fullscreen ${isFullscreen ? 'off' : 'on'}`}
							/>
						</Clickable>
					)}
				</Flex>
			</Flex>
			<div
				className={classNames(`${MainTableStyle}__table-wrapper`, {
					fullscreen: isFullscreen,
				})}
			>
				<Table
					className={classNames(`${MainTableStyle}__table`, {
						fs: isFullscreen,
					})}
					dir="column"
					style={{ maxHeight: getTableHeight(), minHeight: getTableMinHeight() }}
					ref={tableRef}
					isFullscreen={isFullscreen}
				>
					<div
						className={`${MainTableStyle}__sticky-left`}
						style={{
							boxShadow: appConfig.style.boxShadow.right,
							flex: sortedData.pinnedCols.reduce((acc, arr) => acc + arr.length, 0),
						}}
					>
						{sortedData.pinnedCols.map((col, colIdx) => (
							// <Collapse
							// 	in={colIdx === 1 ? showTotalOptionsAndWarrants : true}
							// 	orientation="horizontal"
							// 	key={colIdx}
							// 	unmountOnExit
							// 	timeout={500}
							// 	style={{ position: "relative", flex: col.length }}
							// >
							<Flex direction="column" className={classNames(`${MainTableStyle}__col`, { wide: !colIdx })} key={colIdx} flex={col.length}>
								{renderHeader(col[0])}
								<Flex>
									{col.map((innerCol, innerColIdx) => (
										<div
											data-captable-qaid={innerCol.colQaid}
											key={`${innerColIdx} ${colIdx}`}
											className={classNames(`${MainTableStyle}__col`)}
											ref={(el: HTMLDivElement) => {
												if (innerCol.type !== CapTableColType.shareholders) return;
												shareholdersRef.current = el;
											}}
										>
											{innerCol.cells.map((cell, cellIdx) => (
												<div
													key={cellIdx}
													className={classNames(
														`${MainTableStyle}__cell`,
														`cap-table-row-${cellIdx + 2}`,
														'text-ellipsis',
														getBorderClasses({
															top: !!cellIdx,
														}),
														`${MainTableStyle}__border-right`,
														{
															[`${MainTableStyle}__sticky-bottom`]: cellIdx >= innerCol.cells.length - BOTTOM_OFFSET,
															[`${MainTableStyle}__cell-total bold`]: cellIdx === innerCol.cells.length - 1,
															clickable: !!cell.onClick,
															[`${MainTableStyle}__border-top-thick`]:
																data.totalShareholders && cellIdx === innerCol.cells.length - BOTTOM_OFFSET,
															'to-left': !colIdx,
														}
													)}
													style={{
														top: getTopOffset(cellIdx),
														bottom: getBottomOffset(cellIdx, innerCol.cells.length),
														// borderTopLeftRadius: !cellIdx ? "1.6rem" : "0",
														// borderBottomLeftRadius:
														// 	cellIdx === innerCol.cells.length - 1
														// 		? "1.6rem"
														// 		: "0",
													}}
													onClick={cell.onClick}
												>
													{cell.render ? cell.render() : cell.value !== '0' ? cell.value : ''}
												</div>
											))}
										</div>
									))}
								</Flex>
							</Flex>
							// </Collapse>
						))}
					</div>
					<div
						className={`${MainTableStyle}__scroll`}
						style={{
							flex: sortedData.unpinnedCols.reduce((acc, arr) => acc + arr.length, 0),
						}}
					>
						{sortedData.unpinnedCols.map((col, colIdx) => (
							// <Collapse
							// 	in={colIdx === 1 ? showTotalOptionsAndWarrants : true}
							// 	orientation="horizontal"
							// 	key={colIdx}
							// 	unmountOnExit
							// 	timeout={500}
							// 	style={{ position: "relative", flex: col.length }}
							// >
							<Flex direction="column" className={`${MainTableStyle}__border-left`} key={colIdx}>
								{renderHeader(col[0])}
								<Flex>
									{col.map((innerCol, innerColIdx) => (
										<div
											data-captable-qaid={innerCol.colQaid}
											key={`${innerColIdx} ${colIdx}`}
											className={classNames(`${MainTableStyle}__col`)}
										>
											{innerCol.cells.map((cell, cellIdx) => (
												<div
													key={cellIdx}
													className={classNames(
														`${MainTableStyle}__cell`,
														`cap-table-row-${cellIdx + 2}`,
														'text-ellipsis',
														getBorderClasses({
															top: !!cellIdx,
															right: true,
														}),
														{
															[`${MainTableStyle}__sticky-bottom`]: cellIdx >= innerCol.cells.length - BOTTOM_OFFSET,
															[`${MainTableStyle}__cell-total bold`]: cellIdx === innerCol.cells.length - 1,
															clickable: !!cell.onClick,
															[`${MainTableStyle}__border-top-thick`]:
																data.totalShareholders && cellIdx === innerCol.cells.length - BOTTOM_OFFSET,
														}
													)}
													style={{
														top: getTopOffset(cellIdx),
														bottom: getBottomOffset(cellIdx, innerCol.cells.length),
													}}
													onClick={cell.onClick}
												>
													{cell.render ? cell.render() : cell.value !== '0' ? cell.value : ''}
												</div>
											))}
										</div>
									))}
								</Flex>
							</Flex>
							// </Collapse>
						))}
					</div>
					<div
						className={`${MainTableStyle}__sticky-right`}
						style={{
							flex: showTotalOptionsAndWarrants ? 6 : 4,
						}}
						ref={totalsRef}
					>
						{sortedData.totalCols.map((col, colIdx) => (
							<Fade
								in={colIdx === 1 ? showTotalOptionsAndWarrants : true}
								// orientation="horizontal"
								key={colIdx}
								unmountOnExit
								// timeout={500}
								style={{ flex: col.length }}
							>
								<Flex direction="column" className={`${MainTableStyle}__border-left-thick`}>
									{renderHeader(col[0])}
									<Flex>
										{col.map((innerCol, innerColIdx) => (
											<div
												data-captable-qaid={innerCol.colQaid}
												key={`${innerColIdx} ${colIdx}`}
												className={classNames(`${MainTableStyle}__col`)}
											>
												{innerCol.cells.map((cell, cellIdx) => (
													<div
														key={cellIdx}
														className={classNames(
															`${MainTableStyle}__cell`,
															`cap-table-row-${cellIdx + 2}`,
															'text-ellipsis',
															getBorderClasses({
																top: !!cellIdx,
																left: !!innerColIdx,
															}),
															{
																[`${MainTableStyle}__sticky-bottom`]: cellIdx >= innerCol.cells.length - BOTTOM_OFFSET,
																[`${MainTableStyle}__cell-total`]:
																	cellIdx === innerCol.cells.length - 1 ||
																	(colIdx === sortedData.totalCols.length - 1 && innerColIdx === 1),
																bold: cellIdx === innerCol.cells.length - 1,
																clickable: !!cell.onClick,
																[`${MainTableStyle}__border-top-thick`]:
																	data.totalShareholders && cellIdx === innerCol.cells.length - BOTTOM_OFFSET,
																// [`${MainTableStyle}__border-left-thick`]:
																// 	!innerColIdx,
															}
														)}
														style={{
															top: getTopOffset(cellIdx),
															bottom: getBottomOffset(cellIdx, innerCol.cells.length),
														}}
														onClick={cell.onClick}
													>
														{cell.render ? cell.render() : cell.value !== '0' ? cell.value : ''}
													</div>
												))}
											</div>
										))}
									</Flex>
								</Flex>
							</Fade>
						))}
					</div>
				</Table>
			</div>
		</div>
	);
};

export default MainCapTable;
