import { css } from '@emotion/css';
import { ClickAwayListener } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import {
	IC_ATTACHMENT,
	IC_DATA_COLLECTION_GREY,
	IC_DATA_COLLECTION_PURPLE,
	IC_DOC_PDF,
	IC_DOC_PDF_GREY,
	IC_EDIT2,
	IC_EYE2,
	IC_INFO_PRIMARY,
	IC_INFO_PRIMARY_LIGHT,
} from '../../../../Assets';
import appConfig from '../../../../config/config';
import { ReportType } from '../../../../Models/API/DataCollection/project-preview';
import { UploadReportPayload } from '../../../../Models/API/DataCollection/upload-report-payload';
import { FourONinePermission } from '../../../../Models/API/UsersAndPermissions/permissions-enum';
import { ValuationFormSteps } from '../../../../Models/API/Valuation/steps-enum';
import { ValuationProject } from '../../../../Models/API/Waterfall/IForm';
import { InputRef } from '../../../../Models/App/Inputs/types';
import { Routes } from '../../../../Routes';
import Button from '../../../../Shared/Components/Button/Button';
import Image from '../../../../Shared/Components/Image';
import NumberInput from '../../../../Shared/Components/Input/NumberInput';
import Flex from '../../../../Shared/Components/Layout/Flex';
import Title from '../../../../Shared/Components/Layout/Title';
import { ModalBodyProps } from '../../../../Shared/Components/Modal/types';
import OverflowText from '../../../../Shared/Components/OverflowText';
import StyledSelect from '../../../../Shared/Components/Select/Select.Style';
import Spinner from '../../../../Shared/Components/Spinner/Spinner';
import UnreadMessages from '../../../../Shared/Components/UnreadMessagesIcon';
import { acceptByType } from '../../../../Shared/Components/UploadFile';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';
import useModal from '../../../../Shared/Hooks/useModal';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import ScrollableList from '../../../../Shared/ScrollableList/index.style';
import { fileToBase64, isNullOrUndefined, isNumber, onOpenURL } from '../../../../Shared/Utilities';
import ProjectStatus from '../../../DataCollection/Components/ProjectStatus';
import { getDataCollectionReports, getDataCollectionStatus, getStatusTitle } from '../../../DataCollection/helpers/utils';
import { sortProjectsListWithChildren } from '../../utils';
import { useDataCollectionModal } from '../../../DataCollection/helpers/useDataCollectionModal';

const Style = css({
	label: 'ProjectsList',

	'&__row': {
		'&.parent': {
			'&:hover': {
				'.valuator-name': {
					cursor: 'pointer',
					color: appConfig.style.colors.color1,
					textDecoration: 'underline',
				},
			},
		},
	},
	'&__project-name': {
		'&:hover': {
			transition: 'all .2s',
			cursor: 'pointer',
			color: appConfig.style.colors.color1,
			textDecoration: 'underline',
		},
	},
	'.selected': {
		color: appConfig.style.colors.color1,
	},
});

const ModalStyle = css({
	label: 'FairMarketValueModal',
	padding: '6rem 7.7rem',
	display: 'flex',
	flexDirection: 'column',
	gap: '2rem',
	'&__actions': {
		display: 'flex',
		gap: '2rem',
		justifyContent: 'flex-end',
		marginTop: '0.6rem',
	},
});

const EnterVolatilityModal = (props: ModalBodyProps & { valuationProjectId: string }) => {
	const { valuationStore } = useRootStore();
	const [fmf, setFmf] = useState<number>();

	const onSubmit = () => {
		if (!fmf) return;
		valuationStore.updateFairMarketValue(fmf, props.valuationProjectId);
		props.removeModal?.();
	};

	return (
		<div className={ModalStyle}>
			<Title type="secondary">Fair Market Value</Title>
			<div>
				Please enter the common share's Fair Market Value (after discount) as presented on the valuation document (the figure will appear on the
				customer page)
			</div>
			<NumberInput qaid="FairMarketValue.Input.FMV" label="Enter FMV" value={fmf} onChange={(value) => setFmf(value)} number="float" />
			<div className={`${ModalStyle}__actions`}>
				<Button qaid="FairMarketValue.Button.Cancel" cancel onClick={props.removeModal} label="Cancel" />
				<Button qaid="FairMarketValue.Button.Done" onClick={onSubmit} label="Done" disabled={!fmf} />
			</div>
		</div>
	);
};

const ProjectsList = () => {
	const {
		valuationStore,
		appState,
		dataCollectionStore,
		auth: { currentUserInfo },
	} = useRootStore();
	const history = useHistory();
	const headerRef = useRef<HTMLDivElement>(null);
	const [visibleMenuIdx, setVisibleMenuIdx] = useState<number | null>(null);
	const [menuPosition, setMenuPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
	const containerRef = useRef<HTMLDivElement>(null);
	const inputRef = createRef<HTMLInputElement>();
	const attachPayloadRef = useRef<UploadReportPayload>({} as UploadReportPayload);
	const { showErrorModal } = useGeneralModal();
	const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
	const { showModal } = useModal();
	const { onOpenGeneralChat } = useDataCollectionModal();

	useEffect(() => {
		if ((valuationStore.projects || []).length < 4 || !headerRef.current) return;
		headerRef.current.style.paddingRight = '6px';
	}, [valuationStore.projects]);

	useEffect(() => {
		if (containerRef.current === null) return;
		const onScrollHandler = () => {
			setVisibleMenuIdx(null);
		};
		containerRef.current.addEventListener('scroll', onScrollHandler);
		return () => {
			containerRef.current?.removeEventListener('scroll', onScrollHandler, true);
		};
	}, [containerRef.current]);

	const toggleMenu = (id: number, event: React.MouseEvent<HTMLDivElement>) => {
		if (visibleMenuIdx === id) {
			setVisibleMenuIdx(null);
		} else {
			const rect = (event.target as HTMLElement).getBoundingClientRect();
			setMenuPosition({
				top: rect.bottom + window.scrollY + 10,
				left: rect.left + window.scrollX,
			});
			setVisibleMenuIdx(id);
		}
	};

	const onNavToClientProject = (project: ValuationProject) => {
		if (project.valuationProjectId === null) return;
		dataCollectionStore.valuationProjectId = project.valuationProjectId;
		// dataCollectionStore.currentStatus = getDataCollectionStatus(project.status).currentStatus;
		history.push(`${Routes.dataCollection.form}/${project.valuationProjectId}/0`);
	};

	const onOpen = async (project: ValuationProject, maxStep: ValuationFormSteps = ValuationFormSteps.preferencesTerms) => {
		if (appState.isLoading || project.waterfallId === null) return;

		const isOwner = currentUserInfo.contactId === project.valuatorContactId;

		const lastAllowedStep =
			isOwner || (!isOwner && project.unfulfillmentStep - 1 !== ValuationFormSteps.summary)
				? project.unfulfillmentStep - 1
				: project.unfulfillmentStep - 2;
		const step = Math.min(lastAllowedStep, maxStep);

		appState.isLoading = true;
		try {
			valuationStore.setLastAllowedStep(lastAllowedStep);
			const path = `${Routes.dataCollection.valuation}/${project.waterfallId}${
				step ? `/${step}` : valuationStore.lastAllowedStep ? `/${valuationStore.lastAllowedStep}` : ''
			}`;
			valuationStore.setProjectName(project.projectName);
			valuationStore.isNewProject = false;
			// to check if owner
			valuationStore.setProjectViewMode(!isOwner);
			// setShowVersionsDialog(false);
			history.push(path);
		} catch (error: any) {
			console.log(error);
		}
		appState.isLoading = false;
	};

	const onUploadDocument = (valuationProjectId: string | null, type: ReportType) => {
		if (valuationProjectId === null) return;
		if (valuationProjectId === null || isLoading[valuationProjectId]) return;
		inputRef?.current?.click();
		attachPayloadRef.current.reportType = type;
		attachPayloadRef.current.valuationProjectId = valuationProjectId;
		attachPayloadRef.current.fileBase64 = '';
	};

	const onFileUploadHandler = async (file: File | undefined) => {
		if (isNullOrUndefined(file)) return;

		const isFileSupported = acceptByType.pdf.includes(file.type);
		if (!isFileSupported) return showErrorModal('File not supported, please upload a PDF file only.');
		attachPayloadRef.current.fileName = file.name;

		const fileBase64 = await fileToBase64(file);
		if (fileBase64 === undefined) return;
		attachPayloadRef.current.fileBase64 = fileBase64;
		setIsLoading((prev) => ({ ...prev, [attachPayloadRef.current.valuationProjectId]: true }));
		await valuationStore.uploadReport(attachPayloadRef.current);
		setIsLoading((prev) => ({ ...prev, [attachPayloadRef.current.valuationProjectId]: false }));

		showModal({
			body: <EnterVolatilityModal valuationProjectId={attachPayloadRef.current.valuationProjectId} />,
			isFrameless: true,
			width: '54rem',
		});
	};

	const areProjectsExists = !!valuationStore.projects?.length;
	const sortedProject = useMemo(() => sortProjectsListWithChildren(valuationStore.projects || []), [valuationStore.projects]);

	return (
		<>
			<ScrollableList.Container className={Style}>
				<ScrollableList.Row isHeader ref={headerRef}>
					<ScrollableList.Cell style={{ minWidth: '20rem' }}>Project Name</ScrollableList.Cell>
					<ScrollableList.Cell>Data collection</ScrollableList.Cell>
					<ScrollableList.Cell data-col-type="status">Project status</ScrollableList.Cell>
					<ScrollableList.Cell>Valuator</ScrollableList.Cell>
					<ScrollableList.Cell data-col-type="model">409A Model</ScrollableList.Cell>
					<ScrollableList.Cell data-col-type="drafts">Drafts</ScrollableList.Cell>
					<ScrollableList.Cell data-col-type="final">Final Report</ScrollableList.Cell>
					<ScrollableList.Cell>Chat with analyst</ScrollableList.Cell>
				</ScrollableList.Row>
				<ScrollableList.Scroll ref={containerRef}>
					{isNullOrUndefined(areProjectsExists) ? (
						<Spinner />
					) : areProjectsExists ? (
						sortedProject?.map((project, idx) => {
							const reports = getDataCollectionReports(project.reports || []);
							const { currentStatus, statusList } = getDataCollectionStatus(project.status);
							const isChild = isNumber(project.parentWaterfallId);
							const isParent =
								(sortedProject[idx + 1]?.parentWaterfallId === project.waterfallId ||
									isNullOrUndefined(sortedProject[idx + 1]?.parentWaterfallId)) &&
								project.parentWaterfallId === null;
							return (
								<ScrollableList.Row
									isChild={isChild}
									isParent={isParent}
									key={idx}
									className={classNames(`${Style}__row`, { parent: isParent })}
								>
									<ScrollableList.Cell
										onClick={() => onNavToClientProject(project)}
										className={classNames('pointer', `${Style}__project-name`)}
										style={{ minWidth: '20rem' }}
									>
										<OverflowText>{project.projectName}</OverflowText>
									</ScrollableList.Cell>
									<ScrollableList.Cell>
										{isParent && (
											<UnreadMessages
												count={project.unreadMessages}
												icons={[IC_DATA_COLLECTION_GREY, IC_DATA_COLLECTION_PURPLE]}
												onClick={() => onNavToClientProject(project)}
												width="3rem"
											/>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell data-col-type="status">
										{isParent && (
											<>
												<span style={{ marginRight: '1rem' }}>{getStatusTitle(currentStatus)}</span>
												<Image
													pointer
													src={IC_INFO_PRIMARY_LIGHT}
													srcHover={IC_INFO_PRIMARY}
													tooltip={<ProjectStatus statusList={statusList} currentStatus={currentStatus} />}
													tooltipPlacement="top"
												/>
											</>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell
										data-col-type="valuator-name"
										onClick={(e) => valuationStore.currentRole === FourONinePermission.Chief && toggleMenu(idx, e)}
									>
										{isParent && (
											<>
												<OverflowText
													className={classNames({ 'valuator-name': valuationStore.currentRole === FourONinePermission.Chief })}
												>
													{project.valuatorFullName}
												</OverflowText>
												{visibleMenuIdx === idx &&
													ReactDOM.createPortal(
														<div>
															<ClickAwayListener onClickAway={() => setVisibleMenuIdx(null)}>
																<StyledSelect.OptionsContainer
																	style={{
																		top: menuPosition.top,
																		left: menuPosition.left,
																		maxWidth: '15rem',
																	}}
																>
																	{valuationStore.economicUsers.map((user) => (
																		<StyledSelect.Option
																			className={classNames({ selected: user.defaultName === project.valuatorFullName })}
																			onClick={() =>
																				project.valuationProjectId &&
																				valuationStore.changeProjectOwner(user.userId, project.valuationProjectId)
																			}
																			key={user.userId}
																		>
																			<span className="text-ellipsis">{user.defaultName}</span>
																		</StyledSelect.Option>
																	))}
																</StyledSelect.OptionsContainer>
															</ClickAwayListener>
														</div>,
														document.body
													)}
											</>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell className="flex align-center gap-2" data-col-type="model">
										{isNumber(project.waterfallId) && (
											<>
												{project.unfulfillmentStep - 1 === ValuationFormSteps.summary ? (
													<Image
														src={IC_EYE2}
														height="1.3rem"
														className="pointer"
														onClick={() => onOpen(project, ValuationFormSteps.summary)}
														tooltip="View"
													/>
												) : (
													<div style={{ width: '2.5rem' }}></div>
												)}
												{currentUserInfo.contactId === project.valuatorContactId && (
													<Image
														src={IC_EDIT2}
														height="1.6rem"
														className="pointer"
														onClick={() => onOpen(project, ValuationFormSteps.preferencesTerms)}
														tooltip="Edit"
													/>
												)}
											</>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell data-col-type="drafts">
										{/* {reports.drafts?.map((draft) => (
										<div>{draft.id}</div>
									))} */}
										{isParent && (
											<Flex justify="start" gap="1.2rem">
												{reports.drafts?.map((draft) => (
													<Image
														src={IC_DOC_PDF_GREY}
														width="2.8rem"
														tooltip="Preview"
														key={draft.id}
														onClick={() => onOpenURL(draft.filePath)}
													/>
												))}

												{project.valuationProjectId !== null && (
													<Image
														src={IC_ATTACHMENT}
														width="2.8rem"
														tooltip="Attach"
														onClick={() => onUploadDocument(project.valuationProjectId, ReportType.Draft)}
														className={classNames({ disabled: isLoading[project.valuationProjectId] })}
													/>
												)}
											</Flex>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell data-col-type="final">
										{isParent && (
											<Flex justify="start" gap="1.2rem">
												{!!reports.final?.id && (
													<Image
														src={IC_DOC_PDF}
														width="2.8rem"
														tooltip="Preview"
														onClick={() => onOpenURL(reports.final!.filePath)}
													/>
												)}
												{project.valuationProjectId !== null && (
													<Image
														src={IC_ATTACHMENT}
														width="2.8rem"
														tooltip="Attach"
														onClick={() => onUploadDocument(project.valuationProjectId, ReportType.Final)}
														className={classNames({ disabled: isLoading[project.valuationProjectId] })}
													/>
												)}
											</Flex>
										)}
									</ScrollableList.Cell>
									<ScrollableList.Cell>
										{project.valuationProjectId !== null && (
											<UnreadMessages
												count={project.unreadMessagesGeneral}
												onClick={() => project.valuationProjectId !== null && onOpenGeneralChat(project.valuationProjectId)}
												width="3rem"
											/>
										)}
									</ScrollableList.Cell>
								</ScrollableList.Row>
							);
						})
					) : (
						<ScrollableList.Row>No data to display</ScrollableList.Row>
					)}
				</ScrollableList.Scroll>
			</ScrollableList.Container>
			<input
				ref={inputRef}
				id="fileUpload"
				className="box__file"
				hidden
				type="file"
				data-multiple-caption="{count} files selected"
				onChange={(e) => onFileUploadHandler(e.target.files?.[0])}
				multiple={false}
				accept={acceptByType.pdf}
			/>
		</>
	);
};

export default observer(ProjectsList);
