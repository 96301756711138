import React, { useEffect, useRef, useState } from 'react';
import Flex from '../../../../Shared/Components/Layout/Flex';
import { CardInformationStyle } from './CardInformation.Style';
import { CardNumberElement, useElements, useStripe, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import { observer } from 'mobx-react-lite';
import TextInput from '../../../../Shared/Components/Input/TextInput';
import CheckBox from '../../../../Shared/Components/CheckBox/CheckBox';
import Button from '../../../../Shared/Components/Button/Button';
import useScreenSize from '../../../../Shared/Hooks/useScreenSize';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { ICardInfo, ICheckout, PaymentMethod } from '../../../../Models/App/PaymentModels';
import {
	Stripe,
	StripeCardCvcElementChangeEvent,
	StripeCardExpiryElementChangeEvent,
	StripeCardNumberElement,
	StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { AppendStateInputHandlerType, useAppendState } from '../../../../Shared/Hooks/useAppendState';
import { InputRefs, InputValidationRef, useFormValidation } from '../../../../Shared/Hooks/useFormValidation';
import { ObjectValidationStatus, commonValidators } from '../../../../Shared/ObjectValidator';
import useModal from '../../../../Shared/Hooks/useModal';
import { REGEX_ONLY_LETTERS_SPACES, isNullOrUndefined } from '../../../../Shared/Utilities';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../Routes';
import { UserStatus } from '../../../../Models/API/enums';
import Image from '../../../../Shared/Components/Image';
import { IC_PLUS_PURPLE, IMG_STRIPE_BADGE, IMG_STRIPE_CARDS_TYPE, IMG_STRIPE_PCI_BADGE } from '../../../../Assets';
import Title from '../../../../Shared/Components/Layout/Title';
import SelectableCard from '../../../Account/CompanyProfile/CardInfo/SelectableCard';
import useRecaptcha from '../../../../Shared/Hooks/useRecaptcha';
import useGeneralModal from '../../../../Shared/Hooks/useGeneralModal';

interface IProps {
	readonly flex?: number;
	readonly checkoutInfo: ICheckout;
	readonly formValidationState: ObjectValidationStatus<ICheckout> | null | undefined;
	readonly inputRefs: InputRefs<ICheckout>;
	readonly onInputHandler: AppendStateInputHandlerType;
	readonly onPayHandler: () => any;
	paymentMethod?: PaymentMethod;
	showSuccessModal?: boolean;
}

const CardInformation = (props: IProps) => {
	const { showModal, clearModals } = useModal();
	const { isMobile } = useScreenSize();
	const history = useHistory();
	const { auth, paymentStore } = useRootStore();
	const stripe = useStripe();
	const stripeElements = useElements();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [cardNumberElementComplete, setCardNumberElementComplete] = useState(false);
	const [cardExpiryElementComplete, setCardExpiryElementComplete] = useState(false);
	const [cardCvcElementComplete, setCardCvcElementComplete] = useState(false);
	const { showErrorModal } = useGeneralModal();
	const [uniq, setUniq] = useState<string>();

	const [checkoutError, setCheckoutError] = useState<string | null>(null);
	const [cardExpirationError, setCardExpirationError] = useState<string | null>(null);
	const [cardCvcError, setCardCvcError] = useState<string | null>(null);
	const { execute } = useRecaptcha();

	const [cardInfo, _, onInputHandler] = useAppendState<ICardInfo>({} as ICardInfo);
	const inputRefs = useRef<InputRefs<ICardInfo>>({} as InputRefs<ICardInfo>);
	const [isNewPayment, setIsNewPayment] = useState<boolean>(!props.paymentMethod);

	const { formValidationState } = useFormValidation({
		form: cardInfo,
		schema: {
			cardHolderName: [
				commonValidators.required(),
				commonValidators.regex(REGEX_ONLY_LETTERS_SPACES, 'Only letters or space allowed'),
				commonValidators.minLength(2),
			],
		},
		refs: inputRefs.current,
	});

	useEffect(() => {
		if (stripe && stripeElements) {
			const cardNumberElement = stripeElements.getElement(CardNumberElement);
			cardNumberElement?.on('change', function (event: any) {
				setCardNumberElementComplete(event.complete);
			});

			const cardExpiryElement = stripeElements.getElement(CardExpiryElement);
			cardExpiryElement?.on('change', function (event: any) {
				setCardExpiryElementComplete(event.complete);
			});

			const cardCvcElement = stripeElements.getElement(CardCvcElement);
			cardCvcElement?.on('change', function (event: any) {
				setCardCvcElementComplete(event.complete);
			});
		}
		return () => {
			// paymentStore.getCurrentPlan().then((res) => {
			// 	if (res.data) paymentStore.currentPlan = res.data;
			// });
		};
	}, [stripe, stripeElements, isNewPayment]);

	const handleCardNumberChange = (event: StripeCardNumberElementChangeEvent) => {
		event.error ? setCheckoutError(event.error.message) : setCheckoutError(null);
	};

	const handleCardExpirationChange = (event: StripeCardExpiryElementChangeEvent) => {
		event.error ? setCardExpirationError(event.error.message) : setCardExpirationError(null);
	};

	const handleCardCvcChange = (event: StripeCardCvcElementChangeEvent) => {
		event.error ? setCardCvcError(event.error.message) : setCardCvcError(null);
	};

	const OPTIONS = {
		style: {
			base: {
				fontSize: '12px',
				color: '#424770',
				letterSpacing: '0.025em',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#9e2146',
			},
		},
	};

	const navigateTpDashboard = async () => {
		const res = await auth.updateUserStatus(UserStatus.Active);

		if (res.isSuccess) {
			auth.handleUserData(UserStatus.Active);

			isMobile ? history.push(`${Routes.dashboard.index}?mobile-first-reg`) : history.push(Routes.dashboard.index);
		}
	};

	const showSuccessModal = () => {
		setIsLoading(false);

		showModal({
			type: 'success',
			timeout: 3000,
			width: '40rem',
			height: '25rem',
			showProgressBar: false,
			body: () => (
				<Flex direction="column">
					<span>Your payment has been made</span>
				</Flex>
			),
			onConfirm: () => {
				navigateTpDashboard();
				clearModals();
			},
		});
	};

	// const showErrorModal = (message?: string) => {
	// 	setIsLoading(false);

	// 	showModal({
	// 		type: 'error',
	// 		width: '44.5rem',
	// 		title: 'Sorry',
	// 		body: () => (
	// 			<Flex direction="column">
	// 				<span>Something went wrong...</span>
	// 				{message && <span>{message}</span>}
	// 			</Flex>
	// 		),
	// 		onCancel: () => {},
	// 	});
	// };

	const updateStripeCard = async (stripe: Stripe, cardElement: StripeCardNumberElement, uniq: string): Promise<boolean> => {
		try {
			const paymentMethodReq = await stripe.confirmCardSetup(uniq, {
				payment_method: {
					card: cardElement,
					billing_details: {
						name: cardInfo.cardHolderName,
					},
				},
			});

			if (paymentMethodReq.error) {
				showErrorModal(paymentMethodReq.error.message);

				return false;
			}
			return true;
		} catch (err: any) {
			showErrorModal();
			return true;
		}
	};

	const newStripeCard = async (stripe: Stripe, cardElement: StripeCardNumberElement, uniq: string): Promise<boolean> => {
		try {
			const paymentMethodReq = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: { name: cardInfo.cardHolderName },
			});

			if (paymentMethodReq.error) {
				showErrorModal(paymentMethodReq.error.message);

				return false;
			}

			const confirmedCardPayment = await stripe.confirmCardPayment(uniq, {
				payment_method: paymentMethodReq.paymentMethod.id,
			});

			if (confirmedCardPayment.error) {
				showErrorModal(confirmedCardPayment.error.message);

				return false;
			}
			return true;
		} catch (err: any) {
			showErrorModal();
			return false;
		}
	};

	const onPayHandler = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault();
		try {
			if (!stripe || !stripeElements) return;

			const isUpdateCard = isNewPayment && props.paymentMethod;
			const isNewCard = isNewPayment && !props.paymentMethod;

			setIsLoading(true);
			const cardElement = stripeElements.getElement(CardNumberElement);

			if (isUpdateCard) {
				if (!cardElement) {
					return;
				}
				await execute(async (token) => {
					const cardRes = await paymentStore.updateCustomerCard(token);

					if (!cardRes.isSuccess || !cardRes.data.uniq) {
						throw 'There was an error updating the credit card';
					}

					const isAdded = await updateStripeCard(stripe, cardElement, cardRes.data.uniq);
					if (!isAdded) throw 'An error has occurred';
				});
			}

			if (!uniq) {
				const res = await props.onPayHandler();

				if (res?.uniq) setUniq(res.uniq);
				else if (isNewCard) throw undefined;

				if (cardElement && res?.uniq) {
					const isAdded = await newStripeCard(stripe, cardElement, res.uniq);
					if (!isAdded) return;
				}
			} else {
				if (!uniq && isNewCard) {
					throw undefined;
				}

				if (isNewCard && cardElement && uniq) {
					const isAdded = await newStripeCard(stripe, cardElement, uniq);
					if (!isAdded) return;
				}
			}

			await new Promise((res) => setTimeout(res, 2000));

			props.showSuccessModal && showSuccessModal();
		} catch (error) {
			console.log(error);
			// showErrorModal(error?.toString());
		} finally {
			setIsLoading(false);
			paymentStore.getCurrentPlan().then((res) => {
				if (res.data) paymentStore.currentPlan = res.data;
			});
		}
	};

	return (
		<form className={CardInformationStyle} style={{ flex: props.flex }}>
			{props.paymentMethod && !isNewPayment ? (
				<>
					<SelectableCard paymentMethod={props.paymentMethod} selected className={isMobile ? '' : 'desktop'} />
					<div className={`${CardInformationStyle}__add-payment`} onClick={() => setIsNewPayment(true)}>
						<Image src={IC_PLUS_PURPLE} alt="Add payment method" /> Add payment method
					</div>
				</>
			) : (
				<>
					<span className={`${CardInformationStyle}__title`}>{isMobile ? 'Payment' : 'Card Information'}</span>
					<Flex width="100%" flex={0} justify="start" align="end" gap={19} direction={isMobile ? 'column' : 'row'}>
						<TextInput
							qaid="BillingInfo.Input.CradHolderName"
							className="stripInput"
							containerClassName="mb-0"
							required
							width={isMobile ? '100%' : '30%'}
							label="Card holder name"
							name="cardHolderName"
							placeholder="Card holder name"
							value={cardInfo.cardHolderName}
							error={formValidationState?.cardHolderName?.message}
							onChange={onInputHandler}
							ref={(el: InputValidationRef) => (inputRefs.current.cardHolderName = el)}
						/>
						{!isMobile && (
							<>
								<Flex align="start" direction="column" width="100%">
									<span className="stripInputLabel">Card number *</span>
									<CardNumberElement className="stripInput" options={{ ...OPTIONS, showIcon: true }} onChange={handleCardNumberChange} />
								</Flex>
								<div style={{ height: '3.6rem' }}>
									<Image className="stripCardsType" src={IMG_STRIPE_CARDS_TYPE} alt="cards type" />
								</div>
							</>
						)}
					</Flex>
					{isMobile && (
						<Flex className="mobileCardInformation">
							<Flex justify="between">
								<Flex align="start" direction="column" width="100%">
									<span className="stripInputLabel">Card number *</span>
									<CardNumberElement className="stripInput" options={{ ...OPTIONS, showIcon: true }} onChange={handleCardNumberChange} />
								</Flex>
								<Image className="mobileCardInformation__stripCardsType" src={IMG_STRIPE_CARDS_TYPE} alt="cards type" />
							</Flex>

							<Flex className="mobileCardInformation__bottom">
								<Flex align="start" direction="column" className="mobileCardInformation__borderRight">
									<span className="stripInputLabel">Expiration *</span>
									<CardExpiryElement className="stripInput" options={OPTIONS} onChange={handleCardExpirationChange} />
								</Flex>
								<Flex align="start" direction="column" width="100%">
									<span className="stripInputLabel">CVC *</span>
									<CardCvcElement className="stripInput" options={OPTIONS} onChange={handleCardCvcChange} />
								</Flex>
							</Flex>
						</Flex>
					)}
					{!isMobile && (
						<>
							<Flex justify="start" margin="2.4rem 0 0 0" gap={19} flex={0}>
								<Flex align="start" direction="column" width="30%" flex="0 0 30%">
									<span className="stripInputLabel">Expiration *</span>
									<CardExpiryElement className="stripInput" options={OPTIONS} onChange={handleCardExpirationChange} />
								</Flex>

								<Flex align="start" direction="column" width="30%" flex="0 0 30%">
									<span className="stripInputLabel">CVC *</span>
									<CardCvcElement className="stripInput" options={OPTIONS} onChange={handleCardCvcChange} />
								</Flex>
							</Flex>
						</>
					)}
				</>
			)}
			<Flex align="start" direction="column" flex={0} className="checkBoxContainer">
				<Flex justify="start" align="start" flex={0} className="checkBox">
					<CheckBox
						className="checkBox__check"
						qaid="BillingInfo.CheckBox.Privacy"
						isChecked={props.checkoutInfo.isPaymentPrivacyPolicy}
						name="isPaymentPrivacyPolicy"
						label={() => (
							<span className="checkBox__text">
								I accept&nbsp;
								<a className="account-link" href="/generalDocuments/TermsOfServiceV1.pdf" target="_blank">
									Terms & Conditions
								</a>
								&nbsp;and authorize this recurring charge.
							</span>
						)}
						onClick={props.onInputHandler}
					/>
				</Flex>
			</Flex>
			<Flex justify="start" className="footer">
				<Flex className="badgesContainer">
					<Image src={IMG_STRIPE_BADGE} className="badgesContainer__strip" />
					<Image src={IMG_STRIPE_PCI_BADGE} className="badgesContainer__pci" />
				</Flex>
				<Button
					qaid="BillingInfo.Button.Subscribe"
					label="Purchase plan"
					className="footer__button"
					isLoading={isLoading}
					disabled={
						isNewPayment
							? !formValidationState?.cardHolderName?.isValid ||
							  !isNullOrUndefined(checkoutError) ||
							  !isNullOrUndefined(cardExpirationError) ||
							  !isNullOrUndefined(cardCvcError) ||
							  !cardNumberElementComplete ||
							  !cardExpiryElementComplete ||
							  !cardCvcElementComplete ||
							  !props.checkoutInfo.isPaymentPrivacyPolicy ||
							  !props.formValidationState?.companyName?.isValid ||
							  !props.formValidationState?.email?.isValid
							: !props.checkoutInfo.isPaymentPrivacyPolicy
					}
					onClick={onPayHandler}
				/>
			</Flex>
		</form>
	);
};

export default observer(CardInformation);
