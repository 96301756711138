import React, { useState } from 'react';
import ReportsStyles from './Reports.style';
import Button from '../../../../Shared/Components/Button/Button';
import Image from '../../../../Shared/Components/Image';
import { IC_EXCEL_GREEN, IC_LOOKUP, IC_TRUSTEE_DOWNLOAD, IC_TRUSTEE_REPORT, IC_TRUSTEE_RESET, IC_TRUSTEE_VIEW } from '../../../../Assets';
import TextInput from '../../../../Shared/Components/Input/TextInput';
import Tooltip from '../../../../Shared/Components/Tooltip';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Tab from '../../../../Shared/Components/Tab';
import Table, { TableColumn } from '../../../../Shared/Components/Table/Table';
import DatePickerInput from '../../../../Shared/Components/Input/DatePickerInput';
import { NumberOption } from '../../../../Models/API/All/NumberOption';
import Select from '../../../../Shared/Components/Select/Select';
import useRootStore from '../../../../Shared/Hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import useModal from '../../../../Shared/Hooks/useModal';
import { formatDate, formatNumber, isNumber, onExportToExcel } from '../../../../Shared/Utilities';
import { orderTypes } from '../../../../Shared/StaticData/equityPlans';
import { employeeStatusOptions, optionSectionAwardTypes } from '../../../../Shared/Config';
import { AndOrConnectorEnum, FilterESOPReportsType, FilterFieldEnum, reportTabsEnum, ReportType, SqlOperatorEnum } from '../../../../Models/App/ESOP/Reports';

const resetFilters: FilterESOPReportsType[] = [
    { field: FilterFieldEnum.ForDate, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Equal, value: '' },
    { field: FilterFieldEnum.AwardType, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Equal, value: '' },
    { field: FilterFieldEnum.ExerciseDate, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Equal, value: '' },
    { field: FilterFieldEnum.OrderType, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Equal, value: '' },
    { field: FilterFieldEnum.TaxTrack, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Equal, value: '' },
    { field: FilterFieldEnum.CancelationDate, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Between, value: '', value2: '' },
    { field: FilterFieldEnum.GrantDate, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Between, value: '', value2: '' },
    { field: FilterFieldEnum.ContactId, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Equal, value: '' },
    { field: FilterFieldEnum.EmployeeStatus, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Equal, value: '' },
    { field: FilterFieldEnum.SiteId, connector: AndOrConnectorEnum.And, operator: SqlOperatorEnum.Equal, value: '' },
];

const reports: ReportType[] = [
    {
        name: 'Grants status',
        key: 'grantStatusReport',
        filters: [FilterFieldEnum.ForDate, FilterFieldEnum.AwardType]
    },
    {
        name: 'Exercise orders',
        key: 'exerciseOrdersReport',
        filters: [FilterFieldEnum.ExerciseDate, FilterFieldEnum.OrderType, FilterFieldEnum.AwardType, FilterFieldEnum.TaxTrack]
    },
    {
        name: 'Cancellations report',
        key: 'CancellationsReport',
        filters: [FilterFieldEnum.CancelationDate]
    },
    {
        name: 'Pool status',
        key: 'poolStatusReport',
        filters: [FilterFieldEnum.ForDate]
    },
    {
        name: 'Grants and vesting',
        key: 'grantsAndVestingsReport',
        filters: [FilterFieldEnum.GrantDate, FilterFieldEnum.AwardType, FilterFieldEnum.TaxTrack, FilterFieldEnum.ContactId, FilterFieldEnum.EmployeeStatus, FilterFieldEnum.SiteId]
    },
];

interface ReportsProps {
    rtd: () => void;
}

const Reports = (props: ReportsProps) => {
    const { equityPlansStore } = useRootStore();
    const { showModal } = useModal();
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [tableData, setTableData] = useState<ReportType[]>(reports);
    const [selectedRow, setSelectedRow] = useState<ReportType>();
    const [loading, setLoading] = useState(false);
    const [currentAction, setCurrentAction] = useState<'' | 'download' | 'preview'>('');
    const [filters, setFilters] = useState<FilterESOPReportsType[]>(resetFilters);

    React.useEffect(() => {
        setTableData(reports.filter(row => row.name.toLowerCase().includes(searchValue.toLowerCase())));
    }, [searchValue]);

    const actions = () => {
        if (!selectedRow) return;
        return <div>
            {selectedRow.filters.map(filter => getFilter(filter))}
            <div className={`${ReportsStyles}__actionsCard`}>
                <Tooltip title={'Download'}>
                    {loading && currentAction === 'download' ?
                        <CircularProgress size={18} style={{ margin: 'auto 5px' }} /> : <Image
                            width={18}
                            src={IC_TRUSTEE_DOWNLOAD}
                            onClick={() => [getReport(selectedRow, 'download'), setCurrentAction('download')]}
                        />}
                </Tooltip>
                <Tooltip title={'View'}>
                    {loading && currentAction === 'preview' ?
                        <CircularProgress size={18} style={{ margin: 'auto 5px' }} />
                        : <Image
                            width={20}
                            src={IC_TRUSTEE_VIEW}
                            onClick={() => [getReport(selectedRow, 'preview'), setCurrentAction('preview')]}
                        />}
                </Tooltip>
                <Tooltip title={'Reset'}>
                    <Image
                        width={15}
                        src={IC_TRUSTEE_RESET}
                        onClick={() => [setFilters(resetFilters)]}
                    />
                </Tooltip>
            </div>
        </div>;
    }

    const getFilter = (key: FilterFieldEnum) => {
        switch (key) {
            case FilterFieldEnum.CancelationDate:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'Cancelation date'}</span>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <DatePickerInput
                            placeholder='From'
                            qaid="ESOPReport.Input.GrantDate"
                            value={filters.find(item => item.field === key)?.value}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                            }}
                            containerClassName="mb-0"
                        />
                        <DatePickerInput
                            placeholder='to'
                            qaid="ESOPReport.Input.GrantDate"
                            value={filters.find(item => item.field === key)?.value2}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.field === key ? { ...item, value2: value } : item));
                            }}
                            containerClassName="mb-0"
                        />
                    </div>
                </div>;

            case FilterFieldEnum.OrderType:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'Order type'}</span>
                    <Select
                        qaid="ESOPReport.Input.TaxTrack"
                        options={orderTypes}
                        value={filters.find(item => item.field === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case FilterFieldEnum.AwardType:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'Award type'}</span>
                    <Select
                        qaid="ESOPReport.Input.TaxTrack"
                        options={optionSectionAwardTypes}
                        value={filters.find(item => item.field === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case FilterFieldEnum.TaxTrack:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'Tax track'}</span>
                    <Select
                        qaid="ESOPReport.Input.TaxTrack"
                        options={equityPlansStore.taxTracks}
                        value={filters.find(item => item.field === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case FilterFieldEnum.ExerciseDate:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'Exercise date'}</span>
                    <DatePickerInput
                        placeholder='Select...'
                        qaid="ESOPReport.Input.GrantDate"
                        value={filters.find(item => item.field === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                        }}
                        containerClassName="mb-0"
                    />
                </div>;

            case FilterFieldEnum.ForDate:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'For date'}</span>
                    <DatePickerInput
                        placeholder='Select...'
                        qaid="ESOPReport.Input.ForDate"
                        value={filters.find(item => item.field === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                        }}
                        containerClassName="mb-0"
                    />
                </div>;

            case FilterFieldEnum.GrantDate:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'Grant date'}</span>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <DatePickerInput
                            placeholder='From'
                            qaid="ESOPReport.Input.GrantDate"
                            value={filters.find(item => item.field === key)?.value}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                            }}
                            containerClassName="mb-0"
                        />
                        <DatePickerInput
                            placeholder='to'
                            qaid="ESOPReport.Input.GrantDate"
                            value={filters.find(item => item.field === key)?.value2}
                            onChange={(value) => {
                                setFilters(filters?.map(item => item.field === key ? { ...item, value2: value } : item));
                            }}
                            containerClassName="mb-0"
                        />
                    </div>
                </div>;

            case FilterFieldEnum.ContactId:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'Beneficiary'}</span>
                    <Select
                        qaid="ESOPReport.Input.Beneficiary"
                        options={equityPlansStore.beneficiariesList ?? []}
                        value={filters.find(item => item.field === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case FilterFieldEnum.SiteId:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'Site'}</span>
                    <Select
                        qaid="ESOPReport.Input.TaxTrack"
                        options={equityPlansStore.sites}
                        value={filters.find(item => item.field === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            case FilterFieldEnum.EmployeeStatus:
                return <div key={key} className={`${ReportsStyles}__filterCard`}>
                    <span>{'Employee status'}</span>
                    <Select
                        qaid="ESOPReport.Input.TaxTrack"
                        options={employeeStatusOptions}
                        value={filters.find(item => item.field === key)?.value}
                        onChange={(value) => {
                            setFilters(filters?.map(item => item.field === key ? { ...item, value: value } : item));
                        }}
                        className="mb-0"
                    />
                </div>;

            default:
                break;
        }
    }

    const tabs = <div className={`${ReportsStyles}__tabs`}>
        <Tab
            label="General"
            onClick={() => setSelectedTab(reportTabsEnum.General)}
            isActive={selectedTab === reportTabsEnum.General}
            qaid="grantReports.tab.General"
        />
        <Tab
            label="Business partners"
            isActive={selectedTab === reportTabsEnum.BusinessPartners}
            qaid="grantDocuments.tab.BusinessPartners"
        />
    </div>;

    const reportsTableColumns: TableColumn<ReportType>[] = [
        {
            name: 'name',
            label: 'Report name',
            sortable: true,
        },
    ];

    const getReport = async (report: ReportType, type: 'download' | 'preview') => {
        if (!report) return;

        setLoading(true);
        let resRepo: any = {};
        let filteredList = filters.filter(item => item.value !== '' && item.value !== undefined && item.value !== null);

        switch (report.key) {
            case 'grantStatusReport':
                resRepo = await equityPlansStore.getGrantStatusReport(JSON.stringify(filteredList));
                break;
            case 'exerciseOrdersReport':
                resRepo = await equityPlansStore.getExerciseOrdersReport(JSON.stringify(filteredList));
                break;
            case 'CancellationsReport':
                resRepo = await equityPlansStore.getCancellationsReport(JSON.stringify(filteredList));
                break;
            case 'poolStatusReport':
                resRepo = await equityPlansStore.getPoolStatusReport(JSON.stringify(filteredList));
                break;
            case 'grantsAndVestingsReport':
                resRepo = await equityPlansStore.getGrantsAndVestingsReport(JSON.stringify(filteredList));
                break;
            default:
                break;
        }

        setLoading(false);
        if (!resRepo.data || !resRepo?.data.length) return;

        const keys: string[] = Object.keys((resRepo.data)[0]);
        const columnsTable: TableColumn<any>[] = keys.map(key => {
            return {
                name: key,
                label: key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase()),
                format: (val) => key.includes('Date') ? formatDate(val) : isNumber(val) ? formatNumber(val) : val,
            }
        });

        if (type === 'preview') {
            showReportPreviewModal(resRepo.data, columnsTable, report.name);
        }

        if (type === 'download') {
            onExportToExcel({
                rows: resRepo.data,
                exportToExcel: { fileName: report.name, sheetName: report.name },
                color: 'primary',
                columns: columnsTable,
                showTotal: true,
            });
        }
    }

    const showReportPreviewModal = (data: any[], columns: TableColumn<any>[], reportName: string) => {
        showModal({
            type: "dialog",
            width: '170rem',
            body: <>
                <p style={{ display: 'flex', alignContent: 'center', alignItems: 'center', marginBottom: '2rem' }}>
                    <img
                        src={IC_EXCEL_GREEN}
                        width={20}
                        style={{ marginRight: '1rem' }}
                    />
                    <h4>{reportName}</h4>
                </p>

                <Table
                    columns={columns}
                    rows={data}
                    showTotal
                />
            </>,
        });
    };

    return (
        <div className={ReportsStyles}>
            <Button qaid="" label="Back" inverse cancel onClick={props.rtd} />
            <div className={`${ReportsStyles}__pageBody`}>
                <div className={`${ReportsStyles}__pageHeader`}>
                    <div className={`${ReportsStyles}__headerMain`}>
                        <div className={`${ReportsStyles}__headerMainInner`}>
                            <Image src={IC_TRUSTEE_REPORT} />
                            <h3>Reports</h3>
                        </div>
                        <TextInput
                            placeholder="general.search2"
                            value={searchValue}
                            onChange={(value) => setSearchValue(value)}
                            qaid="Filter.Input.Search"
                            style={{ flex: 1, marginRight: '0px', marginLeft: 'auto' }}
                            endIcon={IC_LOOKUP}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className={`${ReportsStyles}__wrapTable`}>
                        {selectedTab === reportTabsEnum.General &&
                            <Table
                                columns={reportsTableColumns}
                                rows={tableData}
                                rowSize={1.3}
                            onRowClick={(obj) => [setSelectedRow(obj), setFilters(resetFilters)]}
                                rowClassName={(row) => (selectedRow && selectedRow.key === row.key ? `${ReportsStyles}__selected` : undefined)}
                            />}
                    </div>
                    <div className={`${ReportsStyles}__wrapTable`}>
                        <div className={`${ReportsStyles}__card`}>
                            <div className={`${ReportsStyles}__headerCard`}>
                                <span>Selected report settings</span>
                            </div>
                            <div className={`${ReportsStyles}__bodyCard`}>
                                {actions()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(Reports);