import { createRef, forwardRef, useImperativeHandle, useState } from "react";
import { default as DatePicker, default as ReactDatePicker, CalendarContainer } from "react-datepicker";
import { IC_CALENDAR_PURPLE, IC_CALENDAR_WHITE } from "../../../Assets";
import { DateFormatsEnum, checkQaidConvention, eliminateTimeZone, formatDate, isNullOrUndefined } from "../../Utilities";
import InputWrapper from "./InputWrapper";
import i18next from "i18next";
import classNames from "classnames";
import useEffectOnce from "../../Hooks/useEffectOnce";
import { StyledInput, inputClassName } from "./Input.Style";
import { DatePickerProps, InputRef } from "../../../Models/App/Inputs/types";
import Flex from "../Layout/Flex";
import OverflowText from "../OverflowText";

const DatePickerInput = forwardRef<InputRef, DatePickerProps>(
    (
        { maxDate = "01/01/2050", minDate = "01/01/1980", dateFormat = DateFormatsEnum.LABEL_SHORT, onChange, errorOnBlur, ...props },
        ref
    ) => {
        checkQaidConvention(props.qaid);
        const [isFocusOnce, setIsFocusOnce] = useState<boolean>(false);
        const inputRef = createRef<HTMLInputElement>();
        const dateRef = createRef<ReactDatePicker>();
        const [error, setError] = useState<string>();

		const inputValue = !isNullOrUndefined(props.value) && +new Date(props.value) ? formatDate(new Date(props.value.toString())) : '';

        const focus = () => {
            inputRef?.current?.focus();
            inputRef?.current?.scrollIntoView({ behavior: "smooth" });
            dateRef?.current?.setFocus();
        };

        useImperativeHandle(ref, () => ({
			resetError() {
				setIsFocusOnce(false);
			},
			showError() {
				// !errorOnBlur && setIsFocusOnce(true);
				setIsFocusOnce(true);
			},
			focus,
			clear() {
				onChange?.(undefined);
			},
			getName() {
				return props.name;
			},
			value: inputValue,
		}));

		const showError = (isFocusOnce || props.forceValidation) && props.error;

		const onSelect = (value: Date) => {
			onChange?.(value ? new Date(eliminateTimeZone(value)) : undefined, props.name);
			setIsFocusOnce(false);
		};

		useEffectOnce(
			() => {
				props.flat && focus();
			},
			[props.flat],
			!!props.flat
		);

		return (
			<InputWrapper {...props} error={showError ? props.error : undefined}>
				{props.isViewMode ? (
					<Flex height="3.6rem" align="end" justify="start">
						<OverflowText>{formatDate(props.value) || '---------'}</OverflowText>
					</Flex>
				) : (
					<div style={{ position: 'relative', width: '100%' }}>
						<DatePicker
							react-qaid={props.qaid}
							data-qaid={props.qaid}
							disabled={props.disabled}
							required={props.required}
							name={props.name}
							maxDate={new Date(maxDate)}
							minDate={new Date(minDate)}
							autoComplete="nope"
							showMonthDropdown
							showYearDropdown
							placeholderText={props.placeholder}
							className={classNames(inputClassName, props.className || '', {
								inverse: props.inverse,
								flat: props.flat,
							})}
							adjustDateOnChange
							onBlur={(e: any) => {
								setIsFocusOnce(true);
								props.onBlur?.(e);
							}}
							onFocus={(e) => {
								// setIsFocusOnce(!errorOnBlur);
								// setIsFocusOnce(false);
								props.onFocus?.(e);
							}}
							dateFormat={dateFormat}
							selected={!isNullOrUndefined(props.value) && +new Date(props.value) ? new Date(props.value.toString()) : null}
							onChange={onSelect}
							// onSelect={onSelect}
							ref={dateRef}
							customInput={<StyledInput className="w-100" data-qaid={props.qaid} value={inputValue} />}
						/>
						{!props.flat && (
							<div
								className={classNames('icon ic-calendar', { inverse: props.inverse, disabled: props.disabled })}
								onClick={(e) => {
									e.stopPropagation();
									dateRef.current?.setOpen(true);
								}}
							>
								<img src={props.inverse ? IC_CALENDAR_PURPLE : IC_CALENDAR_PURPLE} alt="" />
							</div>
						)}
					</div>
				)}
			</InputWrapper>
		);
    }
);

export default DatePickerInput;
